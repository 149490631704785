<!--
 * @Author: Fenngding
 * @Date: 2019-09-23 10:29:54
 * @LastEditors: zhulin
 * @LastEditTime: 2020-08-05 18:58:42
 * @Email: fenngding@live.com
 * @Description:
 -->
<template>
  <div id="weChatOfficialAccount" ref="weChatOfficialAccount" class="tyc-settings">
    <div class="title">
      <div class="leftbar">
        <span>微信公众号({{ tycData.tycResult.total }})</span>
      </div>
      <div class="rightbar">
        <en-icon name="iconzaicichayan-qiyezhengxin" size="22px" color="#ffb540" @click.native="reCheck()"> </en-icon>
      </div>
    </div>
    <!-- 企业基本信息 -->
    <div class="base">
      <div class="base-inner">
        <div class="item" v-for="(item, index) in tycData.tycResult.items" @click="showInfo(item)" :key="index">
          <div class="item-inner">
            <img :src="item.titleImgURL" :alt="item.title" />
            <div class="text">
              <i>
                <b>{{ item.title }}</b
                >微信号：{{ item.publicNum }} <en-icon name="ico" size="15"> </en-icon> </i
              ><br />
              <span>{{ item.recommend }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="page-bot">
      <span>共{{ tycData.tycResult.total }}条</span>
    </div>
    <!--微信公众号弹窗-->
    <wechat-alert ref="wechatAlert" :show="showAlert" :weixin-data="weixinData" @toShow="toShow"></wechat-alert>
  </div>
</template>

<script>
import wechatAlert from "./components/weChatAlert";

export default {
  name: "WeChatOfficialAccount",
  components: { wechatAlert },
  props: {
    tycData: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      showAlert: false,
      weixinData: {}
    };
  },
  computed: {},
  mounted() {
    // if (this.$route.query.id === "weChatOfficialAccount") {
    //   this.$nextTick(() => {
    //     this.$refs.weChatOfficialAccount.scrollIntoView();
    //   });
    // }
  },
  methods: {
    /**
     * @description: 打开弹窗
     */
    showInfo(item) {
      this.showAlert = true;
      this.weixinData = item;
    },
    /**
     * @description: 关闭弹窗
     */
    toShow() {
      this.showNotice = false;
    },
    /**
     * @description: 再次查验
     */
    reCheck() {
      this.$parent.blockEvent("731", 0, true);
    }
  }
};
</script>

<style lang="scss" scoped>
.tyc-settings {
  border-radius: 5px;
  background-color: #fff;
  margin-top: 10px;
  // .title {
  //   height: 45px;
  //   line-height: 45px;
  //   text-align: left;
  //   border-bottom: 1px solid #f3f3f3;
  //   span {
  //     font-size: 14px;
  //     font-weight: bold;
  //     color: #333333;
  //     margin-left: 20px;
  //   }
  // }
  .wrap {
    padding: 85px;
  }
}
.page-bot {
  font-size: 12px;
  padding: 20px;
  text-align: right;
}
.base {
  display: flex;
  flex-direction: row;
  margin-top: -1px;
  padding: 0 20px 0 20px;
  &-inner {
    width: 100%;
    .item {
      width: 100%;
      height: 80px;
      border-bottom: 1px solid #f3f3f3;
      line-height: 110px;
      cursor: pointer;
      .item-inner {
        line-height: 40px;
        display: inline-block;
        img {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          display: inline-block;
          margin-right: 15px;
        }
        .text {
          display: inline-block;
          line-height: 20px;
          height: 40px;
          vertical-align: top;
          i {
            font-style: normal;
            display: inline-block;
            b {
              margin-right: 10px;
            }
          }
          span {
            display: inline-block;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 950px;
          }
        }
      }
    }
  }
}
</style>
