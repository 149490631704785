var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "en-dialog",
    {
      attrs: { title: "行政处罚", visible: _vm.show, width: "850px" },
      on: {
        "update:visible": function ($event) {
          _vm.show = $event
        },
        close: _vm.closeDialog,
      },
    },
    [
      _c("div", { staticClass: "pop-content letdown-content" }, [
        _c("div", { staticClass: "pop-info" }, [
          _c("table", [
            _c("tr", [
              _c("td", [_vm._v("名称")]),
              _c("td", [_vm._v(_vm._s(_vm.data.iname))]),
            ]),
            _c("tr", [
              _c("td", [_vm._v("法定代表人")]),
              _c("td", [_vm._v(_vm._s(_vm.data.businessentity))]),
            ]),
            _c("tr", [
              _c("td", [_vm._v("身份证号/组织机构代码")]),
              _c("td", [_vm._v(_vm._s(_vm.data.cardnum))]),
            ]),
            _c("tr", [
              _c("td", [_vm._v("执行依据文号")]),
              _c("td", [_vm._v(_vm._s(_vm.data.gistid))]),
            ]),
            _c("tr", [
              _c("td", [_vm._v("案号")]),
              _c("td", [_vm._v(_vm._s(_vm.data.casecode))]),
            ]),
            _c("tr", [
              _c("td", [_vm._v("做出执行依据单位")]),
              _c("td", [_vm._v(_vm._s(_vm.data.gistunit))]),
            ]),
            _c("tr", [
              _c("td", [_vm._v("法律生效文书确定的业务")]),
              _c("td", [_vm._v(_vm._s(_vm.data.duty))]),
            ]),
            _c("tr", [
              _c("td", [_vm._v("被执行人的履行情况")]),
              _c("td", [_vm._v(_vm._s(_vm.data.performance || ""))]),
            ]),
            _c("tr", [
              _c("td", [_vm._v("执行法院")]),
              _c("td", [_vm._v(_vm._s(_vm.data.courtname))]),
            ]),
            _c("tr", [
              _c("td", [_vm._v("省份")]),
              _c("td", [_vm._v(_vm._s(_vm.data.areaname))]),
            ]),
            _c("tr", [
              _c("td", [_vm._v("立案时间")]),
              _c("td", [_vm._v(_vm._s(_vm.data.regdate))]),
            ]),
            _c("tr", [
              _c("td", [_vm._v("发布时间")]),
              _c("td", [_vm._v(_vm._s(_vm.data.publishdate))]),
            ]),
            _c("tr", [
              _c("td", [_vm._v("失信被执行人行为具体情形")]),
              _c("td", [_vm._v(_vm._s(_vm.data.disruptTypeName))]),
            ]),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }