<!--
 * @Author: zhulin
 * @Date: 2020-07-22 10:34:01
 * @LastEditors: zhulin
 * @LastEditTime: 2020-08-06 17:49:29
 * @Description: 核心团队378
 * @FilePath: \user\src\views\corporateCredit\development\team.vue
-->
<template>
  <div id="team" ref="team" class="team">
    <div class="tyc-settings">
      <div class="title">
        <div class="leftbar">
          <span>核心团队</span>
        </div>
      </div>
      <ul class="team-wrap">
        <li v-for="(item, index) in tycData.tycResult.items" :key="index">
          <dl class="clearfix">
            <dt class="fl">
              <en-user-logo :user-name="item.name" :image-url="item.icon" size="60"></en-user-logo>
            </dt>
            <dd class="fl name">
              {{ item.name }}
            </dd>
            <dd class="fl">
              {{ item.title }}
            </dd>
          </dl>
          <p>
            {{ item.desc }}
          </p>
        </li>
      </ul>
      <!-- <div class="page-bot">
        <span>共{{ tycData.tycResult.total }}条</span>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tycData: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {};
  },
  methods: {
    /**
     * @description: 再次查验
     */
    reCheck() {
      this.$parent.blockEvent("378", 0, true);
    }
  }
};
</script>

<style lang="scss" scoped>
.team {
  &-wrap {
    padding: 0 20px 20px;
    li {
      background: #f5f7fa;
      border: 1px solid #e8ecf2;
      padding: 12px;
      margin-top: 20px;
      color: #666;
      font-size: 12px;
      border-radius: 5px;
      line-height: 24px;
      dd {
        width: 60%;
        margin-left: 12px;
        &.name {
          color: #333;
          margin-top: 8px;
          font-weight: bold;
        }
      }
    }
  }
}
</style>
