<!--
 * @Author: Do not edit
 * @Date: 2020-07-22 10:34:01
 * @LastEditors: zhulin
 * @LastEditTime: 2021-09-27 16:23:40
 * @Description: 企业年报371
 * @FilePath: \user\src\views\corporateCredit\information\report.vue
-->
<template>
  <div id="report" ref="report" class="tyc-settings reportInfo">
    <div class="title clearfix">
      <div class="leftbar">
        <span>企业年报({{ tycData.tycResult.total }})</span>
      </div>
    </div>
    <div class="report-items">
      <en-card
        v-for="(item, index) in tycData.tycResult.items"
        :key="index"
        icon-name="qiyenianbao-qiyezhengxin"
        :title="getName(item)"
        icon-bg="#3e90fe"
        icon-color="#fff"
        :dataInfo="item"
        @click.native.stop="showInfo(item)"
      >
      </en-card>
    </div>
    <annual-alert ref="yearReport" :show="showAlert" :info-data="infoData" @toShow="toShow"></annual-alert>
  </div>
</template>

<script>
import annualAlert from "./components/annualAlert";

export default {
  name: "reportInfo",
  components: { annualAlert },
  props: {
    tycData: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      showAlert: false,
      infoData: {}
    };
  },
  mounted() {},
  methods: {
    /**
     * @description: 打开弹窗
     */
    showInfo(item) {
      this.showAlert = true;
      this.infoData = item;
    },
    /**
     * @description: 关闭弹窗
     */
    toShow() {
      this.showAlert = false;
    },
    /**
     * @description: 返回标题
     */
    getName(item) {
      const txt = `${item.baseInfo.reportYear}年${item.baseInfo.companyName}$`;
      return txt;
    },
    /**
     * @description: 再次查验
     */
    reCheck() {
      this.$parent.blockEvent("371", 0, true);
    }
  }
};
</script>

<style lang="scss" scoped>
.reportInfo {
  .report-items {
    display: flex;
    flex-direction: row;
    padding: 20px 5px;
    flex-wrap: wrap;
    /deep/ .en-card-normal-main-title {
      margin-top: 12px;
    }
    .en-card {
      margin-left: 15px;
      cursor: pointer;
    }
  }
}
</style>
