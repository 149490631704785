<template>
  <div class="origin-tree">
    <vue2-org-tree
      :data="data"
      :horizontal="horizontal"
      :collapsable="collapsable"
      :label-class-name="labelClassName"
      :render-content="renderContent"
      @on-expand="onExpand"
      @on-node-click="onNodeClick"
    />
  </div>
</template>

<script>
import Vue2OrgTree from "vue2-org-tree";

export default {
  components: { "vue2-org-tree": Vue2OrgTree },
  data() {
    return {
      data: {
        id: 0,
        label: "XXX科技有限公司",
        amount: "100",
        expand: true,
        children: [
          {
            id: 2,
            label: "产品研发部",
            amount: "200",
            children: [
              {
                id: 5,
                amount: "300",
                label: "研发-前端"
              },
              {
                id: 6,
                amount: "200",
                label: "研发-后端"
              },
              {
                id: 9,
                amount: "130",
                label: "UI设计"
              },
              {
                id: 10,
                amount: "140",
                label: "产品经理"
              }
            ]
          },
          {
            id: 3,
            label: "销售部",
            amount: "110",
            expand: true,
            children: [
              {
                id: 7,
                amount: "500",
                label: "销售一部"
              },
              {
                id: 8,
                amount: "600",
                label: "销售二部",
                children: [
                  {
                    id: 7,
                    amount: "500",
                    label: "销售一组"
                  },
                  {
                    id: 7,
                    amount: "500",
                    label: "销售一组"
                  },
                  {
                    id: 7,
                    amount: "500",
                    label: "销售一组"
                  },
                  {
                    id: 7,
                    amount: "500",
                    label: "销售一组"
                  },
                  {
                    id: 7,
                    amount: "500",
                    label: "销售一组"
                  },
                  {
                    id: 7,
                    amount: "500",
                    label: "销售一组"
                  },
                  {
                    id: 7,
                    amount: "500",
                    label: "销售一组"
                  },
                  {
                    id: 7,
                    amount: "500",
                    label: "销售一组"
                  }
                ]
              },
              {
                id: 7,
                amount: "500",
                label: "销售一部"
              },
              {
                id: 8,
                amount: "600",
                label: "销售二部"
              },
              {
                id: 7,
                amount: "500",
                label: "销售一部"
              },
              {
                id: 8,
                amount: "600",
                label: "销售二部"
              },
              {
                id: 7,
                amount: "500",
                label: "销售一部"
              },
              {
                id: 8,
                amount: "600",
                label: "销售二部"
              }
            ]
          },
          {
            id: 4,
            amount: "400",
            label: "财务部"
          },
          {
            id: 5,
            amount: "400",
            label: "财务部"
          },
          {
            id: 6,
            amount: "400",
            label: "财务部"
          },
          {
            id: 7,
            amount: "400",
            label: "财务部"
          },
          {
            id: 8,
            amount: "400",
            label: "财务部"
          },
          {
            id: 9,
            amount: "400",
            label: "财务部"
          },
          {
            id: 10,
            amount: "400",
            label: "财务部"
          },
          {
            id: 11,
            amount: "400",
            label: "财务部"
          },

          {
            id: 12,
            amount: "300",
            label: "HR人事"
          }
        ]
      },
      horizontal: false,
      collapsable: true,
      expandAll: true,
      labelClassName: "bg-white"
    };
  },
  methods: {
    renderContent(h, data) {
      return {
        label: data.label,
        amount: data.amount
      };
    },
    onExpand(e, data) {
      if ("expand" in data) {
        data.expand = !data.expand;

        if (!data.expand && data.children) {
          this.collapse(data.children);
        }
      } else {
        this.$set(data, "expand", true);
      }
    },
    onNodeClick(e, data) {
      /* 取消事件冒泡 */
      e.preventDefault();
      console.log(data.label);
    },
    collapse(list) {
      const _this = this;
      list.forEach((child) => {
        if (child.expand) {
          child.expand = false;
        }
        child.children && _this.collapse(child.children);
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.bg-white {
  background-color: white;
}
.origin-tree {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
</style>
