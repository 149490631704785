<!--
 * @Author: zhulin
 * @Date: 2020-07-22 10:34:01
 * @LastEditors: zhulin
 * @LastEditTime: 2020-08-06 14:12:56
 * @Description:被执行人389
 * @FilePath: \user\src\views\corporateCredit\law\execute.vue
-->
<template>
  <div id="execute" ref="execute" class="tyc-settings">
    <div class="title">
      <div class="leftbar">
        <span>被执行人({{ tycData.tycResult.total }})</span>
      </div>
      <div class="rightbar">
        <en-icon name="iconzaicichayan-qiyezhengxin" size="22px" color="#ffb540" @click.native="reCheck()"> </en-icon>
      </div>
    </div>
    <!-- 企业基本信息 -->
    <div class="base">
      <div class="base-law">
        <en-table :data="tycData.tycResult.items">
          <en-table-column type="index" width="60px"></en-table-column>
          <en-table-column prop="caseCreateTime" label="立案日期"> </en-table-column>
          <en-table-column prop="execMoney" label="执行标的"> </en-table-column>
          <en-table-column prop="caseCode" label="案号"> </en-table-column>
          <en-table-column prop="execCourtName" label="执行法院"> </en-table-column>
        </en-table>
        <en-pagination :page-model="pageModel" @change="handlePageChanged"></en-pagination>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TrademarkInfo",
  props: {
    tycData: {
      type: Object,
      default() {}
    }
  },
  data() {
    return {
      tableData: [],
      pageModel: {
        pageNo: this.tycData.pageNo || 1,
        pageSize: this.tycData.pageSize || 10,
        total: this.tycData.tycResult.total || "",
        totalPages: this.tycData.totalPages || Math.ceil(this.tycData.tycResult.total / (this.tycData.pageSize || 10))
      }
    };
  },
  computed: {},
  methods: {
    /**
     * @description: 页码
     */
    handlePageChanged({ pageSize, pageNo }) {
      this.$parent.blockEvent("389", 1, true, pageNo, pageSize);
    },
    /**
     * @description: 再次查验
     */
    reCheck() {
      this.$parent.blockEvent("389", 0, true);
    }
  }
};
</script>

<style lang="scss" scoped>
.tyc-settings {
  .title {
    i {
      background: #e5f2ff;
      padding: 3px;
      color: #5fb5ff;
      font-style: normal;
      margin-left: 10px;
      border-radius: 3px;
    }
  }
  .wrap {
    padding: 85px;
  }
}
.base {
  display: flex;
  flex-direction: row;
  margin-top: -1px;
  &-law {
    width: 100%;
  }
}
.el-button--primary.en-btn-normal {
  background: #e5f2ff;
  color: #5fb5ff;
  border-color: #e5f2ff;
}
.el-button--danger.en-btn-normal {
  background: #f7ebeb;
  color: #f76b6b;
  border-color: #f7ebeb;
}
</style>
