<!--
 * @Author: Do not edit
 * @Date: 2020-07-22 10:34:01
 * @LastEditors: zhulin
 * @LastEditTime: 2021-09-27 16:19:20
 * @Description:主要人员信息365
 * @FilePath: \user\src\views\corporateCredit\information\staff-info.vue
-->
<template>
  <div id="staffInfo" ref="staffInfo" class="staffInfo tyc-settings">
    <div class="title">
      <span>主要人员信息({{ total }})</span>
    </div>
    <div class="staff-list">
      <en-table :data="staffList">
        <en-table-column type="index" width="60px"></en-table-column>
        <en-table-column prop="name" label="姓名">
          <template slot-scope="scope">
            <div class="table-slot">
              <span>{{ scope.row.name }}</span>
              <span>他关联{{ scope.row.toco || "-" }}家公司</span>
            </div>
          </template>
        </en-table-column>
        <en-table-column prop="typeJoin" label="职务"> </en-table-column>
      </en-table>
      <!-- <en-pagination :page-model="pageModel" @change="handlePageChanged"></en-pagination> -->
      <div class="page-bot">
        <span>共{{ total }}条</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "staffInfo",
  props: {
    tycData: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      staffList: [],
      total: ""
    };
  },
  mounted() {
    this.getField();
  },
  methods: {
    getField() {
      this.staffList = this.tycData.tycResult.staffList?.result || [];
      this.total = this.tycData.tycResult.staffList?.total || "";
    }
  }
};
</script>

<style lang="scss" scoped>
.staffInfo {
  .table-slot {
    display: flex;
    justify-content: space-between;
    span:last-child {
      color: #999;
    }
  }
  .staff-list {
    margin-top: -1px;
    &-inner {
      width: 100%;
    }
  }
  .page-bot {
    font-size: 12px;
    padding: 20px;
    text-align: right;
  }
}
</style>
