<!--
 * @Author: zhulin
 * @Date: 2020-07-22 10:34:01
 * @LastEditors: zhulin
 * @LastEditTime: 2020-08-07 15:53:32
 * @Description: 法律诉讼384
 * @FilePath: \user\src\views\corporateCredit\law\lawsuit.vue
-->
<template>
  <div id="lawsuit" ref="lawsuit" class="tyc-settings">
    <div class="title">
      <div class="leftbar">
        <span>法律诉讼({{ tycData.tycResult.total }})</span>
      </div>
      <div class="rightbar">
        <en-icon name="iconzaicichayan-qiyezhengxin" size="22px" color="#ffb540" @click.native="reCheck()"> </en-icon>
      </div>
    </div>
    <!-- 企业基本信息 -->
    <div class="base">
      <div class="base-law">
        <en-table :data="tycData.tycResult.items" row-key="id" border default-expand-all>
          <en-table-column type="index" width="60px"></en-table-column>
          <en-table-column prop="submittime" label="日期">
            <template v-slot="{ row }">
              <span>{{ dayjs(row.submittime).format("YYYY-MM-DD") }}</span>
            </template>
          </en-table-column>
          <en-table-column prop="title" label="判断文书"> </en-table-column>
          <en-table-column prop="casereason" label="案由" width="210"> </en-table-column>
          <en-table-column prop="plaintiffs" label="原告">
            <template v-slot="{ row }">
              <span v-html="row.plaintiffs"></span>
            </template>
          </en-table-column>
          <en-table-column prop="defendants" label="被告">
            <template v-slot="{ row }">
              <span v-html="row.defendants"></span>
            </template>
          </en-table-column>
          <en-table-column prop="caseno" label="案号"> </en-table-column>
        </en-table>
        <en-pagination :page-model="pageModel"></en-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";

export default {
  name: "lawsuit",
  props: {
    tycData: {
      type: Object,
      default() {}
    }
  },
  data() {
    return {
      tableData: [],
      dayjs,
      pageModel: {
        pageNo: this.tycData.pageNo || 1,
        pageSize: this.tycData.pageSize || 10,
        total: this.tycData.tycResult.total || "",
        totalPages: this.tycData.totalPages || Math.ceil(this.tycData.tycResult.total / (this.tycData.pageSize || 10))
      }
    };
  },
  computed: {},
  methods: {
    /**
     * @description: 页码
     */
    handlePageChanged({ pageSize, pageNo }) {
      this.$parent.blockEvent("384", 1, true, pageNo, pageSize);
    },
    /**
     * @description: 再次查验
     */
    reCheck() {
      this.$parent.blockEvent("384", 0, true);
    }
  }
};
</script>

<style lang="scss" scoped>
.tyc-settings {
  .wrap {
    padding: 85px;
  }
}
.base {
  display: flex;
  flex-direction: row;
  margin-top: -1px;
  &-law {
    width: 100%;
  }
}
</style>
