var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "en-dialog",
    {
      attrs: { title: "欠税公告", visible: _vm.show, width: "850px" },
      on: {
        "update:visible": function ($event) {
          _vm.show = $event
        },
        close: _vm.closeDialog,
      },
    },
    [
      _c("div", { staticClass: "patentInfo-content" }, [
        _c("div", { staticClass: "patentInfo-info" }, [
          _c("table", [
            _c("tr", [
              _c("td", [_vm._v("纳税人类型")]),
              _c("td", [_vm._v(_vm._s(_vm.data.taxpayerType || ""))]),
              _c("td", [_vm._v("纳税人识别号")]),
              _c("td", [_vm._v(_vm._s(_vm.data.taxIdNumber || ""))]),
            ]),
            _c("tr", [
              _c("td", [_vm._v("负责人姓名")]),
              _c("td", [_vm._v(_vm._s(_vm.data.legalpersonName || ""))]),
              _c("td", [_vm._v("证件号码")]),
              _c("td", [_vm._v(_vm._s(_vm.data.personIdNumber || ""))]),
            ]),
            _c("tr", [
              _c("td", [_vm._v("经营地点")]),
              _c("td", { attrs: { colspan: "3" } }, [
                _vm._v(" " + _vm._s(_vm.data.location || "") + " "),
              ]),
            ]),
            _c("tr", [
              _c("td", [_vm._v("欠税税种")]),
              _c("td", [_vm._v(_vm._s(_vm.data.taxCategory || ""))]),
              _c("td", [_vm._v("欠税金额")]),
              _c("td", [_vm._v(_vm._s(_vm.data.ownTaxAmount || ""))]),
            ]),
            _c("tr", [
              _c("td", [_vm._v("欠税余额")]),
              _c("td", [_vm._v(_vm._s(_vm.data.ownTaxBalance || ""))]),
              _c("td", [_vm._v("当前发生的欠税额")]),
              _c("td", [_vm._v(_vm._s(_vm.data.newOwnTaxBalance || ""))]),
            ]),
            _c("tr", [
              _c("td", [_vm._v("税务机关")]),
              _c("td", [_vm._v(_vm._s(_vm.data.department || ""))]),
              _c("td", [_vm._v("发布日期")]),
              _c("td", [_vm._v(_vm._s(_vm.data.publishDate || ""))]),
            ]),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }