var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "origin-tree" },
    [
      _c("vue2-org-tree", {
        attrs: {
          data: _vm.data,
          horizontal: _vm.horizontal,
          collapsable: _vm.collapsable,
          "label-class-name": _vm.labelClassName,
          "render-content": _vm.renderContent,
        },
        on: { "on-expand": _vm.onExpand, "on-node-click": _vm.onNodeClick },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }