<template>
  <div class="mainwarp" v-en-loading="tycLoding">
    <div class="main" id="tycmain" ref="tycmain">
      <div id="main-cent">
        <!--企业信息-->
        <base-info v-if="blockData[365]" :tyc-data="blockData[365]" />
        <tab-nav v-if="blockData[365]" :tabData="blockData[365].classifyData" id="tabs" ref="tabs" :class="tabFixed == true ? 'isfixed' : ''" :style="fixedWidth" />
        <!--工商信息 -->
        <enterprise-info v-if="blockData[365]" :tyc-data="blockData[365]" id="001" />
        <!--主要人员信息 -->
        <staff-info v-if="blockData[365]&&blockData[365].tycResult.staffList" :tyc-data="blockData[365]" id="002" />
        <!--1、公司背景information-->
        <!--股东信息1-1-->
        <partner-info v-if="blockData[368]" :tyc-data="blockData[368]" id="368" />
        <!--对外投资1-1-->
        <investment-info v-if="blockData[369]" :tyc-data="blockData[369]" id="369" />
        <!--变更记录1-0-->
        <log-change v-if="blockData[370]" :tyc-data="blockData[370]" id="370" />
        <!--企业年报1-1-->
        <report v-if="blockData[371]" :tyc-data="blockData[371]" id="371" />
        <!--分支机构1-0-->
        <branch v-if="blockData[372]" :tyc-data="blockData[372]" id="372" />
        <!--股权结构0-->
        <origin-info v-if="blockData[340]" :tyc-data="blockData[340]" id="340" />
        <!--2、企业发展development-->
        <!--融资历史1-1-->
        <raise v-if="blockData[377]" :tyc-data="blockData[377]" id="377" />
        <!--核心团队1-1-->
        <team v-if="blockData[378]" :tyc-data="blockData[378]" id="378" />
        <!--企业业务1-1-->
        <service v-if="blockData[379]" :tyc-data="blockData[379]" id="379" />
        <!--投资事件1-0-->
        <invest v-if="blockData[380]" :tyc-data="blockData[380]" id="380" />
        <!--竞品信息1-0-->
        <similarity v-if="blockData[381]" :tyc-data="blockData[381]" id="381" />
        <!--3、司法风险law-->
        <!--法律诉讼1-0-->
        <lawsuit v-if="blockData[384]" :tyc-data="blockData[384]" id="384"></lawsuit>
        <!--法院公告1-1-->
        <announcement v-if="blockData[385]" :tyc-data="blockData[385]" id="385"></announcement>
        <!--失信人1-0-->
        <letdownInfo v-if="blockData[387]" :tyc-data="blockData[340]" id="340"></letdownInfo>
        <!--被执行人1-0-->
        <execute v-if="blockData[389]" :tyc-data="blockData[389]" id="389"></execute>
        <!--开庭公告1-1-->
        <courtSession v-if="blockData[386]" :tyc-data="blockData[386]" id="386"></courtSession>
        <!--4、经营风险manageRisk-->
        <!--经营异常1-0-->
        <exception v-if="blockData[391]" :tyc-data="blockData[391]" id="391"></exception>
        <!--行政处罚1-0-->
        <punish v-if="blockData[399]" :tyc-data="blockData[399]" id="399"></punish>
        <!--信用中国1-0-->
        <creditableChina v-if="blockData[401]" :tyc-data="blockData[401]" id="401"></creditableChina>
        <!--严重违法1-0-->
        <illegal v-if="blockData[402]" :tyc-data="blockData[402]" id="402"></illegal>
        <!--股权出质1-0-->
        <equity v-if="blockData[404]" :tyc-data="blockData[404]" id="404"></equity>
        <!--动产抵押-1-0-->
        <pledge v-if="blockData[421]" :tyc-data="blockData[421]" id="421"></pledge>
        <!--欠税公告0-0-->
        <owingtax v-if="blockData[422]" :tyc-data="blockData[422]" id="422"></owingtax>
        <!--司法拍卖0-0(是否有弹窗)-->
        <auction v-if="blockData[423]" :tyc-data="blockData[423]" id="423"></auction>
        <!--5、经营状况manageStatus-->
        <!--投招标1-1-->
        <tender v-if="blockData[429]" :tyc-data="blockData[429]" id="429"></tender>
        <!--债券信息1-1-->
        <bond v-if="blockData[430]" :tyc-data="blockData[430]" id="430"></bond>
        <!--购地信息0-0-->
        <buyland v-if="blockData[431]" :tyc-data="blockData[431]" id="431"></buyland>
        <!--招聘1-1-->
        <recruitment v-if="blockData[432]" :tyc-data="blockData[432]" id="432"></recruitment>
        <!--税务评级1-1-->
        <taxrate v-if="blockData[433]" :tyc-data="blockData[433]" id="433"></taxrate>
        <!--抽查检查1-0-->
        <spottest v-if="blockData[434]" :tyc-data="blockData[434]" id="434"></spottest>
        <!--产品信息1-0-->
        <infoList v-if="blockData[435]" :tyc-data="blockData[435]" id="435"></infoList>
        <!--证书1-1-->
        <credential v-if="blockData[437]" :tyc-data="blockData[437]" id="437"></credential>
        <!--进出口信用1-1-->
        <trading v-if="blockData[436]" :tyc-data="blockData[436]" id="436"></trading>
        <!--6、知识产权property-->
        <!--微信公众号1-1-->
        <weChatOfficialAccount v-if="blockData[731]" :tyc-data="blockData[731]" id="731"></weChatOfficialAccount>
        <!--商标信息1-1-->
        <trademarkInfo v-if="blockData[439]" :tyc-data="blockData[439]" id="439"></trademarkInfo>
        <!--专利信息1-1-->
        <patentInfo v-if="blockData[440]" :tyc-data="blockData[440]" id="440"></patentInfo>
        <!--作品著作权1-1-->
        <copyright v-if="blockData[733]" :tyc-data="blockData[733]" id="733"></copyright>
        <!--著作权1-1-->
        <literary v-if="blockData[441]" :tyc-data="blockData[441]" id="441"></literary>
        <!--网站备案1-1-->
        <filing v-if="blockData[442]" :tyc-data="blockData[442]" id="442"></filing>
      </div>
    </div>
  </div>
</template>

<script>
import TabNav from "@/views/corporateCredit/common/tab-nav";
import { skyEyeReturnRoute } from "@/components/businessDetail/helper";
// import { mapGetters } from "vuex";
// import { BaseInfo, EnterpriseInfo, StaffInfo, PartnerInfo, InvestmentInfo, LogChange, Report, Branch, OriginInfo } from "@/views/corporateCredit/information";

import BaseInfo from "@/views/corporateCredit/information/base-info";
import EnterpriseInfo from "@/views/corporateCredit/information/enterprise-info";
import StaffInfo from "@/views/corporateCredit/information/staff-info";

import PartnerInfo from "@/views/corporateCredit/information/partner-info";
import InvestmentInfo from "@/views/corporateCredit/information/investment-info";
import LogChange from "@/views/corporateCredit/information/log-change";
import Report from "@/views/corporateCredit/information/report";
import Branch from "@/views/corporateCredit/information/branch";
import OriginInfo from "@/views/corporateCredit/information/origin-info";

import Lawsuit from "@/views/corporateCredit/law/lawsuit";
import Announcement from "@/views/corporateCredit/law/announcement";
import LetdownInfo from "@/views/corporateCredit/law/letdownInfo";
import CourtSession from "@/views/corporateCredit/law/courtSession";
import Execute from "@/views/corporateCredit/law/execute";

import Team from "@/views/corporateCredit/development/team";
import Service from "@/views/corporateCredit/development/service";
import Invest from "@/views/corporateCredit/development/invest";
import Raise from "@/views/corporateCredit/development/raise";
import Similarity from "@/views/corporateCredit/development/similarity";

import Exception from "@/views/corporateCredit/manageRisk/exception";
import Punish from "@/views/corporateCredit/manageRisk/punish";
import CreditableChina from "@/views/corporateCredit/manageRisk/creditableChina";
import Equity from "@/views/corporateCredit/manageRisk/equity";
import Pledge from "@/views/corporateCredit/manageRisk/pledge";
import Auction from "@/views/corporateCredit/manageRisk/auction";
import Illegal from "@/views/corporateCredit/manageRisk/illegal";
import Owingtax from "@/views/corporateCredit/manageRisk/owingtax";

import Tender from "@/views/corporateCredit/manageStatus/tender";
import Bond from "@/views/corporateCredit/manageStatus/bond";
import Buyland from "@/views/corporateCredit/manageStatus/buyland";
import Recruitment from "@/views/corporateCredit/manageStatus/recruitment";
import Taxrate from "@/views/corporateCredit/manageStatus/taxrate";
import Spottest from "@/views/corporateCredit/manageStatus/spottest";
import InfoList from "@/views/corporateCredit/manageStatus/infoList";
import Credential from "@/views/corporateCredit/manageStatus/credential";
import Trading from "@/views/corporateCredit/manageStatus/trading";

import TrademarkInfo from "@/views/corporateCredit/property/trademarkInfo";
import PatentInfo from "@/views/corporateCredit/property/patentInfo";
import Copyright from "@/views/corporateCredit/property/copyright";
import WeChatOfficialAccount from "@/views/corporateCredit/property/weChatOfficialAccount";
import Literary from "@/views/corporateCredit/property/literary";
import Filing from "@/views/corporateCredit/property/filing";

import { corporateService } from "@/api";

export default {
  name: "Home",
  components: {
    TabNav,
    BaseInfo,
    EnterpriseInfo,
    StaffInfo,
    PartnerInfo,
    InvestmentInfo,
    LogChange,
    Report,
    Branch,
    OriginInfo,

    Team,
    Service,
    Invest,
    Raise,
    Similarity,

    Lawsuit,
    Announcement,
    CourtSession,
    LetdownInfo,
    Execute,

    Exception,
    Punish,
    CreditableChina,
    Equity,
    Pledge,
    Auction,
    Illegal,
    Owingtax,

    Tender,
    Bond,
    Buyland,
    Recruitment,
    Taxrate,
    Spottest,
    InfoList,
    Credential,
    Trading,

    TrademarkInfo,
    PatentInfo,
    Copyright,
    WeChatOfficialAccount,
    Literary,
    Filing
  },
  data() {
    return {
      blockData: [],
      params: { reqTycData: {} },
      tabFixed: false,
      fixedWidth: {
        width: "auto"
      },
      urlData: {},
      tycLoding: false
    };
  },
  beforeRouteEnter(to, from, next) {
    if (from.name === "businessDetail" || from.name === "list") {
      to.meta.keepAlive = false;
    } else {
      to.meta.keepAlive = true;
    }
    next();
  },
  mounted() {
    this.getUrlParams(this.$route.query || {});
    this.getBlockData(365, {}, 1);
    this.isFirstEnter = true;
  },
  computed: {
    busModel() {
      // 获取业务建模数据
      return this.$store.state.businessModel.skyEyeBusinessData;
    }
  },
  watch: {},
  methods: {
    /**
     * @description: 跳转对应模块
     */
    getBlockData(key, TycData, aginSel, pageChange) {
      if ((!this.blockData[key] || pageChange) && key !== "001" && key !== "002") {
        if (pageChange) {
          this.blockData[key] = {};
        }
        this.queryTYC(key, TycData, aginSel);
      } else {
        this.jump(key);
      }
    },
    /**
     * @description: 获取块级数据
     * @param {type}key:tycType,TycData:reqTycData,aginSel:0: 是1:否 是否是再次查询;
     * @return:
     */
    async queryTYC(key, TycData, aginSel = 1, shouldJump = true) {
      try {
        this.tycLoding = true;
        const pa = {
          saveType: this.params.saveType,
          interfaceId: this.params.interfaceId,
          businessData: this.params.businessData,
          tycType: Number(key),
          aginSel: Number(aginSel),
          reqTycData: JSON.stringify({ ...this.params.reqTycData, ...TycData })
        };
        const rsp = await corporateService.queryTYC(pa);
        this.$set(this.blockData, key, rsp);
        if (key === 365) {
          this.params.reqTycData.id = rsp.tycResult.id || "";
        }
        console.log(this.blockData);
        this.$refs.tycmain.addEventListener("scroll", this.pageScroll);
        this.showblockData = true;
        shouldJump && this.jump(key);
      } catch (e) {
        console.error(e);
      } finally {
        this.tycLoding = false;
      }
    },
    /**
     * @description: 获取地址栏参数
     */
    getUrlParams(val) {
      this.urlData = val;
      // 新增或者待处理的数据，saveType传1，已提交传2
      if (val.billStatus === "000" || val.billStatus === "001") {
        this.params.saveType = "1";
      } else {
        this.params.saveType = "2";
      }
      this.params.reqTycData.name = val.name || "";
      if (val.id) {
        this.params.reqTycData.id = val.id;
      }
      this.params.interfaceId = this.busModel.interfaceId || "";
      this.params.businessData = this.busModel.businessData;
    },
    /**
     * @description: 块级展示
     * @param key:cede,aginSel:是否再次,pageNo:页码,pageSize:,pageChange:是否重新查询接口
     */
    blockEvent(key, aginSel, pageChange, pageNo, pageSize) {
      const listArr = ["401", "402", "404", "421", "422", "423", "429", "430", "431", "432", "433", "434", "435", "436", "437", "439", "440", "441", "442", "731", "733"];
      let TycData = {};
      if (listArr.includes(key)) {
        TycData = { pageNum: pageNo || 1, pageSize: pageSize || 10 };
      }
      this.getBlockData(key, TycData, aginSel || 1, pageChange);
    },
    /**
     * @description: 导航栏滚动事件
     */
    pageScroll() {
      const pageid = this.$refs.tycmain;
      const pageidW = pageid.clientWidth;
      const scrollTop = pageid.pageYOffset || pageid.scrollTop;
      const offsetTop = document.querySelector("#tabs").offsetTop;
      if (scrollTop > offsetTop) {
        this.tabFixed = true;
        this.fixedWidth.width = `${pageidW}px`;
      } else {
        this.tabFixed = false;
        this.fixedWidth.width = "auto";
      }
    },
    /**
     * @description: 点击跳转锚点事件
     */
    jump(target) {
      const ids = document.getElementById(target);
      let hs = 0;
      if (ids) {
        hs = ids.offsetTop;
      } else {
        hs = document.getElementById("main-cent").offsetHeight;
      }
      document.getElementById("tycmain").scrollTop = hs;
      // if (target) {
      //   this.$nextTick(() => {
      //     window.location.hash = `#${target}`;
      //   });
      // }
    },
    /**
     * @description: 返回业务建模详情
     */
    backBusiness() {
      const pa = {
        id: JSON.parse(this.busModel.businessData).id,
        business: this.urlData.business,
        path: this.$route.query.returnPath
      };
      const newRoute = skyEyeReturnRoute(pa);
      this.$router.replace(newRoute);
    }
  }
};
</script>

<style lang="scss" scoped>
.mainwarp {
  background-color: #1d2532;
  height: 100%;
  width: 100%;
}
.main {
  height: 100%;
  overflow-y: auto;
}
.isfixed {
  position: fixed;
  top: 71px;
  z-index: 99;
}
</style>
