var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("transition", [
        _c("div", { attrs: { id: "home-router" } }, [
          _c(
            "div",
            { staticClass: "left-area inline" },
            [
              _c(
                "keep-alive",
                [_c("router-view", { staticClass: "container" })],
                1
              ),
            ],
            1
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }