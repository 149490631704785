<!--
 * @Author: zhulin
 * @Date: 2020-07-22 10:34:01
 * @LastEditors: zhulin
 * @LastEditTime: 2020-08-06 14:33:38
 * @Description: 行政处罚
 * @FilePath: \user\src\views\corporateCredit\manageRisk\components\punishAlert.vue
-->
<template>
  <en-dialog title="行政处罚" :visible.sync="show" width="850px" @close="closeDialog">
    <div class="punish-content">
      <div class="punish-info">
        <table>
          <tr>
            <td>决定书文号</td>
            <td>{{ data.punishNumber }}</td>
          </tr>
          <tr>
            <td>类型</td>
            <td>{{ data.type }}</td>
          </tr>
          <tr>
            <td>处罚内容</td>
            <td>{{ data.content }}</td>
          </tr>
          <tr>
            <td>决定日期</td>
            <td>{{ data.decisionDate }}</td>
          </tr>
          <tr>
            <td>决定机关</td>
            <td>{{ data.departmentName }}</td>
          </tr>
          <tr>
            <td>法人</td>
            <td>{{ data.legalPersonName }}</td>
          </tr>
          <tr>
            <td>备注</td>
            <td>{{ data.description || "" }}</td>
          </tr>
        </table>
      </div>
    </div>
  </en-dialog>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false
    },
    infoData: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      data: {}
    };
  },
  watch: {
    /**
     * @description: 监听弹窗
     */
    show(val) {
      if (val) {
        this.data = this.infoData || {};
      }
    }
  },
  methods: {
    /**
     * @description: 关闭弹窗回调
     */
    closeDialog() {
      this.$emit("toShow", false);
    }
  }
};
</script>

<style lang="scss" scoped>
.punish-content {
  .punish-info {
    margin: 20px 0 50px 0;
    table {
      border-collapse: collapse;
      border-spacing: 0;
      width: 100%;
      tr {
        td {
          padding: 20px 18px;
          border: 1px solid #e8ecf2;
          font-size: 12px;
          text-align: left;
        }
        td:nth-child(1) {
          color: #333;
          background-color: #f6fafd;
          width: 200px;
        }
        td:nth-child(2) {
          color: #636c78;
        }
      }
    }
  }
}
</style>
