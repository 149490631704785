<!--
 * @Author: zhulin
 * @Date: 2020-07-22 10:34:01
 * @LastEditors: zhulin
 * @LastEditTime: 2020-08-07 17:09:55
 * @Description: 对外投资369
 * @FilePath: \user\src\views\corporateCredit\information\investment-info.vue
-->
<template>
  <div id="investmentInfo" ref="investmentInfo" class="tyc-settings investmentInfo">
    <div class="title clearfix">
      <div class="leftbar">
        <span>对外投资({{ tycData.tycResult.total }})</span>
      </div>
      <div class="rightbar">
        <en-icon name="iconzaicichayan-qiyezhengxin" size="22px" color="#ffb540" @click.native="reCheck()"> </en-icon>
      </div>
    </div>
    <div class="base">
      <en-table :data="tycData.tycResult.items" :page-config="{ pageModel: pageModel, changeMethod: handlePageChanged }">
        <en-table-column type="index" width="60px"></en-table-column>
        <en-table-column prop="name" label="被投资公司名称">
          <template slot-scope="scope">
            <div class="table-slot">
              <span>
                <en-user-logo :user-name="scope.row.name" size="30"></en-user-logo>
                {{ scope.row.name }}
              </span>
            </div>
          </template>
        </en-table-column>
        <en-table-column prop="legalPersonName" label="被投资法定代表人"> </en-table-column>
        <en-table-column prop="regCapital" label="注册资本"> </en-table-column>
        <en-table-column prop="percent" label="投资占比"> </en-table-column>
        <en-table-column prop="estiblishTime" label="注册时间"> </en-table-column>
        <en-table-column prop="regStatus" label="状态">
          <template slot-scope="scope">
            <el-tag type="success">{{ scope.row.regStatus }}</el-tag>
          </template>
        </en-table-column>
      </en-table>
    </div>
  </div>
</template>

<script>
export default {
  name: "investmentInfo",
  props: {
    tycData: {
      type: Object,
      default() {}
    }
  },
  data() {
    return {
      tableData: [],
      pageModel: {
        pageNo: this.tycData.pageNo || 1,
        pageSize: this.tycData.pageSize || 10,
        total: this.tycData.tycResult.total || "",
        totalPages: this.tycData.totalPages || Math.ceil(this.tycData.tycResult.total / (this.tycData.pageSize || 10))
      }
    };
  },
  computed: {},
  methods: {
    /**
     * @description: 页码
     */
    handlePageChanged({ pageSize, pageNo }) {
      this.$parent.blockEvent("369", 1, true, pageNo, pageSize);
    },
    /**
     * @description: 再次查验
     */
    reCheck() {
      this.$parent.blockEvent("369", 0, true);
    }
  }
};
</script>

<style lang="scss" scoped>
.investmentInfo {
  margin-top: 10px;
  .page-bot {
    font-size: 12px;
    padding: 20px;
    text-align: right;
  }
}
.base {
  margin-top: -1px;
}
.table-slot {
  display: flex;
  justify-content: space-between;
  span:last-child {
    color: #999;
  }
}
.tips {
  display: inline-block;
  background: #e9f9f4;
  color: #26c393;
  padding: 0 20px;
  line-height: 26px;
  border-radius: 5px;
  &.tips-error {
    color: #f76b6b;
    background: #f7ebeb;
  }
}
</style>
