<!--
 * @Author: Fenngding
 * @Date: 2019-09-23 10:29:54
 * @LastEditors: pengyu
 * @LastEditTime: 2020-06-07 15:10:08
 * @Email: fenngding@live.com
 * @Description:
 -->
<template>
  <div class="main">
    <!--tab-->
    <tab-nav></tab-nav>
    <!--微信公众号-->
    <weChatOfficialAccount></weChatOfficialAccount>
    <!--商标信息-->
    <trademarkInfo></trademarkInfo>
    <!--专利信息-->
    <patentInfo></patentInfo>
    <!--作品著作权-->
    <copyright></copyright>
    <!--著作权-->
    <literary></literary>
    <!--网站备案-->
    <filing></filing>
  </div>
</template>

<script>
import TabNav from "@/views/corporateCredit/common/tab-nav";
import TrademarkInfo from "@/views/corporateCredit/property/trademarkInfo";
import PatentInfo from "@/views/corporateCredit/property/patentInfo";
import Copyright from "@/views/corporateCredit/property/copyright";
import WeChatOfficialAccount from "@/views/corporateCredit/property/weChatOfficialAccount";
import Literary from "@/views/corporateCredit/property/literary";
import Filing from "@/views/corporateCredit/property/filing";

export default {
  name: "Property",
  components: {
    TabNav,
    TrademarkInfo,
    PatentInfo,
    Copyright,
    WeChatOfficialAccount,
    Literary,
    Filing
  },
  data() {
    return {};
  },
  computed: {},
  watch: {
    $route: { // 监听路由变化,当query的location发送变动时,在下一帧跳转到指定dom(该dom需要有id属性)
      immediate: true,
      deep: true,
      handler(val) {
        const { location } = val.query;
        if (location) {
          this.$nextTick(() => {
            window.location.hash = location;
          });
        }
      }
    }
  },
  methods: {}
};
</script>

<style lang="scss" scoped>
</style>
