<!--
 * @Author: zhulin
 * @Date: 2020-07-22 10:34:01
 * @LastEditors: zhulin
 * @LastEditTime: 2020-08-06 15:52:00
 * @Description: 进出口详情
 * @FilePath: \user\src\views\corporateCredit\manageStatus\components\tradingAlert.vue
-->
<template>
  <en-dialog title="进出口信用" :visible.sync="show" width="850px" @close="closeDialog">
    <div class="patentInfo-content">
      <div class="patentInfo-content-title">
        注册信息
      </div>
      <div class="patentInfo-info">
        <table>
          <tr>
            <td>海关注册编码</td>
            <td>{{ data.recordDate || "" }}</td>
            <td>纳税人识别号</td>
            <td>{{ data.crCode || "" }}</td>
          </tr>
          <tr>
            <td>注册海关</td>
            <td>{{ data.customsRegisteredAddress || "" }}</td>
            <td>行政区划</td>
            <td>{{ data.administrativeDivision || "" }}</td>
          </tr>
          <tr>
            <td>经济区划</td>
            <td>{{ data.economicDivision || "" }}</td>
            <td>经营类别</td>
            <td>{{ data.managementCategory || "" }}</td>
          </tr>
          <tr>
            <td>特殊贸易区域</td>
            <td>{{ data.specialTradeArea || "" }}</td>
            <td>行业种类</td>
            <td>{{ data.industryCategory || "" }}</td>
          </tr>
          <tr>
            <td>报关有效期</td>
            <td>{{ data.validityDate || "" }}</td>
            <td>海关注销标识</td>
            <td>{{ data.status || "" }}</td>
          </tr>
          <tr>
            <td>年报情况</td>
            <td>{{ data.validityDate || "" }}</td>
            <td>跨境贸易电子商务类型</td>
            <td>{{ data.types || "" }}</td>
          </tr>
        </table>
      </div>
    </div>
  </en-dialog>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false
    },
    infoData: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      data: {}
    };
  },
  watch: {
    /**
     * @description: 监听弹窗
     */
    show(val) {
      if (val) {
        this.data = this.infoData || {};
      }
    }
  },
  methods: {
    /**
     * @description: 关闭弹窗回调
     */
    closeDialog() {
      this.$emit("toShow", false);
    }
  }
};
</script>

<style lang="scss" scoped>
.patentInfo-content {
  .patentInfo-content-title {
    font-size: 12px;
    color: #303133;
    font-weight: bold;
    margin: 20px 0 15px 0;
  }
  .patentInfo-info {
    margin: 20px 0 50px 0;
    table {
      border-collapse: collapse;
      border-spacing: 0;
      width: 100%;
      tr {
        td {
          padding: 20px 18px;
          border: 1px solid #e8ecf2;
          font-size: 12px;
          text-align: left;
        }
        td:nth-child(1),
        td:nth-child(3) {
          color: #333;
          background-color: #f6fafd;
          width: 200px;
        }
        td:nth-child(2) {
          color: #636c78;
        }
      }
    }
  }
}
</style>
