var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "trademarkInfo",
      staticClass: "tyc-settings partner",
      attrs: { id: "trademarkInfo" },
    },
    [
      _c("div", { staticClass: "title clearfix" }, [
        _c("div", { staticClass: "leftbar" }, [
          _c("span", [
            _vm._v("商标信息(" + _vm._s(_vm.tycData.tycResult.total) + ")"),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "rightbar" },
          [
            _c("en-icon", {
              attrs: {
                name: "iconzaicichayan-qiyezhengxin",
                size: "22px",
                color: "#ffb540",
              },
              nativeOn: {
                click: function ($event) {
                  return _vm.reCheck()
                },
              },
            }),
          ],
          1
        ),
      ]),
      _c(
        "en-table",
        { attrs: { data: _vm.tycData.tycResult.items } },
        [
          _c("en-table-column", { attrs: { type: "index", width: "60px" } }),
          _c("en-table-column", {
            attrs: { prop: "appDate", label: "申请日期", width: "160" },
          }),
          _c("en-table-column", {
            attrs: { label: "商标", width: "200" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("div", { staticClass: "table-slot" }, [
                      _c(
                        "span",
                        [
                          _c("en-user-logo", {
                            attrs: {
                              "image-url": scope.row.tmPic,
                              "user-name": scope.row.tmName,
                              size: "30",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("en-table-column", {
            attrs: { prop: "tmName", label: "商标名称" },
          }),
          _c("en-table-column", { attrs: { prop: "regNo", label: "注册号" } }),
          _c("en-table-column", { attrs: { prop: "intCls", label: "类别" } }),
          _c("en-table-column", {
            attrs: { prop: "category", label: "流程状态", width: "250" },
          }),
        ],
        1
      ),
      _c("en-pagination", {
        attrs: { "page-model": _vm.pageModel },
        on: { change: _vm.handlePageChanged },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }