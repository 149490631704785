var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "infoList",
      staticClass: "tyc-settings partner",
      attrs: { id: "infoList" },
    },
    [
      _c("div", { staticClass: "title clearfix" }, [
        _c("div", { staticClass: "leftbar" }, [
          _c("span", [
            _vm._v("产品信息(" + _vm._s(_vm.tycData.tycResult.total) + ")"),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "rightbar" },
          [
            _c("en-icon", {
              attrs: {
                name: "iconzaicichayan-qiyezhengxin",
                size: "22px",
                color: "#ffb540",
              },
              nativeOn: {
                click: function ($event) {
                  return _vm.reCheck()
                },
              },
            }),
          ],
          1
        ),
      ]),
      _c(
        "en-table",
        {
          attrs: { data: _vm.tycData.tycResult.items },
          on: { "row-click": _vm.showInfo },
        },
        [
          _c("en-table-column", { attrs: { type: "index", width: "60px" } }),
          _c("en-table-column", {
            attrs: { prop: "name", label: "产品名称" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("div", { staticClass: "table-slot" }, [
                      _c(
                        "span",
                        [
                          _c("en-user-logo", {
                            attrs: {
                              "image-url": scope.row.icon,
                              "user-name": scope.row.filterName,
                              size: "30",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("span", [_vm._v(_vm._s(scope.row.name || "-"))]),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("en-table-column", {
            attrs: { prop: "filterName", label: "产品简称" },
          }),
          _c("en-table-column", { attrs: { prop: "type", label: "产品分类" } }),
          _c("en-table-column", { attrs: { prop: "classes", label: "领域" } }),
        ],
        1
      ),
      _c("en-pagination", {
        attrs: { "page-model": _vm.pageModel },
        on: { change: _vm.handlePageChanged },
      }),
      _c("infolist-alert", {
        ref: "infolistAlert",
        attrs: { show: _vm.showAlert, "info-data": _vm.infoData },
        on: { toShow: _vm.toShow },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }