var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "copyright",
      staticClass: "tyc-settings",
      attrs: { id: "copyright" },
    },
    [
      _c("div", { staticClass: "title clearfix" }, [
        _c("div", { staticClass: "leftbar" }, [
          _c("span", [
            _vm._v("作品著作权(" + _vm._s(_vm.tycData.tycResult.total) + ")"),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "rightbar" },
          [
            _c("en-icon", {
              attrs: {
                name: "iconzaicichayan-qiyezhengxin",
                size: "22px",
                color: "#ffb540",
              },
              nativeOn: {
                click: function ($event) {
                  return _vm.reCheck()
                },
              },
            }),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "base" }, [
        _c(
          "div",
          { staticClass: "base-inner" },
          [
            _c(
              "en-table",
              {
                attrs: {
                  data: _vm.tycData.tycResult.items,
                  "row-key": "id",
                  border: "",
                  "default-expand-all": "",
                },
              },
              [
                _c("en-table-column", {
                  attrs: { type: "index", label: "序号", width: "60" },
                }),
                _c("en-table-column", {
                  attrs: { prop: "fullname", label: "作品名称" },
                }),
                _c("en-table-column", {
                  attrs: { prop: "regnum", label: "登记号" },
                }),
                _c("en-table-column", {
                  attrs: { prop: "type", label: "类别" },
                }),
                _c("en-table-column", {
                  attrs: {
                    prop: "finishTime",
                    label: "创作完成日期",
                    width: "155",
                  },
                }),
                _c("en-table-column", {
                  attrs: { prop: "regtime", label: "登记日期", width: "155" },
                }),
                _c("en-table-column", {
                  attrs: {
                    prop: "publishtime",
                    label: "首次发布日期",
                    width: "155",
                  },
                }),
              ],
              1
            ),
            _c("en-pagination", {
              attrs: { "page-model": _vm.pageModel },
              on: { change: _vm.handlePageChanged },
            }),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }