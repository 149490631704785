<template>
  <div>
    <originTree />
  </div>
</template>

<script>
import originTree from "@/views/corporateCredit/information/origin-tree";

export default {
  components: { originTree },
  data() {
    return {};
  }
};
</script>
<style>
#app #home-router .home-content .left-area.inline .router-show-area .container {
  background-color: #fff;
  overflow-x: auto;
}
</style>
