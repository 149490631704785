<!--
 * @Author: Do not edit
 * @Date: 2020-07-22 10:34:01
 * @LastEditors: zhulin
 * @LastEditTime: 2020-08-06 16:25:40
 * @Description: 产品信息435
 * @FilePath: \user\src\views\corporateCredit\manageStatus\infoList.vue
-->
<template>
  <div id="infoList" ref="infoList" class="tyc-settings partner">
    <div class="title clearfix">
      <div class="leftbar">
        <span>产品信息({{ tycData.tycResult.total }})</span>
      </div>
      <div class="rightbar">
        <en-icon name="iconzaicichayan-qiyezhengxin" size="22px" color="#ffb540" @click.native="reCheck()"> </en-icon>
      </div>
    </div>
    <en-table :data="tycData.tycResult.items" @row-click="showInfo">
      <en-table-column type="index" width="60px"></en-table-column>
      <en-table-column prop="name" label="产品名称">
        <template slot-scope="scope">
          <div class="table-slot">
            <span>
              <en-user-logo :image-url="scope.row.icon" :user-name="scope.row.filterName" size="30"></en-user-logo>
            </span>
            <span>{{ scope.row.name || "-" }}</span>
          </div>
        </template>
      </en-table-column>
      <en-table-column prop="filterName" label="产品简称"> </en-table-column>
      <en-table-column prop="type" label="产品分类"> </en-table-column>
      <en-table-column prop="classes" label="领域"> </en-table-column>
    </en-table>
    <en-pagination :page-model="pageModel" @change="handlePageChanged"></en-pagination>
    <!--产品信息详情-->
    <infolist-alert ref="infolistAlert" :show="showAlert" :info-data="infoData" @toShow="toShow"></infolist-alert>
  </div>
</template>

<script>
import infolistAlert from "./components/infolistAlert";

export default {
  name: "InfoList",
  components: { infolistAlert },
  props: {
    tycData: {
      type: Object,
      default() {}
    }
  },
  data() {
    return {
      tableData: [],
      showAlert: false,
      infoData: {},
      pageModel: {
        pageNo: this.tycData.pageNo || 1,
        pageSize: this.tycData.pageSize || 10,
        total: this.tycData.tycResult.total || "",
        totalPages: this.tycData.totalPages || Math.ceil(this.tycData.tycResult.total / (this.tycData.pageSize || 10))
      }
    };
  },
  computed: {},
  methods: {
    /**
     * @description: 页码
     */
    handlePageChanged({ pageSize, pageNo }) {
      this.$parent.blockEvent("435", 1, true, pageNo, pageSize);
    },
    /**
     * @description: 再次查验
     */
    reCheck() {
      this.$parent.blockEvent("435", 0, true);
    },
    /**
     * @description: 打开弹窗
     */
    showInfo(item) {
      this.showAlert = true;
      this.infoData = item.row;
    },
    /**
     * @description: 关闭弹窗
     */
    toShow() {
      this.showNotice = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.partner {
  margin-top: 10px;
}
.table-slot {
  display: flex;
  justify-content: space-between;
  span:last-child {
    color: #999;
  }
}
</style>
