<!--
 * @Author: Do not edit
 * @Date: 2020-07-22 10:34:01
 * @LastEditors: zhulin
 * @LastEditTime: 2020-08-07 17:12:15
 * @Description: 股东信息368
 * @FilePath: \user\src\views\corporateCredit\information\partner-info.vue
-->
<template>
  <div id="partnerInfo" ref="partnerInfo" class="tyc-settings partner">
    <div class="title">
      <div class="leftbar">
        <span>股东信息({{ tycData.tycResult.total }})</span>
      </div>
      <div class="rightbar">
        <en-icon name="iconzaicichayan-qiyezhengxin" size="22px" color="#ffb540" @click.native="reCheck()"> </en-icon>
      </div>
    </div>
    <div class="base">
      <en-table :data="tycData.tycResult.items">
        <en-table-column type="index" width="60px"></en-table-column>
        <en-table-column prop="name" label="姓名">
          <template slot-scope="scope">
            <div class="table-slot">
              <span>
                <!-- <en-user-logo :user-name="sub_str(scope.row.name)" size="30"></en-user-logo> -->
                {{ scope.row.name }}
              </span>
              <span>他有{{ scope.row.toco || "-" }}家公司</span>
            </div>
          </template>
        </en-table-column>
        <en-table-column prop="percent" label="出资比例">
          <template slot-scope="scope">
            {{ scope.row.capital[0].percent || "-" }}
          </template>
        </en-table-column>
        <en-table-column prop="amomon" label="认缴出资">
          <template slot-scope="scope">
            {{ scope.row.capital[0].amomon || "-" }}
          </template>
        </en-table-column>
        <en-table-column prop="time" label="出资时间">
          <template slot-scope="scope">
            {{ scope.row.capital[0].time || "-" }}
          </template>
        </en-table-column>
      </en-table>
      <div class="page-bot">
        <span>共{{ tycData.tycResult.total }}条</span>
      </div>
    </div>

    <!-- <en-pagination :page-model="pageModel"></en-pagination> -->
  </div>
</template>

<script>
export default {
  name: "partnerInfo",
  props: {
    tycData: {
      type: Object,
      default() {}
    }
  },
  data() {
    return {
      tableData: [],
      pageModel: {
        pageNo: this.tycData.pageNo || 1,
        pageSize: this.tycData.pageSize || 10,
        total: this.tycData.tycResult.total || "",
        totalPages: this.tycData.totalPages || Math.ceil(this.tycData.tycResult.total / (this.tycData.pageSize || 10))
      }
    };
  },
  computed: {},
  methods: {
    /**
     * @description: 页码
     */
    handlePageChanged({ pageSize, pageNo }) {
      this.$parent.blockEvent("368", 1, true, pageNo, pageSize);
    },
    /**
     * @description: 再次查验
     */
    reCheck() {
      this.$parent.blockEvent("368", 0, true);
    }
  }
};
</script>

<style lang="scss" scoped>
.partner {
  margin-top: 10px;
  .page-bot {
    font-size: 12px;
    padding: 20px;
    text-align: right;
  }
}
.base {
  margin-top: -1px;
}
.table-slot {
  display: flex;
  justify-content: space-between;
  span:last-child {
    color: #999;
  }
}
</style>
