var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "investmentInfo",
      staticClass: "tyc-settings investmentInfo",
      attrs: { id: "investmentInfo" },
    },
    [
      _c("div", { staticClass: "title clearfix" }, [
        _c("div", { staticClass: "leftbar" }, [
          _c("span", [
            _vm._v("对外投资(" + _vm._s(_vm.tycData.tycResult.total) + ")"),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "rightbar" },
          [
            _c("en-icon", {
              attrs: {
                name: "iconzaicichayan-qiyezhengxin",
                size: "22px",
                color: "#ffb540",
              },
              nativeOn: {
                click: function ($event) {
                  return _vm.reCheck()
                },
              },
            }),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "base" },
        [
          _c(
            "en-table",
            {
              attrs: {
                data: _vm.tycData.tycResult.items,
                "page-config": {
                  pageModel: _vm.pageModel,
                  changeMethod: _vm.handlePageChanged,
                },
              },
            },
            [
              _c("en-table-column", {
                attrs: { type: "index", width: "60px" },
              }),
              _c("en-table-column", {
                attrs: { prop: "name", label: "被投资公司名称" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", { staticClass: "table-slot" }, [
                          _c(
                            "span",
                            [
                              _c("en-user-logo", {
                                attrs: {
                                  "user-name": scope.row.name,
                                  size: "30",
                                },
                              }),
                              _vm._v(" " + _vm._s(scope.row.name) + " "),
                            ],
                            1
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("en-table-column", {
                attrs: { prop: "legalPersonName", label: "被投资法定代表人" },
              }),
              _c("en-table-column", {
                attrs: { prop: "regCapital", label: "注册资本" },
              }),
              _c("en-table-column", {
                attrs: { prop: "percent", label: "投资占比" },
              }),
              _c("en-table-column", {
                attrs: { prop: "estiblishTime", label: "注册时间" },
              }),
              _c("en-table-column", {
                attrs: { prop: "regStatus", label: "状态" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-tag", { attrs: { type: "success" } }, [
                          _vm._v(_vm._s(scope.row.regStatus)),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }