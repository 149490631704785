var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "log",
      staticClass: "tyc-settings change-log",
      attrs: { id: "log" },
    },
    [
      _c("div", { staticClass: "title clearfix" }, [
        _c("div", { staticClass: "leftbar" }, [
          _c("span", [
            _vm._v("变更记录(" + _vm._s(_vm.tycData.tycResult.total) + ")"),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "rightbar" },
          [
            _c("en-icon", {
              attrs: {
                name: "iconzaicichayan-qiyezhengxin",
                size: "22px",
                color: "#ffb540",
              },
              nativeOn: {
                click: function ($event) {
                  return _vm.reCheck()
                },
              },
            }),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "base" },
        [
          _c(
            "en-table",
            {
              attrs: {
                data: _vm.tycData.tycResult.items,
                "page-config": {
                  pageModel: _vm.pageModel,
                  changeMethod: _vm.handlePageChanged,
                },
              },
            },
            [
              _c("en-table-column", {
                attrs: { type: "index", width: "60px" },
              }),
              _c("en-table-column", {
                attrs: {
                  prop: "changeTime",
                  label: "变更时间",
                  width: "100px",
                },
              }),
              _c("en-table-column", {
                attrs: {
                  prop: "changeItem",
                  label: "变更项目",
                  width: "200px",
                },
              }),
              _c("en-table-column", {
                attrs: { prop: "contentBefore", label: "变更前" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c("span", {
                          domProps: { innerHTML: _vm._s(row.contentBefore) },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _c("en-table-column", {
                attrs: { prop: "contentAfter", label: "变更后" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c("span", {
                          domProps: { innerHTML: _vm._s(row.contentAfter) },
                        }),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }