<template>
  <div class="main">
    <!--tab-->
    <tab-nav></tab-nav>
    <!--法律诉讼-->
    <lawsuit></lawsuit>
    <!--法院公告-->
    <announcement></announcement>
    <!--失信人-->
    <letdownInfo></letdownInfo>
    <!--被执行人-->
    <execute></execute>
    <!--开庭公告-->
    <courtSession></courtSession>
  </div>
</template>

<script>
import TabNav from "@/views/corporateCredit/common/tab-nav";
import Lawsuit from "@/views/corporateCredit/law/lawsuit";
import Announcement from "@/views/corporateCredit/law/announcement";
import LetdownInfo from "@/views/corporateCredit/law/letdownInfo";
import CourtSession from "@/views/corporateCredit/law/courtSession";
import Execute from "@/views/corporateCredit/law/execute";

export default {
  name: "LAW",
  components: {
    TabNav,
    Lawsuit,
    Announcement,
    CourtSession,
    LetdownInfo,
    Execute
  },
  data() {
    return {};
  },
  computed: {},
  watch: {
    $route: { // 监听路由变化,当query的location发送变动时,在下一帧跳转到指定dom(该dom需要有id属性)
      immediate: true,
      deep: true,
      handler(val) {
        const { location } = val.query;
        if (location) {
          this.$nextTick(() => {
            window.location.hash = location;
          });
        }
      }
    }
  },
  methods: {}
};
</script>

<style lang="scss" scoped>
</style>
