<!--
 * @Author: zhulin
 * @Date: 2020-07-22 10:34:01
 * @LastEditors: zhulin
 * @LastEditTime: 2020-08-06 17:53:07
 * @Description: 企业业务379
 * @FilePath: \user\src\views\corporateCredit\development\service.vue
-->
<template>
  <div id="service" ref="service" class="service">
    <div class="tyc-settings">
      <div class="title">
        <div class="leftbar">
          <span>企业业务({{ tycData.tycResult.total }})</span>
        </div>
      </div>
      <div class="service-wrap">
        <dl class="clearfix" v-for="(item, index) in tycData.tycResult.items" @click="showInfo(item)" :key="index">
          <dt class="fl">
            <en-user-logo :user-name="item.companyName" :image-url="item.logo" size="40"></en-user-logo>
          </dt>
          <dd class="fl">
            <strong>{{ item.product }}</strong>
            <span>所属公司：{{ item.companyName }}</span>
          </dd>
          <dd class="fl info">
            <span>融资信息：{{ item.hangye }}</span>
            <span>应用日期：{{ item.setupDate }}</span>
            <span>所属地：{{ item.location }}</span>
          </dd>
          <dd class="fl">
            <span class="tags">{{ item.round }}</span>
          </dd>
        </dl>
      </div>
      <div class="page-bot">
        <span>共{{ tycData.tycResult.total }}条</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tycData: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      tableData: []
    };
  },
  methods: {
    /**
     * @description: 再次查验
     */
    reCheck() {
      this.$parent.blockEvent("379", 0, true);
    }
  }
};
</script>

<style lang="scss" scoped>
.service-wrap {
  padding: 0 20px 20px;
  dl {
    margin-top: 20px;
    border-bottom: 1px solid #e8ecf2;
  }
  dt {
    height: 100px;
  }
  dd {
    width: 94%;
    margin-left: 20px;
    line-height: 24px;
    &.info {
      span {
        margin-right: 60px;
      }
    }
    strong {
      margin-right: 12px;
    }
    span {
      font-size: 12px;
      color: #8a919a;
      &.tags {
        background: #f5f8fc;
        border-radius: 5px;
        border: 1px solid #e8ecf2;
        padding: 5px 8px;
        line-height: normal;
        display: inline-block;
        margin-right: 6px;
        margin-top: 5px;
      }
    }
  }
}
</style>
