<!--
 * @Author: pengyu
 * @Date: 2020-06-07 11:13:37
 * @LastEditors: pengyu
 * @LastEditTime: 2020-06-07 15:10:54
 * @Description: file content
-->
<template>
  <div class="main">
    <!--tab-->
    <tab-nav></tab-nav>
    <!--经营异常-->
    <exception></exception>
    <!--行政处罚-->
    <punish></punish>
    <!--信用中国-->
    <creditableChina></creditableChina>
    <!--严重违法-->
    <illegal></illegal>
    <!--股权出质-->
    <equity></equity>
    <!--动产抵押-->
    <pledge></pledge>
    <!--欠税公告-->
    <owingtax></owingtax>
    <!--司法拍卖-->
    <auction></auction>
  </div>
</template>

<script>
import TabNav from "@/views/corporateCredit/common/tab-nav";
import Exception from "@/views/corporateCredit/manageRisk/exception";
import Punish from "@/views/corporateCredit/manageRisk/punish";
import CreditableChina from "@/views/corporateCredit/manageRisk/creditableChina";
import Equity from "@/views/corporateCredit/manageRisk/equity";
import Pledge from "@/views/corporateCredit/manageRisk/pledge";
import Auction from "@/views/corporateCredit/manageRisk/auction";
import Illegal from "@/views/corporateCredit/manageRisk/illegal";
import Owingtax from "@/views/corporateCredit/manageRisk/owingtax";

export default {
  name: "ManageRisk",
  components: {
    TabNav,
    Exception,
    Punish,
    CreditableChina,
    Equity,
    Pledge,
    Auction,
    Illegal,
    Owingtax
  },
  data() {
    return {};
  },
  computed: {},
  watch: {
    $route: { // 监听路由变化,当query的location发送变动时,在下一帧跳转到指定dom(该dom需要有id属性)
      immediate: true,
      deep: true,
      handler(val) {
        const { location } = val.query;
        if (location) {
          this.$nextTick(() => {
            window.location.hash = location;
          });
        }
      }
    }
  },
  methods: {}
};
</script>

<style lang="scss" scoped>
</style>
