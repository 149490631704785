var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { ref: "punish", staticClass: "tyc-settings", attrs: { id: "punish" } },
    [
      _c("div", { staticClass: "title clearfix" }, [
        _c("div", { staticClass: "leftbar" }, [
          _c("span", [
            _vm._v("行政处罚(" + _vm._s(_vm.tycData.tycResult.total) + ")"),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "rightbar" },
          [
            _c("en-icon", {
              attrs: {
                name: "iconzaicichayan-qiyezhengxin",
                size: "22px",
                color: "#ffb540",
              },
              nativeOn: {
                click: function ($event) {
                  return _vm.reCheck()
                },
              },
            }),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "base" }, [
        _c(
          "div",
          { staticClass: "base-inner" },
          [
            _c(
              "en-table",
              { attrs: { data: _vm.tycData.tycResult.items } },
              [
                _c("en-table-column", {
                  attrs: { type: "index", width: "60px" },
                }),
                _c("en-table-column", {
                  attrs: { prop: "decisionDate", label: "决定日期" },
                }),
                _c("en-table-column", {
                  attrs: { prop: "punishNumber", label: "决定文书号" },
                }),
                _c("en-table-column", {
                  attrs: { prop: "type", label: "类型" },
                }),
                _c("en-table-column", {
                  attrs: { prop: "departmentName", label: "决定机关" },
                }),
              ],
              1
            ),
            _c("en-pagination", {
              attrs: { "page-model": _vm.pageModel },
              on: { change: _vm.handlePageChanged },
            }),
          ],
          1
        ),
      ]),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "行政处罚",
            visible: _vm.dialogVisible,
            width: "95%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("div", { staticClass: "punish-content" }, [
            _c("div", { staticClass: "punish-info" }, [
              _c("table", [
                _c("tr", [
                  _c("td", [_vm._v("决定书文号")]),
                  _c("td", [_vm._v("1")]),
                ]),
                _c("tr", [_c("td", [_vm._v("类型")]), _c("td", [_vm._v("1")])]),
                _c("tr", [
                  _c("td", [_vm._v("处罚内容")]),
                  _c("td", [_vm._v("1")]),
                ]),
                _c("tr", [
                  _c("td", [_vm._v("决定日期")]),
                  _c("td", [_vm._v("1")]),
                ]),
                _c("tr", [
                  _c("td", [_vm._v("决定机关")]),
                  _c("td", [_vm._v("1")]),
                ]),
                _c("tr", [_c("td", [_vm._v("法人")]), _c("td", [_vm._v("1")])]),
                _c("tr", [_c("td", [_vm._v("备注")]), _c("td", [_vm._v("1")])]),
              ]),
            ]),
          ]),
        ]
      ),
      _c("punish-alert", {
        ref: "punishAlert",
        attrs: { show: _vm.showAlert, "info-data": _vm.infoData },
        on: { toShow: _vm.toShow },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }