<template>
  <div class="main">
    <tab-nav />
    <raise />
    <team />
    <service />
    <invest />
    <similarity />
  </div>
</template>

<script>
import TabNav from "@/views/corporateCredit/common/tab-nav";
import Team from "@/views/corporateCredit/development/team";
import Service from "@/views/corporateCredit/development/service";
import Invest from "@/views/corporateCredit/development/invest";
import Raise from "@/views/corporateCredit/development/raise";
import Similarity from "@/views/corporateCredit/development/similarity";

export default {
  name: "Development",
  components: {
    TabNav,
    Team,
    Service,
    Invest,
    Raise,
    Similarity
  },
  data() {
    return {};
  },
  computed: {},
  watch: {
    $route: { // 监听路由变化,当query的location发送变动时,在下一帧跳转到指定dom(该dom需要有id属性)
      immediate: true,
      deep: true,
      handler(val) {
        const { location } = val.query;
        if (location) {
          this.$nextTick(() => {
            window.location.hash = location;
          });
        }
      }
    }
  },
  methods: {}
};
</script>
