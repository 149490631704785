<!--
 * @Author: zhulin
 * @Date: 2020-07-22 10:34:01
 * @LastEditors: zhulin
 * @LastEditTime: 2020-08-06 10:12:00
 * @Description: 融资历史377
 * @FilePath: \user\src\views\corporateCredit\development\raise.vue
-->
<template>
  <div id="raise" ref="raise" class="invest">
    <div class="tyc-settings">
      <div class="title">
        <div class="leftbar">
          <span>融资历史({{ tycData.tycResult.total }})</span>
        </div>
        <div class="rightbar">
          <en-icon name="iconzaicichayan-qiyezhengxin" size="22px" color="#ffb540" @click.native="reCheck()"> </en-icon>
        </div>
      </div>
      <div class="invest-wrap">
        <en-table :data="tycData.tycResult.items">
          <en-table-column type="index" width="60px"></en-table-column>
          <en-table-column prop="date" label="时间" width="160"> </en-table-column>
          <en-table-column prop="round" label="轮次"> </en-table-column>
          <en-table-column prop="value" label="估值"> </en-table-column>
          <en-table-column prop="money" label="金额"> </en-table-column>
          <en-table-column prop="share" label="比例"> </en-table-column>
          <en-table-column prop="investorName" label="投资方"> </en-table-column>
          <en-table-column prop="newsTitle" label="新闻来源"> </en-table-column>
        </en-table>
        <div class="page-bot">
          <span>共{{ tycData.tycResult.total }}条</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tycData: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      tableData: []
    };
  },
  methods: {
    /**
     * @description: 再次查验
     */
    reCheck() {
      this.$parent.blockEvent("377", 0, true);
    }
  }
};
</script>

<style lang="scss" scoped>
.invest {
  margin-top: 10px;
}
.table-slot {
  display: flex;
  align-items: center;
  span {
    line-height: normal;
    display: block;
    padding: 10px 0;
    em {
      display: block;
      font-style: normal;
      line-height: 24px;
      font-size: 12px;
    }
  }
  span:last-child {
    color: #999;
    margin-left: 10px;
  }
}
</style>
