<!--
 * @Author: zhulin
 * @Date: 2020-07-22 10:34:01
 * @LastEditors: zhulin
 * @LastEditTime: 2020-08-06 15:11:30
 * @Description: 欠税公告
 * @FilePath: \user\src\views\corporateCredit\law\components\owingtaxAlert.vue
-->
<template>
  <en-dialog title="欠税公告" :visible.sync="show" width="850px" @close="closeDialog">
    <div class="patentInfo-content">
      <div class="patentInfo-info">
        <table>
          <tr>
            <td>纳税人类型</td>
            <td>{{ data.taxpayerType || "" }}</td>
            <td>纳税人识别号</td>
            <td>{{ data.taxIdNumber || "" }}</td>
          </tr>
          <tr>
            <td>负责人姓名</td>
            <td>{{ data.legalpersonName || "" }}</td>
            <td>证件号码</td>
            <td>{{ data.personIdNumber || "" }}</td>
          </tr>
          <tr>
            <td>经营地点</td>
            <td colspan="3">
              {{ data.location || "" }}
            </td>
          </tr>
          <tr>
            <td>欠税税种</td>
            <td>{{ data.taxCategory || "" }}</td>
            <td>欠税金额</td>
            <!-- <td>{{ data.ownTaxAmount?Number(data.ownTaxAmount).fmoney(2):"" || "" }}</td> -->
            <td>{{ data.ownTaxAmount || "" }}</td>
          </tr>
          <tr>
            <td>欠税余额</td>
            <td>{{ data.ownTaxBalance || "" }}</td>
            <td>当前发生的欠税额</td>
            <td>{{ data.newOwnTaxBalance || "" }}</td>
          </tr>
          <tr>
            <td>税务机关</td>
            <td>{{ data.department || "" }}</td>
            <td>发布日期</td>
            <td>{{ data.publishDate || "" }}</td>
          </tr>
        </table>
      </div>
    </div>
  </en-dialog>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false
    },
    infoData: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      data: {}
    };
  },
  watch: {
    /**
     * @description: 监听弹窗
     */
    show(val) {
      if (val) {
        this.data = this.infoData || {};
      }
    }
  },
  methods: {
    /**
     * @description: 关闭弹窗回调
     */
    closeDialog() {
      this.$emit("toShow", false);
    }
  }
};
</script>

<style lang="scss" scoped>
.patentInfo-content {
  .patentInfo-info {
    margin: 20px 0 50px 0;
    table {
      border-collapse: collapse;
      border-spacing: 0;
      width: 100%;
      tr {
        td {
          padding: 20px 18px;
          border: 1px solid #e8ecf2;
          font-size: 12px;
          text-align: left;
        }
        td:nth-child(1),
        td:nth-child(3) {
          color: #333;
          background-color: #f6fafd;
          width: 200px;
        }
        td:nth-child(2) {
          color: #636c78;
        }
      }
    }
  }
}
</style>
