<!--
 * @Author: Do not edit
 * @Date: 2020-07-22 10:34:01
 * @LastEditors: zhulin
 * @LastEditTime: 2020-08-07 16:30:00
 * @Description: 债券信息430
 * @FilePath: \user\src\views\corporateCredit\manageStatus\bond.vue
-->
<template>
  <div id="bond" ref="bond" class="tyc-settings">
    <div class="title">
      <div class="leftbar">
        <span>债券信息({{ tycData.tycResult.total }})</span>
      </div>
      <div class="rightbar">
        <en-icon name="iconzaicichayan-qiyezhengxin" size="22px" color="#ffb540" @click.native="reCheck()"> </en-icon>
      </div>
    </div>
    <!-- 企业基本信息 -->
    <div class="base">
      <div class="base-law">
        <en-table :data="tycData.tycResult.items">
          <en-table-column type="index" width="60px"></en-table-column>
          <en-table-column prop="publishTime" label="发行日期">
            <template v-slot="{ row }">
              <span>{{ dayjs(row.publishTime).format("YYYY-MM-DD") }}</span>
            </template>
          </en-table-column>
          <en-table-column prop="bondName" label="债券名称"> </en-table-column>
          <en-table-column prop="bondNum" label="债券代码"> </en-table-column>
          <en-table-column prop="bondType" label="债券类型"> </en-table-column>
          <en-table-column prop="debtRating" label="最新评级"> </en-table-column>
        </en-table>
        <en-pagination :page-model="pageModel" @change="handlePageChanged"></en-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";

export default {
  name: "Bond",
  props: {
    tycData: {
      type: Object,
      default() {}
    }
  },
  data() {
    return {
      tableData: [],
      dayjs,
      pageModel: {
        pageNo: this.tycData.pageNo || 1,
        pageSize: this.tycData.pageSize || 10,
        total: this.tycData.tycResult.total || "",
        totalPages: this.tycData.totalPages || Math.ceil(this.tycData.tycResult.total / (this.tycData.pageSize || 10))
      }
    };
  },
  computed: {},
  methods: {
    /**
     * @description: 页码
     */
    handlePageChanged({ pageSize, pageNo }) {
      this.$parent.blockEvent("430", 1, true, pageNo, pageSize);
    },
    /**
     * @description: 再次查验
     */
    reCheck() {
      this.$parent.blockEvent("430", 0, true);
    }
  }
};
</script>

<style lang="scss" scoped>
.tyc-settings {
  .title {
    i {
      background: #e5f2ff;
      padding: 3px;
      color: #5fb5ff;
      font-style: normal;
      margin-left: 10px;
      border-radius: 3px;
    }
  }
  .wrap {
    padding: 85px;
  }
}
.base {
  display: flex;
  flex-direction: row;
  margin-top: -1px;
  &-law {
    width: 100%;
  }
}
</style>
