var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "enterpriseInfo",
      staticClass: "tyc-settings",
      attrs: { id: "enterpriseInfo" },
    },
    [
      _vm._m(0),
      _c("div", { staticClass: "enterprise" }, [
        _c("table", { staticClass: "table" }, [
          _c("tbody", [
            _c("tr", [
              _c("th", { attrs: { rowspan: "2", width: "200" } }, [
                _vm._v(" 法定代表人信息 "),
              ]),
              _c("td", { attrs: { rowspan: "2" } }, [
                _c("div", { staticClass: "legalPerson" }, [
                  _c("img", {
                    attrs: { src: _vm.legalPersonNameMap.logo, alt: "" },
                  }),
                  _c("div", [
                    _c("strong", [
                      _vm._v(
                        _vm._s(
                          _vm.legalPersonNameMap.name ||
                            _vm.tycData.tycResult.legalPersonName ||
                            "-"
                        )
                      ),
                    ]),
                    _c("span", [_vm._v("职位:" + _vm._s(_vm.typeJoin))]),
                    _c("span", [
                      _vm._v(
                        "他有" +
                          _vm._s(_vm.legalPersonNameMap.toco || "-") +
                          "家公司"
                      ),
                    ]),
                  ]),
                ]),
              ]),
              _c("th", [_vm._v("注册资本")]),
              _c("td", [_vm._v(_vm._s(_vm.tycData.tycResult.regCapital))]),
            ]),
            _c("tr", [
              _c("th", [_vm._v("实缴资本")]),
              _c("td", [_vm._v(_vm._s(_vm.tycData.tycResult.actualCapital))]),
            ]),
            _c("tr", [
              _c("th", [_vm._v("成立日期")]),
              _c("td", [
                _vm._v(
                  _vm._s(
                    _vm
                      .dayjs(_vm.tycData.tycResult.fromTime)
                      .format("YYYY-MM-DD")
                  )
                ),
              ]),
              _c("th", [_vm._v("经营状态")]),
              _c("td", [
                _c("span", { staticClass: "tips" }, [
                  _vm._v(_vm._s(_vm.tycData.tycResult.regStatus)),
                ]),
              ]),
            ]),
            _c("tr", [
              _c("th", [_vm._v("统一社会信用代码")]),
              _c("td", [_vm._v(_vm._s(_vm.tycData.tycResult.creditCode))]),
              _c("th", [_vm._v("工商注册号")]),
              _c("td", [_vm._v(_vm._s(_vm.tycData.tycResult.regNumber))]),
            ]),
            _c("tr", [
              _c("th", [_vm._v("纳税人识别号")]),
              _c("td", [_vm._v(_vm._s(_vm.tycData.tycResult.taxNumber))]),
              _c("th", [_vm._v("组织机构代码")]),
              _c("td", [_vm._v(_vm._s(_vm.tycData.tycResult.orgNumber))]),
            ]),
            _c("tr", [
              _c("th", [_vm._v("公司类型")]),
              _c("td", [_vm._v(_vm._s(_vm.tycData.tycResult.companyOrgType))]),
              _c("th", [_vm._v("行业")]),
              _c("td", [_vm._v(_vm._s(_vm.tycData.tycResult.industry))]),
            ]),
            _c("tr", [
              _c("th", [_vm._v("核准日期")]),
              _c("td", [
                _vm._v(
                  _vm._s(
                    _vm
                      .dayjs(_vm.tycData.tycResult.approvedTime)
                      .format("YYYY-MM-DD")
                  )
                ),
              ]),
              _c("th", [_vm._v("登记机关")]),
              _c("td", [_vm._v(_vm._s(_vm.tycData.tycResult.regInstitute))]),
            ]),
            _c("tr", [
              _c("th", [_vm._v("营业期限")]),
              _c("td", [_vm._v(_vm._s(_vm.time365))]),
              _c("th", [_vm._v("纳税人资质")]),
              _c("td", [_vm._v(_vm._s(_vm.tycData.tycResult.industry))]),
            ]),
            _c("tr", [
              _c("th", [_vm._v("人员规模")]),
              _c("td", [
                _vm._v(_vm._s(_vm.tycData.tycResult.staffNumRange || "-")),
              ]),
              _c("th", [_vm._v("参保人数")]),
              _c("td", [
                _vm._v(_vm._s(_vm.tycData.tycResult.socialStaffNum || "-")),
              ]),
            ]),
            _c("tr", [
              _c("th", [_vm._v("曾用名")]),
              _c("td", [
                _vm._v(_vm._s(_vm.tycData.tycResult.historyNames || "-")),
              ]),
              _c("th", [_vm._v("英文名称")]),
              _c("td", [
                _vm._v(_vm._s(_vm.tycData.tycResult.property3 || "-")),
              ]),
            ]),
            _c("tr", [
              _c("th", [_vm._v("注册地址")]),
              _c("td", { attrs: { colspan: "3" } }, [
                _vm._v(" " + _vm._s(_vm.tycData.tycResult.regLocation) + " "),
              ]),
            ]),
            _c("tr", [
              _c("th", [_vm._v("经营范围")]),
              _c("td", { attrs: { colspan: "3" } }, [
                _vm._v(" " + _vm._s(_vm.tycData.tycResult.businessScope) + " "),
              ]),
            ]),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "title" }, [
      _c("span", [_vm._v("工商信息")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }