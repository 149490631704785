var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "main" },
    [
      _c("tab-nav"),
      _c("raise"),
      _c("team"),
      _c("service"),
      _c("invest"),
      _c("similarity"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }