var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { ref: "buyland", staticClass: "tyc-settings", attrs: { id: "buyland" } },
    [
      _c("div", { staticClass: "title" }, [
        _c("div", { staticClass: "leftbar" }, [
          _c("span", [
            _vm._v("购地信息(" + _vm._s(_vm.tycData.tycResult.total) + ")"),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "rightbar" },
          [
            _c("en-icon", {
              attrs: {
                name: "iconzaicichayan-qiyezhengxin",
                size: "22px",
                color: "#ffb540",
              },
              nativeOn: {
                click: function ($event) {
                  return _vm.reCheck()
                },
              },
            }),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "base" }, [
        _c(
          "div",
          { staticClass: "base-law" },
          [
            _c(
              "en-table",
              {
                attrs: { data: _vm.tycData.tycResult.items },
                on: { "row-click": _vm.showInfo },
              },
              [
                _c("en-table-column", {
                  attrs: { type: "index", width: "60px" },
                }),
                _c("en-table-column", {
                  attrs: { prop: "signedDate", label: "签订日期" },
                }),
                _c("en-table-column", {
                  attrs: { prop: "elecSupervisorNo", label: "电子监管号" },
                }),
                _c("en-table-column", {
                  attrs: { prop: "startTime", label: "约定动工日" },
                }),
                _c("en-table-column", {
                  attrs: { prop: "totalArea", label: "供地总面积（公顷）" },
                }),
                _c("en-table-column", {
                  attrs: { prop: "adminRegion", label: "行政区" },
                }),
                _c("en-table-column", {
                  attrs: { prop: "status", label: "状态" },
                }),
              ],
              1
            ),
            _c("en-pagination", {
              attrs: { "page-model": _vm.pageModel },
              on: { change: _vm.handlePageChanged },
            }),
          ],
          1
        ),
      ]),
      _c("buyland-alert", {
        ref: "buylandAlert",
        attrs: { show: _vm.showAlert, "info-data": _vm.infoData },
        on: { toShow: _vm.toShow },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }