<!--
 * @Author: zhulin
 * @Date: 2020-07-22 10:34:01
 * @LastEditors: zhulin
 * @LastEditTime: 2020-08-06 17:00:15
 * @Description: 年报详情
 * @FilePath: \user\src\views\corporateCredit\information\dialog\annual-report.vue
-->
<template>
  <en-dialog :title="titleAlert" :visible.sync="show" en-fullscreen en-body-scroll @close="closeDialog" class="report-alert">
    <div class="report-details">
      <h5>{{ title }}</h5>
      <div class="tyc-settings">
        <div class="title">
          <span class="title-text">企业基本信息</span>
        </div>
        <div class="enterprise">
          <table class="table">
            <tbody>
              <tr>
                <td class="hasbg">统一社会信用代码</td>
                <td>{{ data.baseInfo.creditCode }}</td>
                <td class="hasbg">企业名称</td>
                <td>{{ data.baseInfo.companyName }}</td>
              </tr>
              <tr>
                <td class="hasbg">企业联系电话</td>
                <td>{{ data.baseInfo.phoneNumber }}</td>
                <td class="hasbg">邮政编码</td>
                <td>{{ data.baseInfo.postcode }}</td>
              </tr>
              <tr>
                <td class="hasbg">企业经验状态</td>
                <td>{{ data.baseInfo.manageState }}</td>
                <td class="hasbg">从业人数</td>
                <td>{{ data.baseInfo.employeeNum }}</td>
              </tr>
              <tr>
                <td class="hasbg">电子邮箱</td>
                <td>{{ data.baseInfo.email }}</td>
                <td class="hasbg">是否有网站或网店</td>
                <td>{{ data.webInfoList && data.webInfoList.length ? "是" : "否" }}</td>
              </tr>
              <tr>
                <td class="hasbg">企业通信地址</td>
                <td>{{ data.baseInfo.postalAddress }}</td>
                <td class="hasbg">企业是否有投资信息或购买其他公司股权</td>
                <td>{{ data.outboundInvestmentList && data.outboundInvestmentList.length ? "是" : "否" }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="tyc-settings" v-if="data.webInfoList && data.webInfoList.length">
        <div class="title">
          <span class="title-text">网站或网店信息</span>
        </div>
        <div class="enterprise">
          <table class="table">
            <tbody>
              <tr>
                <th>类型</th>
                <th>名称</th>
                <th>网址</th>
              </tr>
              <tr v-for="(item, index) in data.webInfoList" :key="index">
                <td>{{ item.webType }}</td>
                <td>{{ item.name }}</td>
                <td>{{ item.website }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="tyc-settings" v-if="data.shareholderList && data.shareholderList.length">
        <div class="title">
          <span class="title-text">股东及出资信息</span>
        </div>
        <div class="enterprise">
          <table class="table">
            <tbody>
              <tr>
                <th>股东</th>
                <th>认缴出资额(万元)</th>
                <th>认缴出资时间</th>
                <th>认缴出资方式</th>
                <th>实缴出资额(万元)</th>
                <th>实缴出资时间</th>
                <th>实缴出资方式</th>
              </tr>
              <tr v-for="(item, index) in data.shareholderList" :key="index">
                <td>{{ item.investorName }}</td>
                <td>{{ item.subscribeAmount }}</td>
                <td>{{ item.subscribeTime }}</td>
                <td>{{ item.subscribeType }}</td>
                <td>{{ item.paidAmount }}</td>
                <td>{{ item.paidTime }}</td>
                <td>{{ item.paidType }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="tyc-settings">
        <div class="title">
          <span class="title-text">企业资产状况信息</span>
        </div>
        <div class="enterprise">
          <table class="table">
            <tbody>
              <tr>
                <td class="hasbg">资产总额</td>
                <td>{{ data.baseInfo.totalAssets }}</td>
                <td class="hasbg">所有者权益合计</td>
                <td>{{ data.baseInfo.totalEquity }}</td>
              </tr>
              <tr>
                <td class="hasbg">销售总额</td>
                <td>{{ data.baseInfo.totalSales }}</td>
                <td class="hasbg">利润总额</td>
                <td>{{ data.baseInfo.totalProfit }}</td>
              </tr>
              <tr>
                <td class="hasbg">营业总收入中主营业务收入</td>
                <td>{{ data.baseInfo.totalAssets }}</td>
                <td class="hasbg">净利润</td>
                <td>{{ data.baseInfo.totalAssets }}</td>
              </tr>
              <tr>
                <td class="hasbg">纳税总额</td>
                <td>{{ data.baseInfo.primeBusProfit }}</td>
                <td class="hasbg">负债总额</td>
                <td>{{ data.baseInfo.retainedProfit }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="tyc-settings" v-if="data.outboundInvestmentList && data.outboundInvestmentList.length">
        <div class="title">
          <span class="title-text">对外投资信息</span>
        </div>
        <div class="enterprise">
          <table class="table">
            <tbody>
              <tr>
                <th>注册号/统一社会信用代码</th>
                <th>对外投资企业名称</th>
              </tr>
              <tr v-for="(item, index) in data.outboundInvestmentList" :key="index">
                <td>{{ item.creditCode }}</td>
                <td>{{ item.outcompanyName }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </en-dialog>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false
    },
    infoData: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      data: {},
      title: "",
      titleAlert: "",
      tableData: [
        {
          id: "1",
          name: "长沙科比体育有限公司"
        },
        {
          id: "2",
          name: "长沙科比体育有限公司"
        },
        {
          id: "3",
          name: "长沙科比体育有限公司"
        },
        {
          id: "4",
          name: "长沙科比体育有限公司"
        },
        {
          id: "5",
          name: "长沙科比体育有限公司"
        }
      ]
    };
  },
  watch: {
    /**
     * @description: 监听弹窗
     */
    show(val) {
      if (val) {
        this.data = this.infoData || {};
        this.titleAlert = `${this.data.baseInfo.reportYear}年度报告`;
        this.title = `${this.data.baseInfo.reportYear}年${this.data.baseInfo.companyName}年度报告`;
      }
    }
  },
  methods: {
    /**
     * @description: 关闭弹窗回调
     */
    closeDialog() {
      this.$emit("toShow", false);
    }
  }
};
</script>

<style lang="scss" scoped>
.tyc-settings .title span.title-text {
  font-size: 12px;
  margin-left: 0;
}
.report-details {
  padding: 0 0 20px;
  h5 {
    display: block;
    text-align: center;
    color: #333;
    font-size: 14px;
    line-height: 42px;
    margin: 0;
    padding: 0;
    font-weight: normal;
  }
}
.enterprise {
  .table {
    border-collapse: collapse;
    border-spacing: 0;
    margin-top: -1px;
    width: 100%;
    border-right: 1px solid #e8ecf2;
    border-bottom: 1px solid #e8ecf2;
    th,
    td {
      padding: 15px 18px;
      border-top: 1px solid #e8ecf2;
      border-left: 1px solid #e8ecf2;
      font-size: 12px;
      text-align: left;
    }
    th {
      // width: 15%;
      background-color: #f6fafd;
      font-weight: normal;
      color: #333;
    }
    .hasbg {
      width: 15%;
      background-color: #f6fafd;
      font-weight: normal;
      color: #333;
    }
    td {
      // width: 35%;
      color: #8a919a;
      line-height: 16px;
      letter-spacing: 1px;
    }
  }
}
</style>
