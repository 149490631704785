var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "patentInfo",
      staticClass: "tyc-settings",
      attrs: { id: "patentInfo" },
    },
    [
      _c("div", { staticClass: "title clearfix" }, [
        _c("div", { staticClass: "leftbar" }, [
          _c("span", [
            _vm._v("专利信息(" + _vm._s(_vm.tycData.tycResult.total) + ")"),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "rightbar" },
          [
            _c("en-icon", {
              attrs: {
                name: "iconzaicichayan-qiyezhengxin",
                size: "22px",
                color: "#ffb540",
              },
              nativeOn: {
                click: function ($event) {
                  return _vm.reCheck()
                },
              },
            }),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "base" }, [
        _c(
          "div",
          { staticClass: "base-inner" },
          [
            _c(
              "en-table",
              {
                attrs: { data: _vm.tycData.tycResult.items },
                on: { "row-click": _vm.showInfo },
              },
              [
                _c("en-table-column", {
                  attrs: { type: "index", label: "序号", width: "60" },
                }),
                _c("en-table-column", {
                  attrs: {
                    prop: "applicationPublishTime",
                    label: "申请公布日",
                    width: "160",
                  },
                }),
                _c("en-table-column", {
                  attrs: { prop: "patentName", label: "专利名称" },
                }),
                _c("en-table-column", {
                  attrs: {
                    prop: "appnumber",
                    label: "申请公布号",
                    width: "235",
                  },
                }),
                _c("en-table-column", {
                  attrs: {
                    prop: "pubnumber",
                    label: "申请公示号",
                    width: "240",
                  },
                }),
                _c("en-table-column", {
                  attrs: {
                    prop: "patentType",
                    label: "专利类型",
                    width: "260",
                  },
                }),
              ],
              1
            ),
            _c("en-pagination", {
              attrs: { "page-model": _vm.pageModel },
              on: { change: _vm.handlePageChanged },
            }),
          ],
          1
        ),
      ]),
      _c("patentinfo-alert", {
        ref: "patentinfoAlert",
        attrs: { show: _vm.showAlert, "info-data": _vm.infoData },
        on: { toShow: _vm.toShow },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }