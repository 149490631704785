var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { ref: "team", staticClass: "team", attrs: { id: "team" } },
    [
      _c("div", { staticClass: "tyc-settings" }, [
        _vm._m(0),
        _c(
          "ul",
          { staticClass: "team-wrap" },
          _vm._l(_vm.tycData.tycResult.items, function (item, index) {
            return _c("li", { key: index }, [
              _c("dl", { staticClass: "clearfix" }, [
                _c(
                  "dt",
                  { staticClass: "fl" },
                  [
                    _c("en-user-logo", {
                      attrs: {
                        "user-name": item.name,
                        "image-url": item.icon,
                        size: "60",
                      },
                    }),
                  ],
                  1
                ),
                _c("dd", { staticClass: "fl name" }, [
                  _vm._v(" " + _vm._s(item.name) + " "),
                ]),
                _c("dd", { staticClass: "fl" }, [
                  _vm._v(" " + _vm._s(item.title) + " "),
                ]),
              ]),
              _c("p", [_vm._v(" " + _vm._s(item.desc) + " ")]),
            ])
          }),
          0
        ),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "title" }, [
      _c("div", { staticClass: "leftbar" }, [_c("span", [_vm._v("核心团队")])]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }