<!--
 * @Author: Fenngding
 * @Date: 2019-09-27 09:56:53
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2024-03-08 14:52:30
 * @Description:
 -->
<template>
  <div>
    <transition>
      <div id="home-router">
        <div class="left-area inline">
          <keep-alive>
            <router-view class="container" />
          </keep-alive>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
export default {
  name: "App",
  data() {
    return {};
  }
};
</script>
<style lang="scss">
@include cssReset;
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  height: 100%;
  overflow-y: hidden;
  font-size: 14px;
  .full-page {
    height: 100%;
    width: 100%;
  }
  #home-router {
    height: 100%;
    width: 100%;
    .inline {
      display: inline-block;
    }
    .left-area.inline {
      width: 100%;
      height: 100%;
      ::-webkit-scrollbar {
        width: 0 !important;
      }
      ::-webkit-scrollbar {
        width: 0 !important;
        height: 0;
      }
      //overflow-y: auto;
      .container {
        background-color: #1d2532;
        height: 100%;
        width: 100%;
      }
    }
  }
}
/* 清浮动 */
.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}
/* 浮动设置 */
.fl {
  float: left;
}
.fr {
  float: right;
}
.tyc-settings {
  border-radius: 5px;
  background-color: #fff;
  margin-bottom: 10px;
  overflow: hidden;
  .title {
    height: 45px;
    text-align: left;
    border-bottom: 1px solid #f3f3f3;
    zoom: 1;
    &::after {
      content: "";
      display: block;
      clear: both;
    }
    span {
      font-size: 14px;
      font-weight: bold;
      color: #333333;
      margin-left: 20px;
      line-height: 44px;
    }
    .leftbar {
      float: left;
    }
    .rightbar {
      float: right;
      padding: 8px 10px 0 0;
      .en-icon {
        cursor: pointer;
      }
    }
    .title-right-tips {
      display: flex;
      flex-direction: row;
      span {
        font-weight: normal;
        font-size: 12px;
        color: #999;
        display: inline-block;
        margin-right: 20px;
        margin-left: 0;
        &:last-child {
          padding-left: 20px;
          background: url("../../assets/icon-1.png") no-repeat 0 48%;
          cursor: pointer;
        }
      }
    }
  }
  .page-bot {
    font-size: 12px;
    padding: 20px;
    text-align: right;
  }
  .wrap {
    padding: 85px;
  }
}
.dialog-style {
  height: 80%;
  overflow-y: auto;
  @include hscrollbar;
}
</style>
