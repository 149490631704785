<!--
 * @Author: Do not edit
 * @Date: 2020-07-22 10:34:01
 * @LastEditors: zhulin
 * @LastEditTime: 2020-08-06 17:09:38
 * @Description: Do not edit
 * @FilePath: \user\src\views\corporateCredit\information\origin-info.vue
-->

<template>
  <div id="originInfo" ref="originInfo" class="origin">
    <div class="tyc-settings">
      <div class="title clearfix">
        <span>股权结构</span>
        <div class="title-right-tips fr">
          <span>下载</span>
          <span @click="fullScreen">全屏</span>
        </div>
      </div>
      <originTree />
    </div>
  </div>
</template>

<script>
import originTree from "@/views/corporateCredit/information/origin-tree";

export default {
  components: { originTree },
  data() {
    return {};
  },
  methods: {
    fullScreen() {
      this.$router.push({ path: "/fullScreen" });
    }
  }
};
</script>
<style lang="scss" scoped>
.title-right-tips {
  span {
    cursor: pointer;
  }
}
</style>
