<!--
 * @Author: zhulin
 * @Date: 2020-07-22 10:34:01
 * @LastEditors: zhulin
 * @LastEditTime: 2020-08-06 15:00:17
 * @Description: 动产抵押
 * @FilePath: \user\src\views\corporateCredit\manageRisk\components\pledgeAlert.vue
-->
<template>
  <en-dialog title="动产抵押" :visible.sync="show" width="850px" @close="closeDialog">
    <div class="pledgeInfo-content">
      <div class="pledgeInfo-content-title">
        动产抵押登记信息
      </div>
      <div class="mortgagor-info">
        <table>
          <tr>
            <td>登记编号</td>
            <td>{{ data.regNum || "" }}</td>
          </tr>
          <tr>
            <td>登记机关</td>
            <td>{{ data.regDepartment || "" }}</td>
          </tr>
          <tr>
            <td>登记日期</td>
            <td>{{ data.regDate || "" }}</td>
          </tr>
          <tr>
            <td>状态</td>
            <td>{{ data.status || "" }}</td>
          </tr>
        </table>
      </div>
      <div class="pledgeInfo-content-title">
        抵押人信息
      </div>
      <div class="mortgagor-info">
        <table>
          <tr>
            <td>抵押权人名称</td>
            <td>{{ data.peopleName || "" }}</td>
          </tr>
          <tr>
            <td>抵押权人证照 / 证件类型</td>
            <td>{{ data.liceseType || "" }}</td>
          </tr>
          <tr>
            <td>证件 / 证照号码</td>
            <td>{{ data.licenseNum || "" }}</td>
          </tr>
        </table>
      </div>
    </div>
  </en-dialog>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false
    },
    infoData: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      data: {}
    };
  },
  watch: {
    /**
     * @description: 监听弹窗
     */
    show(val) {
      if (val) {
        this.data = this.infoData || {};
      }
    }
  },
  methods: {
    /**
     * @description: 关闭弹窗回调
     */
    closeDialog() {
      this.$emit("toShow", false);
    }
  }
};
</script>

<style lang="scss" scoped>
.pledgeInfo-content {
  &-title {
    font-size: 12px;
    color: #303133;
    font-weight: bold;
    margin: 20px 0 15px 0;
  }
  .mortgagor-info {
    table {
      border-collapse: collapse;
      border-spacing: 0;
      width: 100%;
      tr {
        td {
          padding: 20px 18px;
          border: 1px solid #e8ecf2;
          font-size: 12px;
          text-align: left;
        }
        td:nth-child(1) {
          color: #333;
          background-color: #f6fafd;
          width: 200px;
        }
        td:nth-child(2) {
          color: #636c78;
        }
      }
    }
  }
  .mortgagor-info:last-child {
    margin-bottom: 30px;
  }
}
</style>
