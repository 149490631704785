var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "en-dialog",
    {
      attrs: { title: "进出口信用", visible: _vm.show, width: "850px" },
      on: {
        "update:visible": function ($event) {
          _vm.show = $event
        },
        close: _vm.closeDialog,
      },
    },
    [
      _c("div", { staticClass: "patentInfo-content" }, [
        _c("div", { staticClass: "patentInfo-content-title" }, [
          _vm._v(" 注册信息 "),
        ]),
        _c("div", { staticClass: "patentInfo-info" }, [
          _c("table", [
            _c("tr", [
              _c("td", [_vm._v("海关注册编码")]),
              _c("td", [_vm._v(_vm._s(_vm.data.recordDate || ""))]),
              _c("td", [_vm._v("纳税人识别号")]),
              _c("td", [_vm._v(_vm._s(_vm.data.crCode || ""))]),
            ]),
            _c("tr", [
              _c("td", [_vm._v("注册海关")]),
              _c("td", [
                _vm._v(_vm._s(_vm.data.customsRegisteredAddress || "")),
              ]),
              _c("td", [_vm._v("行政区划")]),
              _c("td", [_vm._v(_vm._s(_vm.data.administrativeDivision || ""))]),
            ]),
            _c("tr", [
              _c("td", [_vm._v("经济区划")]),
              _c("td", [_vm._v(_vm._s(_vm.data.economicDivision || ""))]),
              _c("td", [_vm._v("经营类别")]),
              _c("td", [_vm._v(_vm._s(_vm.data.managementCategory || ""))]),
            ]),
            _c("tr", [
              _c("td", [_vm._v("特殊贸易区域")]),
              _c("td", [_vm._v(_vm._s(_vm.data.specialTradeArea || ""))]),
              _c("td", [_vm._v("行业种类")]),
              _c("td", [_vm._v(_vm._s(_vm.data.industryCategory || ""))]),
            ]),
            _c("tr", [
              _c("td", [_vm._v("报关有效期")]),
              _c("td", [_vm._v(_vm._s(_vm.data.validityDate || ""))]),
              _c("td", [_vm._v("海关注销标识")]),
              _c("td", [_vm._v(_vm._s(_vm.data.status || ""))]),
            ]),
            _c("tr", [
              _c("td", [_vm._v("年报情况")]),
              _c("td", [_vm._v(_vm._s(_vm.data.validityDate || ""))]),
              _c("td", [_vm._v("跨境贸易电子商务类型")]),
              _c("td", [_vm._v(_vm._s(_vm.data.types || ""))]),
            ]),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }