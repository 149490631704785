import { render, staticRenderFns } from "./origin-info.vue?vue&type=template&id=6719dd97&scoped=true"
import script from "./origin-info.vue?vue&type=script&lang=js"
export * from "./origin-info.vue?vue&type=script&lang=js"
import style0 from "./origin-info.vue?vue&type=style&index=0&id=6719dd97&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6719dd97",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/lib/jenkins/workspace/dev_en-user/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6719dd97')) {
      api.createRecord('6719dd97', component.options)
    } else {
      api.reload('6719dd97', component.options)
    }
    module.hot.accept("./origin-info.vue?vue&type=template&id=6719dd97&scoped=true", function () {
      api.rerender('6719dd97', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/corporateCredit/information/origin-info.vue"
export default component.exports