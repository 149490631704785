<!--
 * @Author: Do not edit
 * @Date: 2020-07-22 10:34:01
 * @LastEditors: zhulin
 * @LastEditTime: 2020-08-07 17:08:11
 * @Description:变更记录370
 * @FilePath: \user\src\views\corporateCredit\information\log-change.vue
-->
<template>
  <div id="log" ref="log" class="tyc-settings change-log">
    <div class="title clearfix">
      <div class="leftbar">
        <span>变更记录({{ tycData.tycResult.total }})</span>
      </div>
      <div class="rightbar">
        <en-icon name="iconzaicichayan-qiyezhengxin" size="22px" color="#ffb540" @click.native="reCheck()"> </en-icon>
      </div>
    </div>
    <div class="base">
      <en-table :data="tycData.tycResult.items" :page-config="{ pageModel: pageModel, changeMethod: handlePageChanged }">
        <en-table-column type="index" width="60px"></en-table-column>
        <en-table-column prop="changeTime" label="变更时间" width="100px"> </en-table-column>
        <en-table-column prop="changeItem" label="变更项目" width="200px"></en-table-column>
        <en-table-column prop="contentBefore" label="变更前">
          <template v-slot="{ row }">
            <span v-html="row.contentBefore"></span>
          </template>
        </en-table-column>
        <en-table-column prop="contentAfter" label="变更后">
          <template v-slot="{ row }">
            <span v-html="row.contentAfter"></span>
          </template>
        </en-table-column>
      </en-table>
    </div>
    <!-- <en-pagination :page-model="pageModel"></en-pagination> -->
  </div>
</template>

<script>
export default {
  name: "TrademarkInfo",
  props: {
    tycData: {
      type: Object,
      default() {}
    }
  },
  data() {
    return {
      tableData: [],
      pageModel: {
        pageNo: this.tycData.pageNo || 1,
        pageSize: this.tycData.pageSize || 10,
        total: this.tycData.tycResult.total || "",
        totalPages: this.tycData.totalPages || Math.ceil(this.tycData.tycResult.total / (this.tycData.pageSize || 10))
      }
    };
  },
  computed: {},
  methods: {
    /**
     * @description: 页码
     */
    handlePageChanged({ pageSize, pageNo }) {
      this.$parent.blockEvent("439", 1, true, pageNo, pageSize);
    },
    /**
     * @description: 再次查验
     */
    reCheck() {
      this.$parent.blockEvent("370", 0, true);
    }
  }
};
</script>

<style lang="scss" scoped>
.change-log {
  margin-top: 10px;
  .base {
    margin-top: -1px;
  }
}
</style>
