var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "tyc-settings fixedTop" }, [
    _c("div", { staticClass: "title clearfix" }, [
      _c(
        "span",
        {
          staticClass: "enterprise-title",
          on: {
            click: function ($event) {
              return _vm.backClick()
            },
          },
        },
        [
          _c("en-icon", {
            attrs: { name: "iconfanhuishangcengji", size: "small" },
          }),
          _c("span", { staticClass: "txts" }, [_vm._v("企业信息")]),
        ],
        1
      ),
      _c("div", { staticClass: "title-right-tips fr" }, [
        _c("span", [_vm._v("上次查阅：" + _vm._s(_vm.tycData.enCreateTime))]),
        _c("span", { staticClass: "linlk-txt", on: { click: _vm.reCheck } }, [
          _vm._v("再次查验"),
        ]),
      ]),
    ]),
    _c("div", { staticClass: "base" }, [
      _c("div", { staticClass: "base-avatar" }, [
        _c("img", {
          staticStyle: { width: "100px" },
          attrs: { src: _vm.tycData.tycResult.logo, alt: "" },
        }),
      ]),
      _c("div", { staticClass: "base-wrap" }, [
        _c("ul", { staticClass: "base-wrap-list" }, [
          _c("li", [
            _c("div", { staticClass: "base-wrap-title" }, [
              _c("h4", [_vm._v(_vm._s(_vm.tycData.tycResult.name))]),
              _c("span", [_vm._v(_vm._s(_vm.tycData.tycResult.regStatus))]),
            ]),
            _c("h5", [_vm._v("基本信息")]),
            _c("div", [
              _vm._v("电话：" + _vm._s(_vm.tycData.tycResult.phoneNumber)),
            ]),
            _c("div", [_vm._v("邮箱：" + _vm._s(_vm.tycData.tycResult.email))]),
            _c("div", [
              _vm._v(" 网址："),
              _c("a", { attrs: { href: "#", target: "_blank" } }, [
                _vm._v(_vm._s(_vm.tycData.tycResult.websiteList)),
              ]),
            ]),
            _c("div", [
              _vm._v("地址：" + _vm._s(_vm.tycData.tycResult.regLocation)),
            ]),
          ]),
          _c("li", [
            _c("h5", [_vm._v("发票抬头")]),
            _c("div", [_vm._v("名称：" + _vm._s(_vm.tycData.tycResult.name))]),
            _c("div", [
              _vm._v("税号：" + _vm._s(_vm.tycData.tycResult.taxNumber)),
            ]),
            _c("div", [
              _vm._v("电话：" + _vm._s(_vm.tycData.tycResult.phoneNumber)),
            ]),
            _c("div", [
              _vm._v("地址：" + _vm._s(_vm.tycData.tycResult.regLocation)),
            ]),
          ]),
          _c("li", [
            _c("h5", [_vm._v("行业分数")]),
            _c(
              "div",
              { staticClass: "cricle-wrap" },
              [
                _c(
                  "radial-progress-bar",
                  {
                    attrs: {
                      "total-steps": _vm.totalSteps,
                      "stroke-width": _vm.strokeWidth,
                      "start-color": _vm.startColor,
                      "stop-color": _vm.stopColor,
                      diameter: 122,
                      "completed-steps": Math.floor(
                        _vm.tycData.tycResult.percentileScore / 100
                      ),
                      "inner-stroke-color": _vm.innerStrokeColor,
                    },
                  },
                  [
                    _c("div", { staticClass: "score" }, [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            Math.floor(
                              _vm.tycData.tycResult.percentileScore / 100
                            )
                          )
                        ),
                      ]),
                      _vm._v(" 分 "),
                    ]),
                  ]
                ),
              ],
              1
            ),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }