<!--
 * @Author: Do not edit
 * @Date: 2020-07-22 10:34:01
 * @LastEditors: zhulin
 * @LastEditTime: 2020-08-07 17:10:32
 * @Description: 分支机构372
 * @FilePath: \user\src\views\corporateCredit\information\branch.vue
-->
<template>
  <div id="branch" ref="branch" class="tyc-settings change-log">
    <div class="title clearfix">
      <div class="leftbar">
        <span>分支机构({{ tycData.tycResult.total }})</span>
      </div>
      <div class="rightbar">
        <en-icon name="iconzaicichayan-qiyezhengxin" size="22px" color="#ffb540" @click.native="reCheck()"> </en-icon>
      </div>
    </div>
    <div class="base">
      <el-table :data="tycData.tycResult.items" stripe border style="width: 100%">
        <el-table-column prop="id" label="序号" width="60"> </el-table-column>
        <el-table-column label="企业名称">
          <template slot-scope="scope">
            <div class="table-slot">
              <span>
                <en-user-logo :user-name="sub_str(scope.row.enterpriseName)" size="30"></en-user-logo>
                {{ scope.row.enterpriseName }}
              </span>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="legalPersonName" label="负责人" width="200"> </el-table-column>
        <el-table-column prop="regTime" label="注册时间" width="200"> </el-table-column>
        <el-table-column prop="status" label="状态" width="200">
          <template slot-scope="scope">
            <span class="tips">{{ scope.row.status }}</span>
          </template>
        </el-table-column>
      </el-table>
      <en-pagination :page-model="pageModel" @change="handlePageChanged"></en-pagination>
    </div>
  </div>
</template>

<script>
export default {
  name: "Branch",
  props: {
    tycData: {
      type: Object,
      default() {}
    }
  },
  data() {
    return {
      tableData: [],
      pageModel: {
        pageNo: this.tycData.pageNo || 1,
        pageSize: this.tycData.pageSize || 10,
        total: this.tycData.tycResult.total || "",
        totalPages: this.tycData.totalPages || Math.ceil(this.tycData.tycResult.total / (this.tycData.pageSize || 10))
      }
    };
  },
  computed: {},
  methods: {
    /**
     * @description: 页码
     */
    handlePageChanged({ pageSize, pageNo }) {
      this.$parent.blockEvent("372", 1, true, pageNo, pageSize);
    },
    /**
     * @description: 再次查验
     */
    reCheck() {
      this.$parent.blockEvent("372", 0, true);
    }
  }
};
</script>

<style lang="scss" scoped>
.change-log {
  margin-top: 10px;
}
.base {
  margin-top: -1px;
}
.tips {
  display: inline-block;
  background: #e9f9f4;
  color: #26c393;
  padding: 0 20px;
  line-height: 26px;
  border-radius: 5px;
  &.tips-error {
    color: #f76b6b;
    background: #f7ebeb;
  }
}
.table-slot {
  display: flex;
  justify-content: space-between;
  span:last-child {
    color: #999;
  }
}
</style>
