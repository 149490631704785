<!--
 * @Author: zhulin
 * @Date: 2020-07-22 10:34:01
 * @LastEditors: zhulin
 * @LastEditTime: 2020-08-06 15:00:17
 * @Description: 土地购地信息状况
 * @FilePath: \user\src\views\corporateCredit\manageRisk\components\pledgeAlert.vue
-->
<template>
  <en-dialog title="土地购地信息状况" :visible.sync="show" width="850px" @close="closeDialog">
    <div class="patentInfo-content">
      <div class="patentInfo-info">
        <table>
          <tr>
            <td>行政区</td>
            <td>{{ data.adminRegion || "" }}</td>
            <td>电子监管号</td>
            <td>{{ data.elecSupervisorNo || "" }}</td>
          </tr>
          <tr>
            <td>签订日期</td>
            <td>{{ data.signedDate || "" }}</td>
            <td>供地总面积（公顷）</td>
            <td>{{ data.totalArea || "" }}</td>
          </tr>
          <tr>
            <td>宗地位置</td>
            <td colspan="3">
              {{ data.location || "" }}
            </td>
          </tr>
          <tr>
            <td>受让人</td>
            <td>{{ data.assignee || "" }}</td>
            <td>上级公司</td>
            <td>{{ data.parentCompany || "" }}</td>
          </tr>
          <tr>
            <td>土地用户</td>
            <td>{{ data.purpose || "" }}</td>
            <td>供应方式</td>
            <td>{{ data.supplyWay || "" }}</td>
          </tr>
          <tr>
            <td>最小容积率</td>
            <td>{{ data.minVolume || "" }}</td>
            <td>最大容积率</td>
            <td>{{ data.maxVolume || "" }}</td>
          </tr>
          <tr>
            <td>成交价款（万元）</td>
            <td colspan="3">
              {{ data.dealPrice || "" }}
            </td>
          </tr>
          <tr>
            <td>约定动工时间</td>
            <td>{{ data.startTime || "" }}</td>
            <td>约定竣工时间</td>
            <td>{{ data.endTime || "" }}</td>
          </tr>
        </table>
      </div>
    </div>
  </en-dialog>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false
    },
    infoData: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      data: {}
    };
  },
  watch: {
    /**
     * @description: 监听弹窗
     */
    show(val) {
      if (val) {
        this.data = this.infoData || {};
      }
    }
  },
  methods: {
    /**
     * @description: 关闭弹窗回调
     */
    closeDialog() {
      this.$emit("toShow", false);
    }
  }
};
</script>

<style lang="scss" scoped>
.patentInfo-content {
  .patentInfo-info {
    margin: 20px 0 50px 0;
    table {
      border-collapse: collapse;
      border-spacing: 0;
      width: 100%;
      tr {
        td {
          padding: 20px 18px;
          border: 1px solid #e8ecf2;
          font-size: 12px;
          text-align: left;
        }
        td:nth-child(1),
        td:nth-child(3) {
          color: #333;
          background-color: #f6fafd;
          width: 200px;
        }
        td:nth-child(2) {
          color: #636c78;
        }
      }
    }
  }
}
</style>
