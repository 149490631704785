var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "en-dialog",
    {
      attrs: { title: "产品信息", visible: _vm.show, width: "850px" },
      on: {
        "update:visible": function ($event) {
          _vm.show = $event
        },
        close: _vm.closeDialog,
      },
    },
    [
      _c("div", { staticClass: "punish-content" }, [
        _c("div", { staticClass: "punish-info" }, [
          _c("table", [
            _c("tr", [
              _c("td", [_vm._v("产品图标")]),
              _c("td", [
                _c("img", {
                  attrs: { src: _vm.data.icon, alt: "data.filterName" },
                }),
              ]),
            ]),
            _c("tr", [
              _c("td", [_vm._v("产品名称")]),
              _c("td", [_vm._v(_vm._s(_vm.data.name || ""))]),
            ]),
            _c("tr", [
              _c("td", [_vm._v("产品简称")]),
              _c("td", [_vm._v(_vm._s(_vm.data.filterName || ""))]),
            ]),
            _c("tr", [
              _c("td", [_vm._v("产品分类")]),
              _c("td", [_vm._v(_vm._s(_vm.data.type || ""))]),
            ]),
            _c("tr", [
              _c("td", [_vm._v("领域")]),
              _c("td", [_vm._v(_vm._s(_vm.data.classes || ""))]),
            ]),
            _c("tr", [
              _c("td", [_vm._v("描述")]),
              _c("td", [_vm._v(_vm._s(_vm.data.brief || ""))]),
            ]),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }