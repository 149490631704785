var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { ref: "lawsuit", staticClass: "tyc-settings", attrs: { id: "lawsuit" } },
    [
      _c("div", { staticClass: "title" }, [
        _c("div", { staticClass: "leftbar" }, [
          _c("span", [
            _vm._v("法律诉讼(" + _vm._s(_vm.tycData.tycResult.total) + ")"),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "rightbar" },
          [
            _c("en-icon", {
              attrs: {
                name: "iconzaicichayan-qiyezhengxin",
                size: "22px",
                color: "#ffb540",
              },
              nativeOn: {
                click: function ($event) {
                  return _vm.reCheck()
                },
              },
            }),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "base" }, [
        _c(
          "div",
          { staticClass: "base-law" },
          [
            _c(
              "en-table",
              {
                attrs: {
                  data: _vm.tycData.tycResult.items,
                  "row-key": "id",
                  border: "",
                  "default-expand-all": "",
                },
              },
              [
                _c("en-table-column", {
                  attrs: { type: "index", width: "60px" },
                }),
                _c("en-table-column", {
                  attrs: { prop: "submittime", label: "日期" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function ({ row }) {
                        return [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.dayjs(row.submittime).format("YYYY-MM-DD")
                              )
                            ),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
                _c("en-table-column", {
                  attrs: { prop: "title", label: "判断文书" },
                }),
                _c("en-table-column", {
                  attrs: { prop: "casereason", label: "案由", width: "210" },
                }),
                _c("en-table-column", {
                  attrs: { prop: "plaintiffs", label: "原告" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function ({ row }) {
                        return [
                          _c("span", {
                            domProps: { innerHTML: _vm._s(row.plaintiffs) },
                          }),
                        ]
                      },
                    },
                  ]),
                }),
                _c("en-table-column", {
                  attrs: { prop: "defendants", label: "被告" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function ({ row }) {
                        return [
                          _c("span", {
                            domProps: { innerHTML: _vm._s(row.defendants) },
                          }),
                        ]
                      },
                    },
                  ]),
                }),
                _c("en-table-column", {
                  attrs: { prop: "caseno", label: "案号" },
                }),
              ],
              1
            ),
            _c("en-pagination", { attrs: { "page-model": _vm.pageModel } }),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }