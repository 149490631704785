var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "en-dialog",
    {
      attrs: { title: "著作人详情信息", visible: _vm.show, width: "1000px" },
      on: {
        "update:visible": function ($event) {
          _vm.show = $event
        },
        close: _vm.closeDialog,
      },
    },
    [
      _c("div", { staticClass: "patentInfo-content" }, [
        _c("div", { staticClass: "patentInfo-info" }, [
          _c("table", [
            _c("tr", [
              _c("td", [_vm._v("软件全称")]),
              _c("td", { attrs: { colspan: "3" } }, [
                _vm._v(_vm._s(_vm.data.fullname || "")),
              ]),
            ]),
            _c("tr", [
              _c("td", [_vm._v("软件简称")]),
              _c("td", [_vm._v(_vm._s(_vm.data.simplename || ""))]),
              _c("td", [_vm._v("登记号")]),
              _c("td", [_vm._v(_vm._s(_vm.data.regnum || ""))]),
            ]),
            _c("tr", [
              _c("td", [_vm._v("分类号")]),
              _c("td", [_vm._v(_vm._s(_vm.data.catnum || ""))]),
              _c("td", [_vm._v("版本号")]),
              _c("td", [_vm._v(_vm._s(_vm.data.version || ""))]),
            ]),
            _c("tr", [
              _c("td", [_vm._v("著作人权（国籍）")]),
              _c("td", { attrs: { colspan: "3" } }, [
                _vm._v(_vm._s(_vm.data.authorNationality || "")),
              ]),
            ]),
            _c("tr", [
              _c("td", [_vm._v("首次发表日期")]),
              _c("td", [_vm._v(_vm._s(_vm.data.publishtime || ""))]),
              _c("td", [_vm._v("登记日期")]),
              _c("td", [_vm._v(_vm._s(_vm.data.regtime || ""))]),
            ]),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }