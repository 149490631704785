var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "partnerInfo",
      staticClass: "tyc-settings partner",
      attrs: { id: "partnerInfo" },
    },
    [
      _c("div", { staticClass: "title" }, [
        _c("div", { staticClass: "leftbar" }, [
          _c("span", [
            _vm._v("股东信息(" + _vm._s(_vm.tycData.tycResult.total) + ")"),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "rightbar" },
          [
            _c("en-icon", {
              attrs: {
                name: "iconzaicichayan-qiyezhengxin",
                size: "22px",
                color: "#ffb540",
              },
              nativeOn: {
                click: function ($event) {
                  return _vm.reCheck()
                },
              },
            }),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "base" },
        [
          _c(
            "en-table",
            { attrs: { data: _vm.tycData.tycResult.items } },
            [
              _c("en-table-column", {
                attrs: { type: "index", width: "60px" },
              }),
              _c("en-table-column", {
                attrs: { prop: "name", label: "姓名" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", { staticClass: "table-slot" }, [
                          _c("span", [
                            _vm._v(" " + _vm._s(scope.row.name) + " "),
                          ]),
                          _c("span", [
                            _vm._v(
                              "他有" + _vm._s(scope.row.toco || "-") + "家公司"
                            ),
                          ]),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("en-table-column", {
                attrs: { prop: "percent", label: "出资比例" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(scope.row.capital[0].percent || "-") +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("en-table-column", {
                attrs: { prop: "amomon", label: "认缴出资" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " + _vm._s(scope.row.capital[0].amomon || "-") + " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("en-table-column", {
                attrs: { prop: "time", label: "出资时间" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " + _vm._s(scope.row.capital[0].time || "-") + " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c("div", { staticClass: "page-bot" }, [
            _c("span", [
              _vm._v("共" + _vm._s(_vm.tycData.tycResult.total) + "条"),
            ]),
          ]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }