var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "en-dialog",
    {
      staticClass: "report-alert",
      attrs: {
        title: _vm.titleAlert,
        visible: _vm.show,
        "en-fullscreen": "",
        "en-body-scroll": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.show = $event
        },
        close: _vm.closeDialog,
      },
    },
    [
      _c("div", { staticClass: "report-details" }, [
        _c("h5", [_vm._v(_vm._s(_vm.title))]),
        _c("div", { staticClass: "tyc-settings" }, [
          _c("div", { staticClass: "title" }, [
            _c("span", { staticClass: "title-text" }, [_vm._v("企业基本信息")]),
          ]),
          _c("div", { staticClass: "enterprise" }, [
            _c("table", { staticClass: "table" }, [
              _c("tbody", [
                _c("tr", [
                  _c("td", { staticClass: "hasbg" }, [
                    _vm._v("统一社会信用代码"),
                  ]),
                  _c("td", [_vm._v(_vm._s(_vm.data.baseInfo.creditCode))]),
                  _c("td", { staticClass: "hasbg" }, [_vm._v("企业名称")]),
                  _c("td", [_vm._v(_vm._s(_vm.data.baseInfo.companyName))]),
                ]),
                _c("tr", [
                  _c("td", { staticClass: "hasbg" }, [_vm._v("企业联系电话")]),
                  _c("td", [_vm._v(_vm._s(_vm.data.baseInfo.phoneNumber))]),
                  _c("td", { staticClass: "hasbg" }, [_vm._v("邮政编码")]),
                  _c("td", [_vm._v(_vm._s(_vm.data.baseInfo.postcode))]),
                ]),
                _c("tr", [
                  _c("td", { staticClass: "hasbg" }, [_vm._v("企业经验状态")]),
                  _c("td", [_vm._v(_vm._s(_vm.data.baseInfo.manageState))]),
                  _c("td", { staticClass: "hasbg" }, [_vm._v("从业人数")]),
                  _c("td", [_vm._v(_vm._s(_vm.data.baseInfo.employeeNum))]),
                ]),
                _c("tr", [
                  _c("td", { staticClass: "hasbg" }, [_vm._v("电子邮箱")]),
                  _c("td", [_vm._v(_vm._s(_vm.data.baseInfo.email))]),
                  _c("td", { staticClass: "hasbg" }, [
                    _vm._v("是否有网站或网店"),
                  ]),
                  _c("td", [
                    _vm._v(
                      _vm._s(
                        _vm.data.webInfoList && _vm.data.webInfoList.length
                          ? "是"
                          : "否"
                      )
                    ),
                  ]),
                ]),
                _c("tr", [
                  _c("td", { staticClass: "hasbg" }, [_vm._v("企业通信地址")]),
                  _c("td", [_vm._v(_vm._s(_vm.data.baseInfo.postalAddress))]),
                  _c("td", { staticClass: "hasbg" }, [
                    _vm._v("企业是否有投资信息或购买其他公司股权"),
                  ]),
                  _c("td", [
                    _vm._v(
                      _vm._s(
                        _vm.data.outboundInvestmentList &&
                          _vm.data.outboundInvestmentList.length
                          ? "是"
                          : "否"
                      )
                    ),
                  ]),
                ]),
              ]),
            ]),
          ]),
        ]),
        _vm.data.webInfoList && _vm.data.webInfoList.length
          ? _c("div", { staticClass: "tyc-settings" }, [
              _c("div", { staticClass: "title" }, [
                _c("span", { staticClass: "title-text" }, [
                  _vm._v("网站或网店信息"),
                ]),
              ]),
              _c("div", { staticClass: "enterprise" }, [
                _c("table", { staticClass: "table" }, [
                  _c(
                    "tbody",
                    [
                      _c("tr", [
                        _c("th", [_vm._v("类型")]),
                        _c("th", [_vm._v("名称")]),
                        _c("th", [_vm._v("网址")]),
                      ]),
                      _vm._l(_vm.data.webInfoList, function (item, index) {
                        return _c("tr", { key: index }, [
                          _c("td", [_vm._v(_vm._s(item.webType))]),
                          _c("td", [_vm._v(_vm._s(item.name))]),
                          _c("td", [_vm._v(_vm._s(item.website))]),
                        ])
                      }),
                    ],
                    2
                  ),
                ]),
              ]),
            ])
          : _vm._e(),
        _vm.data.shareholderList && _vm.data.shareholderList.length
          ? _c("div", { staticClass: "tyc-settings" }, [
              _c("div", { staticClass: "title" }, [
                _c("span", { staticClass: "title-text" }, [
                  _vm._v("股东及出资信息"),
                ]),
              ]),
              _c("div", { staticClass: "enterprise" }, [
                _c("table", { staticClass: "table" }, [
                  _c(
                    "tbody",
                    [
                      _c("tr", [
                        _c("th", [_vm._v("股东")]),
                        _c("th", [_vm._v("认缴出资额(万元)")]),
                        _c("th", [_vm._v("认缴出资时间")]),
                        _c("th", [_vm._v("认缴出资方式")]),
                        _c("th", [_vm._v("实缴出资额(万元)")]),
                        _c("th", [_vm._v("实缴出资时间")]),
                        _c("th", [_vm._v("实缴出资方式")]),
                      ]),
                      _vm._l(_vm.data.shareholderList, function (item, index) {
                        return _c("tr", { key: index }, [
                          _c("td", [_vm._v(_vm._s(item.investorName))]),
                          _c("td", [_vm._v(_vm._s(item.subscribeAmount))]),
                          _c("td", [_vm._v(_vm._s(item.subscribeTime))]),
                          _c("td", [_vm._v(_vm._s(item.subscribeType))]),
                          _c("td", [_vm._v(_vm._s(item.paidAmount))]),
                          _c("td", [_vm._v(_vm._s(item.paidTime))]),
                          _c("td", [_vm._v(_vm._s(item.paidType))]),
                        ])
                      }),
                    ],
                    2
                  ),
                ]),
              ]),
            ])
          : _vm._e(),
        _c("div", { staticClass: "tyc-settings" }, [
          _c("div", { staticClass: "title" }, [
            _c("span", { staticClass: "title-text" }, [
              _vm._v("企业资产状况信息"),
            ]),
          ]),
          _c("div", { staticClass: "enterprise" }, [
            _c("table", { staticClass: "table" }, [
              _c("tbody", [
                _c("tr", [
                  _c("td", { staticClass: "hasbg" }, [_vm._v("资产总额")]),
                  _c("td", [_vm._v(_vm._s(_vm.data.baseInfo.totalAssets))]),
                  _c("td", { staticClass: "hasbg" }, [
                    _vm._v("所有者权益合计"),
                  ]),
                  _c("td", [_vm._v(_vm._s(_vm.data.baseInfo.totalEquity))]),
                ]),
                _c("tr", [
                  _c("td", { staticClass: "hasbg" }, [_vm._v("销售总额")]),
                  _c("td", [_vm._v(_vm._s(_vm.data.baseInfo.totalSales))]),
                  _c("td", { staticClass: "hasbg" }, [_vm._v("利润总额")]),
                  _c("td", [_vm._v(_vm._s(_vm.data.baseInfo.totalProfit))]),
                ]),
                _c("tr", [
                  _c("td", { staticClass: "hasbg" }, [
                    _vm._v("营业总收入中主营业务收入"),
                  ]),
                  _c("td", [_vm._v(_vm._s(_vm.data.baseInfo.totalAssets))]),
                  _c("td", { staticClass: "hasbg" }, [_vm._v("净利润")]),
                  _c("td", [_vm._v(_vm._s(_vm.data.baseInfo.totalAssets))]),
                ]),
                _c("tr", [
                  _c("td", { staticClass: "hasbg" }, [_vm._v("纳税总额")]),
                  _c("td", [_vm._v(_vm._s(_vm.data.baseInfo.primeBusProfit))]),
                  _c("td", { staticClass: "hasbg" }, [_vm._v("负债总额")]),
                  _c("td", [_vm._v(_vm._s(_vm.data.baseInfo.retainedProfit))]),
                ]),
              ]),
            ]),
          ]),
        ]),
        _vm.data.outboundInvestmentList &&
        _vm.data.outboundInvestmentList.length
          ? _c("div", { staticClass: "tyc-settings" }, [
              _c("div", { staticClass: "title" }, [
                _c("span", { staticClass: "title-text" }, [
                  _vm._v("对外投资信息"),
                ]),
              ]),
              _c("div", { staticClass: "enterprise" }, [
                _c("table", { staticClass: "table" }, [
                  _c(
                    "tbody",
                    [
                      _c("tr", [
                        _c("th", [_vm._v("注册号/统一社会信用代码")]),
                        _c("th", [_vm._v("对外投资企业名称")]),
                      ]),
                      _vm._l(
                        _vm.data.outboundInvestmentList,
                        function (item, index) {
                          return _c("tr", { key: index }, [
                            _c("td", [_vm._v(_vm._s(item.creditCode))]),
                            _c("td", [_vm._v(_vm._s(item.outcompanyName))]),
                          ])
                        }
                      ),
                    ],
                    2
                  ),
                ]),
              ]),
            ])
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }