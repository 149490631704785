var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { ref: "filing", staticClass: "tyc-settings", attrs: { id: "filing" } },
    [
      _c("div", { staticClass: "title clearfix" }, [
        _c("div", { staticClass: "leftbar" }, [
          _c("span", [
            _vm._v("网站备案(" + _vm._s(_vm.tycData.tycResult.total) + ")"),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "rightbar" },
          [
            _c("en-icon", {
              attrs: {
                name: "iconzaicichayan-qiyezhengxin",
                size: "22px",
                color: "#ffb540",
              },
              nativeOn: {
                click: function ($event) {
                  return _vm.reCheck()
                },
              },
            }),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "base" }, [
        _c(
          "div",
          { staticClass: "base-law" },
          [
            _c(
              "en-table",
              { attrs: { data: _vm.tycData.tycResult.items } },
              [
                _c("en-table-column", {
                  attrs: { type: "index", width: "60px" },
                }),
                _c("en-table-column", {
                  attrs: { prop: "examineDate", label: "审核时间" },
                }),
                _c("en-table-column", {
                  attrs: { prop: "webName", label: "网站名称" },
                }),
                _c("en-table-column", {
                  attrs: { prop: "webSite", label: "网站首页" },
                }),
                _c("en-table-column", { attrs: { prop: "ym", label: "域名" } }),
                _c("en-table-column", {
                  attrs: { prop: "liscense", label: "备案号" },
                }),
                _c("en-table-column", {
                  attrs: { prop: "companyType", label: "单位性质" },
                }),
              ],
              1
            ),
            _c("en-pagination", {
              attrs: { "page-model": _vm.pageModel },
              on: { change: _vm.handlePageChanged },
            }),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }