var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "main" },
    [
      _c("tab-nav"),
      _c("exception"),
      _c("punish"),
      _c("creditableChina"),
      _c("illegal"),
      _c("equity"),
      _c("pledge"),
      _c("owingtax"),
      _c("auction"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }