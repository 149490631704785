var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "courtSession",
      staticClass: "tyc-settings",
      attrs: { id: "courtSession" },
    },
    [
      _c("div", { staticClass: "title clearfix" }, [
        _c("div", { staticClass: "leftbar" }, [
          _c("span", [
            _vm._v("开庭公告(" + _vm._s(_vm.tycData.tycResult.total) + ")"),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "rightbar" },
          [
            _c("en-icon", {
              attrs: {
                name: "iconzaicichayan-qiyezhengxin",
                size: "22px",
                color: "#ffb540",
              },
              nativeOn: {
                click: function ($event) {
                  return _vm.reCheck()
                },
              },
            }),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "base" }, [
        _c(
          "div",
          { staticClass: "base-law" },
          [
            _c(
              "en-table",
              { attrs: { data: _vm.tycData.tycResult.items } },
              [
                _c("en-table-column", {
                  attrs: { type: "index", width: "60px" },
                }),
                _c("en-table-column", {
                  attrs: { prop: "startDate", label: "开庭日期" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function ({ row }) {
                        return [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.dayjs(row.startDate).format("YYYY-MM-DD")
                              )
                            ),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
                _c("en-table-column", {
                  attrs: { prop: "caseReason", label: "案由" },
                }),
                _c("en-table-column", {
                  attrs: { prop: "plaintiff", label: "原告/上诉人" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function ({ row }) {
                        return [
                          _c("span", [_vm._v(_vm._s(row.plaintiff[0].name))]),
                        ]
                      },
                    },
                  ]),
                }),
                _c("en-table-column", {
                  attrs: { prop: "defendant", label: "被告/被上诉人" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function ({ row }) {
                        return [
                          _c("span", [_vm._v(_vm._s(row.defendant[0].name))]),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
            _c("en-pagination", {
              attrs: { "page-model": _vm.pageModel },
              on: { change: _vm.handlePageChanged },
            }),
          ],
          1
        ),
      ]),
      _c("court-alert", {
        ref: "courtAlert",
        attrs: { show: _vm.showAlert, "info-data": _vm.infoData },
        on: { toShow: _vm.toShow },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }