var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "staffInfo",
      staticClass: "staffInfo tyc-settings",
      attrs: { id: "staffInfo" },
    },
    [
      _c("div", { staticClass: "title" }, [
        _c("span", [_vm._v("主要人员信息(" + _vm._s(_vm.total) + ")")]),
      ]),
      _c(
        "div",
        { staticClass: "staff-list" },
        [
          _c(
            "en-table",
            { attrs: { data: _vm.staffList } },
            [
              _c("en-table-column", {
                attrs: { type: "index", width: "60px" },
              }),
              _c("en-table-column", {
                attrs: { prop: "name", label: "姓名" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", { staticClass: "table-slot" }, [
                          _c("span", [_vm._v(_vm._s(scope.row.name))]),
                          _c("span", [
                            _vm._v(
                              "他关联" +
                                _vm._s(scope.row.toco || "-") +
                                "家公司"
                            ),
                          ]),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("en-table-column", {
                attrs: { prop: "typeJoin", label: "职务" },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "page-bot" }, [
            _c("span", [_vm._v("共" + _vm._s(_vm.total) + "条")]),
          ]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }