var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "en-dialog",
    {
      attrs: { title: "行政处罚", visible: _vm.show, width: "850px" },
      on: {
        "update:visible": function ($event) {
          _vm.show = $event
        },
        close: _vm.closeDialog,
      },
    },
    [
      _c("div", { staticClass: "punish-content" }, [
        _c("div", { staticClass: "punish-info" }, [
          _c("table", [
            _c("tr", [
              _c("td", [_vm._v("决定书文号")]),
              _c("td", [_vm._v(_vm._s(_vm.data.punishNumber))]),
            ]),
            _c("tr", [
              _c("td", [_vm._v("类型")]),
              _c("td", [_vm._v(_vm._s(_vm.data.type))]),
            ]),
            _c("tr", [
              _c("td", [_vm._v("处罚内容")]),
              _c("td", [_vm._v(_vm._s(_vm.data.content))]),
            ]),
            _c("tr", [
              _c("td", [_vm._v("决定日期")]),
              _c("td", [_vm._v(_vm._s(_vm.data.decisionDate))]),
            ]),
            _c("tr", [
              _c("td", [_vm._v("决定机关")]),
              _c("td", [_vm._v(_vm._s(_vm.data.departmentName))]),
            ]),
            _c("tr", [
              _c("td", [_vm._v("法人")]),
              _c("td", [_vm._v(_vm._s(_vm.data.legalPersonName))]),
            ]),
            _c("tr", [
              _c("td", [_vm._v("备注")]),
              _c("td", [_vm._v(_vm._s(_vm.data.description || ""))]),
            ]),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }