<!--
 * @Author: zhulin
 * @Date: 2020-07-22 10:34:01
 * @LastEditors: zhulin
 * @LastEditTime: 2020-08-07 11:50:15
 * @Description: 法院公告
 * @FilePath: \user\src\views\corporateCredit\law\components\announceAlert.vue
-->
<template>
  <en-dialog title="法院公告" :visible.sync="show" width="1000px" @close="closeDialog" class="report-alert">
    <div class="announcementInfo-content">
      <div class="announcementInfo-content-title">
        法院公告
      </div>
      <div class="mortgagor-info">
        <table>
          <tr>
            <td>刊登日期</td>
            <td>{{ data.publishdate }}</td>
          </tr>
          <tr>
            <td>上诉方</td>
            <td>{{ data.party1 }}</td>
          </tr>
          <tr>
            <td>被诉方</td>
            <td>{{ data.party2 }}</td>
          </tr>
          <tr>
            <td>公告类型</td>
            <td>{{ data.bltntypename }}</td>
          </tr>
          <tr>
            <td>法院</td>
            <td>{{ data.courtcode }}</td>
          </tr>
        </table>
      </div>
      <div class="announcementInfo-content-title">
        抵押人信息
      </div>
      <div class="mortgagor-text">{{ data.content }}</div>
    </div>
  </en-dialog>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false
    },
    infoData: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      data: {}
    };
  },
  watch: {
    /**
     * @description: 监听弹窗
     */
    show(val) {
      if (val) {
        this.data = this.infoData || {};
      }
    }
  },
  methods: {
    /**
     * @description: 关闭弹窗回调
     */
    closeDialog() {
      this.$emit("toShow", false);
    }
  }
};
</script>

<style lang="scss" scoped>
.announcementInfo-content {
  padding-bottom: 20px;
  &-title {
    font-size: 12px;
    color: #303133;
    font-weight: bold;
    margin: 20px 0 15px 0;
  }
  .mortgagor-info {
    table {
      border-collapse: collapse;
      border-spacing: 0;
      width: 100%;
      tr {
        td {
          padding: 20px 18px;
          border: 1px solid #e8ecf2;
          font-size: 12px;
          text-align: left;
        }
        td:nth-child(1) {
          color: #333;
          background-color: #f6fafd;
          width: 200px;
        }
        td:nth-child(2) {
          color: #636c78;
        }
      }
    }
  }
  .mortgagor-text {
    background-color: #f5f7fa;
    color: #636c78;
    padding: 12px 13px 12px 13px;
    margin-bottom: 30px;
    border: 1px solid #e8ecf2;
    line-height: 20px;
    font-size: 14px;
  }
}
</style>
