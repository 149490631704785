<!--
 * @Author: Do not edit
 * @Date: 2020-07-22 10:34:01
 * @LastEditors: zhulin
 * @LastEditTime: 2020-08-07 09:47:02
 * @Description: 作品著作权733
 * @FilePath: \user\src\views\corporateCredit\property\copyright.vue
-->
<template>
  <div id="copyright" ref="copyright" class="tyc-settings">
    <div class="title clearfix">
      <div class="leftbar">
        <span>作品著作权({{ tycData.tycResult.total }})</span>
      </div>
      <div class="rightbar">
        <en-icon name="iconzaicichayan-qiyezhengxin" size="22px" color="#ffb540" @click.native="reCheck()"> </en-icon>
      </div>
    </div>
    <!-- 企业基本信息 -->
    <div class="base">
      <div class="base-inner">
        <en-table :data="tycData.tycResult.items" row-key="id" border default-expand-all>
          <en-table-column type="index" label="序号" width="60"> </en-table-column>
          <en-table-column prop="fullname" label="作品名称"> </en-table-column>
          <en-table-column prop="regnum" label="登记号"> </en-table-column>
          <en-table-column prop="type" label="类别"> </en-table-column>
          <en-table-column prop="finishTime" label="创作完成日期" width="155"> </en-table-column>
          <en-table-column prop="regtime" label="登记日期" width="155"> </en-table-column>
          <en-table-column prop="publishtime" label="首次发布日期" width="155"> </en-table-column>
        </en-table>
        <en-pagination :page-model="pageModel" @change="handlePageChanged"></en-pagination>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Copyright",
  props: {
    tycData: {
      type: Object,
      default() {}
    }
  },
  data() {
    return {
      tableData: [],
      pageModel: {
        pageNo: this.tycData.pageNo || 1,
        pageSize: this.tycData.pageSize || 10,
        total: this.tycData.tycResult.total || "",
        totalPages: this.tycData.totalPages || Math.ceil(this.tycData.tycResult.total / (this.tycData.pageSize || 10))
      }
    };
  },
  computed: {},
  methods: {
    /**
     * @description: 页码
     */
    handlePageChanged({ pageSize, pageNo }) {
      this.$parent.blockEvent("733", 1, true, pageNo, pageSize);
    },
    /**
     * @description: 再次查验
     */
    reCheck() {
      this.$parent.blockEvent("733", 0, true);
    }
  }
};
</script>

<style lang="scss" scoped>
.tyc-settings {
  margin-top: 10px;
  .wrap {
    padding: 85px;
  }
}
.base {
  display: flex;
  flex-direction: row;
  margin-top: -1px;
  &-inner {
    width: 100%;
  }
}
</style>
