<!--
 * @Author: zhulin
 * @Date: 2020-07-22 10:34:01
 * @LastEditors: zhulin
 * @LastEditTime: 2020-08-07 16:51:02
 * @Description: 开庭公告详情
 * @FilePath: \user\src\views\corporateCredit\law\components\courtAlert.vue
-->
<template>
  <en-dialog title="开庭公告详情" :visible.sync="show" width="850px" @close="closeDialog">
    <div class="courtSession-content">
      <div class="courtSession-content-title">
        开庭公告详情
      </div>
      <div class="mortgagor-info">
        <table>
          <tr>
            <td>案由</td>
            <td>{{ data.caseReason || "" }}}</td>
          </tr>
          <tr>
            <td>案号</td>
            <td>{{ data.caseNo || "" }}}</td>
          </tr>
          <tr>
            <td>原告/上诉人</td>
            <td>{{ data.plaintiff || "" }}}</td>
          </tr>
          <tr>
            <td>被告/被上诉人</td>
            <td>{{ data.defendant || "" }}}</td>
          </tr>
          <tr>
            <td>开庭日期</td>
            <td>{{ data.startDate || "" }}}</td>
          </tr>
          <tr>
            <td>当事人</td>
            <td>{{ data.litigant || "" }}}</td>
          </tr>
          <tr>
            <td>承办部门</td>
            <td>{{ data.contractors || "" }}}</td>
          </tr>
          <tr>
            <td>审判长/主审人</td>
            <td>{{ data.judge || "" }}}</td>
          </tr>
          <tr>
            <td>法院</td>
            <td>{{ data.court || "" }}}</td>
          </tr>
          <tr>
            <td>法庭</td>
            <td>{{ data.courtroom || "" }}}</td>
          </tr>
        </table>
      </div>
    </div>
  </en-dialog>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false
    },
    infoData: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      data: {}
    };
  },
  watch: {
    /**
     * @description: 监听弹窗
     */
    show(val) {
      if (val) {
        this.data = this.infoData || {};
      }
    }
  },
  methods: {
    /**
     * @description: 关闭弹窗回调
     */
    closeDialog() {
      this.$emit("toShow", false);
    }
  }
};
</script>

<style lang="scss" scoped>
.courtSession-content {
  &-title {
    font-size: 12px;
    color: #303133;
    font-weight: bold;
    margin: 20px 0 15px 0;
  }
  .mortgagor-info {
    table {
      border-collapse: collapse;
      border-spacing: 0;
      width: 100%;
      tr {
        td {
          padding: 20px 18px;
          border: 1px solid #e8ecf2;
          font-size: 12px;
          text-align: left;
        }
        td:nth-child(1) {
          color: #333;
          background-color: #f6fafd;
          width: 200px;
        }
        td:nth-child(2) {
          color: #636c78;
        }
      }
    }
  }
  .mortgagor-text {
    background-color: #f5f7fa;
    color: #636c78;
    padding: 12px 13px 12px 13px;
    margin-bottom: 30px;
    border: 1px solid #e8ecf2;
    line-height: 20px;
    font-size: 12px;
  }
}
</style>
