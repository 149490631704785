<!--
 * @Author: Do not edit
 * @Date: 2020-07-22 10:34:01
 * @LastEditors: zhulin
 * @LastEditTime: 2020-08-07 11:47:53
 * @Description: 购地信息431
 * @FilePath: \user\src\views\corporateCredit\manageStatus\buyland.vue
-->
<template>
  <div id="buyland" ref="buyland" class="tyc-settings">
    <div class="title">
      <div class="leftbar">
        <span>购地信息({{ tycData.tycResult.total }})</span>
      </div>
      <div class="rightbar">
        <en-icon name="iconzaicichayan-qiyezhengxin" size="22px" color="#ffb540" @click.native="reCheck()"> </en-icon>
      </div>
    </div>
    <!-- 企业基本信息 -->
    <div class="base">
      <div class="base-law">
        <en-table :data="tycData.tycResult.items" @row-click="showInfo">
          <en-table-column type="index" width="60px"></en-table-column>
          <en-table-column prop="signedDate" label="签订日期"> </en-table-column>
          <en-table-column prop="elecSupervisorNo" label="电子监管号"> </en-table-column>
          <en-table-column prop="startTime" label="约定动工日"> </en-table-column>
          <en-table-column prop="totalArea" label="供地总面积（公顷）"> </en-table-column>
          <en-table-column prop="adminRegion" label="行政区"> </en-table-column>
          <en-table-column prop="status" label="状态"> </en-table-column>
        </en-table>
        <en-pagination :page-model="pageModel" @change="handlePageChanged"></en-pagination>
      </div>
    </div>
    <!--购地信息弹窗-->
    <buyland-alert ref="buylandAlert" :show="showAlert" :info-data="infoData" @toShow="toShow"></buyland-alert>
  </div>
</template>

<script>
import buylandAlert from "./components/buylandAlert";

export default {
  name: "Buyland",
  components: { buylandAlert },
  props: {
    tycData: {
      type: Object,
      default() {}
    }
  },
  data() {
    return {
      tableData: [],
      showAlert: false,
      infoData: {},
      pageModel: {
        pageNo: this.tycData.pageNo || 1,
        pageSize: this.tycData.pageSize || 10,
        total: this.tycData.tycResult.total || "",
        totalPages: this.tycData.totalPages || Math.ceil(this.tycData.tycResult.total / (this.tycData.pageSize || 10))
      }
    };
  },
  computed: {},
  methods: {
    /**
     * @description: 页码
     */
    handlePageChanged({ pageSize, pageNo }) {
      this.$parent.blockEvent("431", 1, true, pageNo, pageSize);
    },
    /**
     * @description: 再次查验
     */
    reCheck() {
      this.$parent.blockEvent("431", 0, true);
    },
    /**
     * @description: 打开弹窗
     */
    showInfo(item) {
      this.showAlert = true;
      this.infoData = item.row;
    },
    /**
     * @description: 关闭弹窗
     */
    toShow() {
      this.showNotice = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.tyc-settings {
  .title {
    i {
      background: #e5f2ff;
      padding: 3px;
      color: #5fb5ff;
      font-style: normal;
      margin-left: 10px;
      border-radius: 3px;
    }
  }
  .wrap {
    padding: 85px;
  }
}
.base {
  display: flex;
  flex-direction: row;
  margin-top: -1px;
  &-law {
    width: 100%;
  }
}
.el-button--primary.en-btn-normal {
  background: #e5f2ff;
  color: #5fb5ff;
  border-color: #e5f2ff;
}
.el-button--danger.en-btn-normal {
  background: #f7ebeb;
  color: #f76b6b;
  border-color: #f7ebeb;
}
</style>
