var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { ref: "trading", staticClass: "tyc-settings", attrs: { id: "trading" } },
    [
      _c("div", { staticClass: "title" }, [
        _c("div", { staticClass: "leftbar" }, [
          _c("span", [
            _vm._v("进出口信用(" + _vm._s(_vm.tycData.tycResult.total) + ")"),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "rightbar" },
          [
            _c("en-icon", {
              attrs: {
                name: "iconzaicichayan-qiyezhengxin",
                size: "22px",
                color: "#ffb540",
              },
              nativeOn: {
                click: function ($event) {
                  return _vm.reCheck()
                },
              },
            }),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "base" }, [
        _c(
          "div",
          { staticClass: "base-law" },
          [
            _c(
              "en-table",
              {
                attrs: { data: _vm.tycData.tycResult.baseInfo },
                on: { "row-click": _vm.showInfo },
              },
              [
                _c("en-table-column", {
                  attrs: { type: "index", width: "60px" },
                }),
                _c("en-table-column", {
                  attrs: {
                    prop: "customsRegisteredAddress",
                    label: "注册海关",
                  },
                }),
                _c("en-table-column", {
                  attrs: { prop: "crCode", label: "海关编码" },
                }),
                _c("en-table-column", {
                  attrs: { prop: "managementCategory", label: "经营类别" },
                }),
              ],
              1
            ),
            _c("en-pagination", {
              attrs: { "page-model": _vm.pageModel },
              on: { change: _vm.handlePageChanged },
            }),
          ],
          1
        ),
      ]),
      _c("trading-alert", {
        ref: "tradingAlert",
        attrs: { show: _vm.showAlert, "info-data": _vm.infoData },
        on: { toShow: _vm.toShow },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }