var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { ref: "originInfo", staticClass: "origin", attrs: { id: "originInfo" } },
    [
      _c(
        "div",
        { staticClass: "tyc-settings" },
        [
          _c("div", { staticClass: "title clearfix" }, [
            _c("span", [_vm._v("股权结构")]),
            _c("div", { staticClass: "title-right-tips fr" }, [
              _c("span", [_vm._v("下载")]),
              _c("span", { on: { click: _vm.fullScreen } }, [_vm._v("全屏")]),
            ]),
          ]),
          _c("originTree"),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }