var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "branch",
      staticClass: "tyc-settings change-log",
      attrs: { id: "branch" },
    },
    [
      _c("div", { staticClass: "title clearfix" }, [
        _c("div", { staticClass: "leftbar" }, [
          _c("span", [
            _vm._v("分支机构(" + _vm._s(_vm.tycData.tycResult.total) + ")"),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "rightbar" },
          [
            _c("en-icon", {
              attrs: {
                name: "iconzaicichayan-qiyezhengxin",
                size: "22px",
                color: "#ffb540",
              },
              nativeOn: {
                click: function ($event) {
                  return _vm.reCheck()
                },
              },
            }),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "base" },
        [
          _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tycData.tycResult.items,
                stripe: "",
                border: "",
              },
            },
            [
              _c("el-table-column", {
                attrs: { prop: "id", label: "序号", width: "60" },
              }),
              _c("el-table-column", {
                attrs: { label: "企业名称" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", { staticClass: "table-slot" }, [
                          _c(
                            "span",
                            [
                              _c("en-user-logo", {
                                attrs: {
                                  "user-name": _vm.sub_str(
                                    scope.row.enterpriseName
                                  ),
                                  size: "30",
                                },
                              }),
                              _vm._v(
                                " " + _vm._s(scope.row.enterpriseName) + " "
                              ),
                            ],
                            1
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "legalPersonName",
                  label: "负责人",
                  width: "200",
                },
              }),
              _c("el-table-column", {
                attrs: { prop: "regTime", label: "注册时间", width: "200" },
              }),
              _c("el-table-column", {
                attrs: { prop: "status", label: "状态", width: "200" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", { staticClass: "tips" }, [
                          _vm._v(_vm._s(scope.row.status)),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c("en-pagination", {
            attrs: { "page-model": _vm.pageModel },
            on: { change: _vm.handlePageChanged },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }