var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "report",
      staticClass: "tyc-settings reportInfo",
      attrs: { id: "report" },
    },
    [
      _c("div", { staticClass: "title clearfix" }, [
        _c("div", { staticClass: "leftbar" }, [
          _c("span", [
            _vm._v("企业年报(" + _vm._s(_vm.tycData.tycResult.total) + ")"),
          ]),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "report-items" },
        _vm._l(_vm.tycData.tycResult.items, function (item, index) {
          return _c("en-card", {
            key: index,
            attrs: {
              "icon-name": "qiyenianbao-qiyezhengxin",
              title: _vm.getName(item),
              "icon-bg": "#3e90fe",
              "icon-color": "#fff",
              dataInfo: item,
            },
            nativeOn: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.showInfo(item)
              },
            },
          })
        }),
        1
      ),
      _c("annual-alert", {
        ref: "yearReport",
        attrs: { show: _vm.showAlert, "info-data": _vm.infoData },
        on: { toShow: _vm.toShow },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }