<!--
 * @Author: Do not edit
 * @Date: 2020-07-22 10:34:01
 * @LastEditors: zhulin
 * @LastEditTime: 2020-08-20 14:27:57
 * @Description:企业信息365
 * @FilePath: \user\src\views\corporateCredit\information\base-info.vue
-->
<template>
  <div class="tyc-settings fixedTop">
    <div class="title clearfix">
      <span class="enterprise-title" @click="backClick()">
        <en-icon name="iconfanhuishangcengji" size="small"> </en-icon>
        <span class="txts">企业信息</span>
      </span>
      <!-- <router-link to=""> </router-link> -->
      <div class="title-right-tips fr">
        <span>上次查阅：{{ tycData.enCreateTime }}</span>
        <span @click="reCheck" class="linlk-txt">再次查验</span>
      </div>
    </div>
    <!-- 企业基本信息 -->
    <div class="base">
      <div class="base-avatar">
        <img :src="tycData.tycResult.logo" alt="" style="width:100px" />
      </div>
      <div class="base-wrap">
        <ul class="base-wrap-list">
          <li>
            <div class="base-wrap-title">
              <h4>{{ tycData.tycResult.name }}</h4>
              <span>{{ tycData.tycResult.regStatus }}</span>
            </div>
            <h5>基本信息</h5>
            <div>电话：{{ tycData.tycResult.phoneNumber }}</div>
            <div>邮箱：{{ tycData.tycResult.email }}</div>
            <div>
              网址：<a href="#" target="_blank">{{ tycData.tycResult.websiteList }}</a>
            </div>
            <div>地址：{{ tycData.tycResult.regLocation }}</div>
          </li>
          <li>
            <h5>发票抬头</h5>
            <div>名称：{{ tycData.tycResult.name }}</div>
            <div>税号：{{ tycData.tycResult.taxNumber }}</div>
            <div>电话：{{ tycData.tycResult.phoneNumber }}</div>
            <div>地址：{{ tycData.tycResult.regLocation }}</div>
          </li>
          <li>
            <h5>行业分数</h5>
            <div class="cricle-wrap">
              <radial-progress-bar
                :total-steps="totalSteps"
                :stroke-width="strokeWidth"
                :start-color="startColor"
                :stop-color="stopColor"
                :diameter="122"
                :completed-steps="Math.floor(tycData.tycResult.percentileScore / 100)"
                :inner-stroke-color="innerStrokeColor"
              >
                <div class="score">
                  <span>{{ Math.floor(tycData.tycResult.percentileScore / 100) }}</span> 分
                </div>
              </radial-progress-bar>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import RadialProgressBar from "vue-radial-progress";

export default {
  name: "Home",
  components: { "radial-progress-bar": RadialProgressBar },
  props: {
    tycData: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      totalSteps: 100,
      strokeWidth: 12,
      startColor: "#3e90fe",
      stopColor: "#179eda",
      innerStrokeColor: "#e8ecf2",
      completedSteps: 75
    };
  },
  mounted() {},
  computed: {},
  methods: {
    /**
     * @description: 再次查验
     */
    reCheck() {
      this.$parent.queryTYC(365, { id: undefined }, 0, false);
    },
    /**
     * @description: 返回
     */
    backClick() {
      this.$parent.backBusiness();
    }
  }
};
</script>

<style lang="scss" scoped>
.enterprise-title{
  .en-icon{
    color:#aeaeae;
    display:inline-block;
    vertical-align:middle;
    }
  .txts{
    display:inline-block;
    vertical-align:middle;
    margin-left: 10px;
    }
  }
.base {
  padding: 20px 20px 40px;
  display: flex;
  flex-direction: row;
  .linlk-txt {
    cursor: pointer;
  }
  a {
    text-decoration: none;
  }
  &-avatar {
    width: 100px;
    height: 100px;
    img {
      min-width: 100%;
    }
  }
  &-wrap {
    padding-left: 15px;
    flex: 1;
    &-title {
      margin-bottom: 5px;
      text-align: left;
      h4 {
        font-size: 14px;
        color: #333;
        margin: 0;
        font-weight: normal;
      }
      span {
        display: inline-block;
        background: #e9f9f4;
        color: #26c393;
        font-size: 12px;
        height: 26px;
        line-height: 26px;
        border-radius: 4px;
        margin-left: 15px;
        padding: 0 15px;
        cursor: pointer;
      }
    }
    &-list {
      display: flex;
      flex-direction: row;
      text-align: left;
      padding-left: 0;
      margin: 0;
      li {
        //padding-right: 140px;
        color: #666;
        line-height: 22px;
        font-size: 12px;
        border-right: 1px solid #e8ecf2;
        margin-right: 15px;
        flex: 1;
        margin-top: 30px;
        &:first-child {
          margin-top: 0;
        }
        &:last-child {
          flex: none;
          width: 20%;
          padding-right: 0;
          border-right: none;
        }
        h5 {
          color: #333333;
          font-weight: bold;
          margin-bottom: 5px;
          margin-top: 10px;
        }
        .score {
          color: #3e90fe;
          font-size: 12px;
          span {
            font-weight: bold;
            font-size: 16px;
          }
        }
        .cricle-wrap {
          padding-left: 60px;
          margin-top: -35px;
        }
      }
    }
  }
}
</style>
