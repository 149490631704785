var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "en-dialog",
    {
      attrs: { title: "专利信息", visible: _vm.show, width: "1000px" },
      on: {
        "update:visible": function ($event) {
          _vm.show = $event
        },
        close: _vm.closeDialog,
      },
    },
    [
      _c("div", { staticClass: "patentInfo-content" }, [
        _c("div", { staticClass: "patentInfo-info" }, [
          _c("table", [
            _c("tr", [
              _c("td", [_vm._v("申请号")]),
              _c("td", [_vm._v(_vm._s(_vm.data.applicationPublishNum || ""))]),
              _c("td", [_vm._v("申请日")]),
              _c("td", [_vm._v(_vm._s(_vm.data.appnumber || ""))]),
            ]),
            _c("tr", [
              _c("td", [_vm._v("申请日期")]),
              _c("td", [_vm._v(_vm._s(_vm.data.applicationTime || ""))]),
              _c("td", [_vm._v("申请公布日期")]),
              _c("td", [_vm._v(_vm._s(_vm.data.applicationPublishTime || ""))]),
            ]),
            _c("tr", [
              _c("td", [_vm._v("分类号")]),
              _c("td", [_vm._v(_vm._s(_vm.data.inventor || ""))]),
              _c("td", [_vm._v("分类")]),
              _c("td", [_vm._v(_vm._s(_vm.data.name || ""))]),
            ]),
            _c("tr", [
              _c("td", [_vm._v("发明人")]),
              _c("td", [_vm._v(_vm._s(_vm.data.inventor || ""))]),
              _c("td", [_vm._v("申请（专利权）人")]),
              _c("td", [_vm._v(_vm._s(_vm.data.applicantName || ""))]),
            ]),
            _c("tr", [
              _c("td", [_vm._v("代理机构")]),
              _c("td", [_vm._v(_vm._s(_vm.data.agency || ""))]),
              _c("td", [_vm._v("代理人")]),
              _c("td", [_vm._v(_vm._s(_vm.data.agent || ""))]),
            ]),
            _c("tr", [
              _c("td", [_vm._v("地址")]),
              _c("td", { attrs: { colspan: "3" } }, [
                _vm._v(" " + _vm._s(_vm.data.address || "") + " "),
              ]),
            ]),
            _c("tr", [
              _c("td", [_vm._v("摘要")]),
              _c("td", { attrs: { colspan: "3" } }, [
                _vm._v(" " + _vm._s(_vm.data.abstracts || "") + " "),
              ]),
            ]),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }