var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "main" },
    [
      _c("tab-nav"),
      _c("tender"),
      _c("bond"),
      _c("buyland"),
      _c("recruitment"),
      _c("taxrate"),
      _c("spottest"),
      _c("infoList"),
      _c("credential"),
      _c("trading"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }