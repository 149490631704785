<!--
 * @Author: Do not edit
 * @Date: 2020-07-22 10:34:01
 * @LastEditors: zhulin
 * @LastEditTime: 2020-08-06 10:51:54
 * @Description: 商标信息439
 * @FilePath: \user\src\views\corporateCredit\property\trademarkInfo.vue
-->
<template>
  <div id="trademarkInfo" ref="trademarkInfo" class="tyc-settings partner">
    <div class="title clearfix">
      <div class="leftbar">
        <span>商标信息({{ tycData.tycResult.total }})</span>
      </div>
      <div class="rightbar">
        <en-icon name="iconzaicichayan-qiyezhengxin" size="22px" color="#ffb540" @click.native="reCheck()"> </en-icon>
      </div>
    </div>
    <en-table :data="tycData.tycResult.items">
      <en-table-column type="index" width="60px"></en-table-column>
      <en-table-column prop="appDate" label="申请日期" width="160"> </en-table-column>
      <en-table-column label="商标" width="200">
        <template slot-scope="scope">
          <div class="table-slot">
            <span>
              <en-user-logo :image-url="scope.row.tmPic" :user-name="scope.row.tmName" size="30"></en-user-logo>
            </span>
          </div>
        </template>
      </en-table-column>
      <en-table-column prop="tmName" label="商标名称"> </en-table-column>
      <en-table-column prop="regNo" label="注册号"> </en-table-column>
      <en-table-column prop="intCls" label="类别"> </en-table-column>
      <en-table-column prop="category" label="流程状态" width="250"> </en-table-column>
    </en-table>
    <en-pagination :page-model="pageModel" @change="handlePageChanged"></en-pagination>
  </div>
</template>

<script>
export default {
  name: "TrademarkInfo",
  props: {
    tycData: {
      type: Object,
      default() {}
    }
  },
  data() {
    return {
      tableData: [],
      pageModel: {
        pageNo: this.tycData.pageNo || 1,
        pageSize: this.tycData.pageSize || 10,
        total: this.tycData.tycResult.total || "",
        totalPages: this.tycData.totalPages || Math.ceil(this.tycData.tycResult.total / (this.tycData.pageSize || 10))
      }
    };
  },
  computed: {},
  methods: {
    /**
     * @description: 页码
     */
    handlePageChanged({ pageSize, pageNo }) {
      this.$parent.blockEvent("439", 1, true, pageNo, pageSize);
    },
    /**
     * @description: 再次查验
     */
    reCheck() {
      this.$parent.blockEvent("439", 0, true);
    }
  }
};
</script>

<style lang="scss" scoped>
.partner {
  margin-top: 10px;
}
.table-slot {
  display: flex;
  justify-content: space-between;
  span:last-child {
    color: #999;
  }
}
</style>
