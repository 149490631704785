var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "en-dialog",
    {
      staticClass: "report-alert",
      attrs: { title: "微信公众号", visible: _vm.show, width: "850px" },
      on: {
        "update:visible": function ($event) {
          _vm.show = $event
        },
        close: _vm.closeDialog,
      },
    },
    [
      _c("div", { staticClass: "wechat-content" }, [
        _c("div", { staticClass: "content-left" }, [
          _c("div", { staticClass: "content-left-item" }, [
            _c("img", {
              attrs: { src: _vm.data.titleImgURL, alt: _vm.data.title },
            }),
            _c("div", { staticClass: "text" }, [
              _c("b", [_vm._v(_vm._s(_vm.data.title))]),
              _c("br"),
              _c("span", [_vm._v("微信号：" + _vm._s(_vm.data.publicNum))]),
            ]),
          ]),
          _c("p", [_vm._v(" " + _vm._s(_vm.data.recommend) + " ")]),
        ]),
        _c("div", { staticClass: "content-right" }, [
          _c("img", { attrs: { src: _vm.data.codeImg, alt: _vm.data.title } }),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }