var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "en-dialog",
    {
      attrs: { title: "动产抵押", visible: _vm.show, width: "850px" },
      on: {
        "update:visible": function ($event) {
          _vm.show = $event
        },
        close: _vm.closeDialog,
      },
    },
    [
      _c("div", { staticClass: "pledgeInfo-content" }, [
        _c("div", { staticClass: "pledgeInfo-content-title" }, [
          _vm._v(" 动产抵押登记信息 "),
        ]),
        _c("div", { staticClass: "mortgagor-info" }, [
          _c("table", [
            _c("tr", [
              _c("td", [_vm._v("登记编号")]),
              _c("td", [_vm._v(_vm._s(_vm.data.regNum || ""))]),
            ]),
            _c("tr", [
              _c("td", [_vm._v("登记机关")]),
              _c("td", [_vm._v(_vm._s(_vm.data.regDepartment || ""))]),
            ]),
            _c("tr", [
              _c("td", [_vm._v("登记日期")]),
              _c("td", [_vm._v(_vm._s(_vm.data.regDate || ""))]),
            ]),
            _c("tr", [
              _c("td", [_vm._v("状态")]),
              _c("td", [_vm._v(_vm._s(_vm.data.status || ""))]),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "pledgeInfo-content-title" }, [
          _vm._v(" 抵押人信息 "),
        ]),
        _c("div", { staticClass: "mortgagor-info" }, [
          _c("table", [
            _c("tr", [
              _c("td", [_vm._v("抵押权人名称")]),
              _c("td", [_vm._v(_vm._s(_vm.data.peopleName || ""))]),
            ]),
            _c("tr", [
              _c("td", [_vm._v("抵押权人证照 / 证件类型")]),
              _c("td", [_vm._v(_vm._s(_vm.data.liceseType || ""))]),
            ]),
            _c("tr", [
              _c("td", [_vm._v("证件 / 证照号码")]),
              _c("td", [_vm._v(_vm._s(_vm.data.licenseNum || ""))]),
            ]),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }