<!--
 * @Author: zhulin
 * @Date: 2020-07-22 10:34:01
 * @LastEditors: zhulin
 * @LastEditTime: 2020-07-30 15:34:12
 * @Description: 微信公众号详情
 * @FilePath: \user\src\views\corporateCredit\property\components\weChatAlert.vue
-->
<template>
  <en-dialog title="微信公众号" :visible.sync="show" width="850px" @close="closeDialog" class="report-alert">
    <div class="wechat-content">
      <div class="content-left">
        <div class="content-left-item">
          <img :src="data.titleImgURL" :alt="data.title" />
          <div class="text">
            <b>{{ data.title }}</b
            ><br />
            <span>微信号：{{ data.publicNum }}</span>
          </div>
        </div>
        <p>
          {{ data.recommend }}
        </p>
      </div>
      <div class="content-right">
        <img :src="data.codeImg" :alt="data.title" />
      </div>
    </div>
    <!-- <span slot="footer" class="dialog-footer">
      <en-button type="primary" @click="closeDialog = false">确定</en-button>
    </span> -->
  </en-dialog>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false
    },
    weixinData: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      data: {}
    };
  },
  watch: {
    /**
     * @description: 监听弹窗
     */
    show(val) {
      if (val) {
        this.data = this.weixinData || {};
      }
    }
  },
  methods: {
    /**
     * @description: 关闭弹窗回调
     */
    closeDialog() {
      this.$emit("toShow", false);
    }
  }
};
</script>

<style lang="scss" scoped>
.wechat-content {
  overflow: hidden;
  margin-bottom: 120px;
  padding-bottom: 80px;
  .content-left {
    margin-top: 20px;
    float: left;
    &-item {
      line-height: 40px;
      img {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        display: inline-block;
        margin-right: 15px;
      }
      .text {
        display: inline-block;
        vertical-align: top;
        line-height: 30px;
        height: 60px;
        b {
          font-size: 16px;
        }
        span {
          font-size: 12px;
        }
      }
    }
    p {
      width: 650px;
      font-size: 12px;
      line-height: 30px;
    }
  }
  .content-right {
    margin-top: 20px;
    width: 120px;
    height: 120px;
    border: 1px solid #f3f3f3;
    float: right;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
