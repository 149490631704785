var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "en-dialog",
    {
      attrs: { title: "土地购地信息状况", visible: _vm.show, width: "850px" },
      on: {
        "update:visible": function ($event) {
          _vm.show = $event
        },
        close: _vm.closeDialog,
      },
    },
    [
      _c("div", { staticClass: "patentInfo-content" }, [
        _c("div", { staticClass: "patentInfo-info" }, [
          _c("table", [
            _c("tr", [
              _c("td", [_vm._v("行政区")]),
              _c("td", [_vm._v(_vm._s(_vm.data.adminRegion || ""))]),
              _c("td", [_vm._v("电子监管号")]),
              _c("td", [_vm._v(_vm._s(_vm.data.elecSupervisorNo || ""))]),
            ]),
            _c("tr", [
              _c("td", [_vm._v("签订日期")]),
              _c("td", [_vm._v(_vm._s(_vm.data.signedDate || ""))]),
              _c("td", [_vm._v("供地总面积（公顷）")]),
              _c("td", [_vm._v(_vm._s(_vm.data.totalArea || ""))]),
            ]),
            _c("tr", [
              _c("td", [_vm._v("宗地位置")]),
              _c("td", { attrs: { colspan: "3" } }, [
                _vm._v(" " + _vm._s(_vm.data.location || "") + " "),
              ]),
            ]),
            _c("tr", [
              _c("td", [_vm._v("受让人")]),
              _c("td", [_vm._v(_vm._s(_vm.data.assignee || ""))]),
              _c("td", [_vm._v("上级公司")]),
              _c("td", [_vm._v(_vm._s(_vm.data.parentCompany || ""))]),
            ]),
            _c("tr", [
              _c("td", [_vm._v("土地用户")]),
              _c("td", [_vm._v(_vm._s(_vm.data.purpose || ""))]),
              _c("td", [_vm._v("供应方式")]),
              _c("td", [_vm._v(_vm._s(_vm.data.supplyWay || ""))]),
            ]),
            _c("tr", [
              _c("td", [_vm._v("最小容积率")]),
              _c("td", [_vm._v(_vm._s(_vm.data.minVolume || ""))]),
              _c("td", [_vm._v("最大容积率")]),
              _c("td", [_vm._v(_vm._s(_vm.data.maxVolume || ""))]),
            ]),
            _c("tr", [
              _c("td", [_vm._v("成交价款（万元）")]),
              _c("td", { attrs: { colspan: "3" } }, [
                _vm._v(" " + _vm._s(_vm.data.dealPrice || "") + " "),
              ]),
            ]),
            _c("tr", [
              _c("td", [_vm._v("约定动工时间")]),
              _c("td", [_vm._v(_vm._s(_vm.data.startTime || ""))]),
              _c("td", [_vm._v("约定竣工时间")]),
              _c("td", [_vm._v(_vm._s(_vm.data.endTime || ""))]),
            ]),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }