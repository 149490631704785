<!--
 * @Author: Fenngding
 * @Date: 2019-09-23 10:29:54
 * @LastEditors: zhulin
 * @LastEditTime: 2021-09-13 14:10:43
 * @Email: fenngding@live.com
 * @Description:
 -->
<template>
  <div class="tab-dropdown" @mouseleave="handleMouseLeave">
    <div class="tab-dropdown-nav">
      <span class="menu-item" v-for="(menu, menuindex) in tabData" :key="menuindex" :class="{ active: menuindex === isActive }" @mouseenter="handleMouseEnter(menuindex)">
        {{ menu.name }}
      </span>
    </div>
    <ul v-if="isShow" class="tab-dropdown-content">
      <li v-for="(menu, menuindex) in tabData" :key="menuindex">
        <span v-for="(item, itemidnex) in menu.nodes" :key="itemidnex" @click="apeendBlock(item)" :class="item.isPower === '1' ? 'disabled' : ''">{{ item.name }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  namr: "Tab",
  components: {},
  props: {
    tabData: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      isActive: null,
      isShow: false
    };
  },
  mounted() {
    // 加入2个固定菜单
    this.tabData.find((item) => item.id === "2").nodes.unshift(
      {
        isPower: "0",
        name: "工商信息",
        onlyCode: "001"
      },
      {
        isPower: "0",
        name: "主要人员信息",
        onlyCode: "002"
      }
    );
  },
  methods: {
    /**
     * @description: 鼠标移入，显示菜单
     */
    handleMouseEnter(index) {
      this.isActive = index;
      this.isShow = true;
    },
    /**
     * @description: 鼠标离开，隐藏菜单
     */
    handleMouseLeave(index) {
      this.isActive = index;
      this.isShow = false;
    },
    /**
     * @description: 点击添加块
     * @param {type}
     * @return:
     */
    apeendBlock(item) {
      console.log(item);
      if (item.isPower === "0") {
        this.$parent.blockEvent(item.onlyCode, 1);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.tab-dropdown {
  position: relative;
  text-align: left;
  .menu-item {
    text-decoration: none;
    color: #666;
    font-size: 14px;
    display: inline-block;
  }
  &-nav {
    border-radius: 5px;
    height: 50px;
    background: #fff;
    border-bottom: 1px solid #ededed;
    .menu-item {
      color: #333;
      position: relative;
      line-height: 48px;
      padding: 0 40px;
      &.active,
      &:hover {
        color: #3e90fe;
        border-bottom: 1px solid #3e90fe;
      }
      &::after {
        content: " ";
        display: inline-block;
        width: 1px;
        height: 26px;
        background: #e8ecf2;
        position: absolute;
        right: 0;
        top: 12px;
      }
      &:last-child {
        &::after {
          content: " ";
          background: #fff;
        }
      }
    }
  }
  &-content {
    padding-left: 0;
    margin-top: 0;
    position: absolute;
    left: 0;
    right: 0;
    top: 50px;
    background: #fff;
    z-index: 99;
    display: flex;
    flex-direction: row;
    border-radius: 5px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
    li {
      border-right: 1px solid #e8ecf2;
      width: 136px;
      span {
        display: block;
        font-size: 12px;
        height: 36px;
        line-height: 36px;
        padding-left: 20px;
        cursor: pointer;
        &.active,
        &:hover {
          background: #f5f8fc;
          color: #72ade6;
        }
        &.disabled {
          color: #aeaeae;
          cursor: not-allowed;
        }
      }
      &:last-child {
        border-right: none;
      }
    }
  }
}
</style>
