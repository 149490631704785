var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "en-dialog",
    {
      staticClass: "report-alert",
      attrs: { title: "法院公告", visible: _vm.show, width: "1000px" },
      on: {
        "update:visible": function ($event) {
          _vm.show = $event
        },
        close: _vm.closeDialog,
      },
    },
    [
      _c("div", { staticClass: "announcementInfo-content" }, [
        _c("div", { staticClass: "announcementInfo-content-title" }, [
          _vm._v(" 法院公告 "),
        ]),
        _c("div", { staticClass: "mortgagor-info" }, [
          _c("table", [
            _c("tr", [
              _c("td", [_vm._v("刊登日期")]),
              _c("td", [_vm._v(_vm._s(_vm.data.publishdate))]),
            ]),
            _c("tr", [
              _c("td", [_vm._v("上诉方")]),
              _c("td", [_vm._v(_vm._s(_vm.data.party1))]),
            ]),
            _c("tr", [
              _c("td", [_vm._v("被诉方")]),
              _c("td", [_vm._v(_vm._s(_vm.data.party2))]),
            ]),
            _c("tr", [
              _c("td", [_vm._v("公告类型")]),
              _c("td", [_vm._v(_vm._s(_vm.data.bltntypename))]),
            ]),
            _c("tr", [
              _c("td", [_vm._v("法院")]),
              _c("td", [_vm._v(_vm._s(_vm.data.courtcode))]),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "announcementInfo-content-title" }, [
          _vm._v(" 抵押人信息 "),
        ]),
        _c("div", { staticClass: "mortgagor-text" }, [
          _vm._v(_vm._s(_vm.data.content)),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }