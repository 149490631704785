import { render, staticRenderFns } from "./base-info.vue?vue&type=template&id=324410cc&scoped=true"
import script from "./base-info.vue?vue&type=script&lang=js"
export * from "./base-info.vue?vue&type=script&lang=js"
import style0 from "./base-info.vue?vue&type=style&index=0&id=324410cc&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "324410cc",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/lib/jenkins/workspace/dev_en-user/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('324410cc')) {
      api.createRecord('324410cc', component.options)
    } else {
      api.reload('324410cc', component.options)
    }
    module.hot.accept("./base-info.vue?vue&type=template&id=324410cc&scoped=true", function () {
      api.rerender('324410cc', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/corporateCredit/information/base-info.vue"
export default component.exports