<!--
 * @Author: zhulin
 * @Date: 2020-07-22 10:34:01
 * @LastEditors: zhulin
 * @LastEditTime: 2020-08-07 11:47:18
 * @Description: 失信人387
 * @FilePath: \user\src\views\corporateCredit\law\letdownInfo.vue
-->
<template>
  <div id="letdownInfo" ref="letdownInfo" class="tyc-settings">
    <div class="title">
      <div class="leftbar">
        <span>失信人({{ tycData.tycResult.total }})</span>
      </div>
      <div class="rightbar">
        <en-icon name="iconzaicichayan-qiyezhengxin" size="22px" color="#ffb540" @click.native="reCheck()"> </en-icon>
      </div>
    </div>
    <!-- 企业基本信息 -->
    <div class="base">
      <div class="base-law">
        <en-table :data="tycData.tycResult.items" row-key="id" border default-expand-all @row-click="showInfo">
          <en-table-column prop="index" label="序号" width="60"> </en-table-column>
          <en-table-column prop="casecode" label="立案日期" width="160"> </en-table-column>
          <en-table-column prop="gistunit" label="案号"> </en-table-column>
          <en-table-column prop="performance" label="执行法院"> </en-table-column>
          <en-table-column prop="gistid" label="履行状态"> </en-table-column>
          <en-table-column label="操作" width="150">
            <template>
              <en-button type="primary">
                详情
              </en-button>
            </template>
          </en-table-column>
        </en-table>
        <en-pagination :page-model="pageModel" @change="handlePageChanged"></en-pagination>
      </div>
    </div>
    <!--失信人详情弹窗-->
    <letdown-alert ref="wechatAlert" :show="showAlert" :info-data="infoData" @toShow="toShow"></letdown-alert>
  </div>
</template>

<script>
import letdownAlert from "./components/letdownAlert";

export default {
  name: "LetdownInfo",
  components: { letdownAlert },
  props: {
    tycData: {
      type: Object,
      default() {}
    }
  },
  data() {
    return {
      tableData: [],
      showAlert: false,
      infoData: {},
      pageModel: {
        pageNo: this.tycData.pageNo || 1,
        pageSize: this.tycData.pageSize || 10,
        total: this.tycData.tycResult.total || "",
        totalPages: this.tycData.totalPages || Math.ceil(this.tycData.tycResult.total / (this.tycData.pageSize || 10))
      }
    };
  },
  computed: {},
  methods: {
    /**
     * @description: 页码
     */
    handlePageChanged({ pageSize, pageNo }) {
      this.$parent.blockEvent("387", 1, true, pageNo, pageSize);
    },
    /**
     * @description: 再次查验
     */
    reCheck() {
      this.$parent.blockEvent("387", 0, true);
    },
    /**
     * @description: 打开弹窗
     */
    showInfo(item) {
      this.showAlert = true;
      this.infoData = item.row;
    },
    /**
     * @description: 关闭弹窗
     */
    toShow() {
      this.showNotice = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.tyc-settings {
  .title {
    i {
      background: #e5f2ff;
      padding: 3px;
      color: #5fb5ff;
      font-style: normal;
      margin-left: 10px;
      border-radius: 3px;
    }
  }
  .wrap {
    padding: 85px;
  }
}
.base {
  display: flex;
  flex-direction: row;
  margin-top: -1px;
  &-law {
    width: 100%;
  }
}
.el-button--primary.en-btn-normal {
  background: #e5f2ff;
  color: #5fb5ff;
  border-color: #e5f2ff;
}
.el-button--danger.en-btn-normal {
  background: #f7ebeb;
  color: #f76b6b;
  border-color: #f7ebeb;
}
</style>
