<!--
 * @Author: Do not edit
 * @Date: 2020-07-22 10:34:01
 * @LastEditors: zhulin
 * @LastEditTime: 2020-08-06 15:58:54
 * @Description: 抽查检查434
 * @FilePath: \user\src\views\corporateCredit\manageStatus\spottest.vue
-->
<template>
  <div id="spottest" ref="spottest" class="tyc-settings">
    <div class="title clearfix">
      <div class="leftbar">
        <span>抽查检查({{ tycData.tycResult.total }})</span>
      </div>
      <div class="rightbar">
        <en-icon name="iconzaicichayan-qiyezhengxin" size="22px" color="#ffb540" @click.native="reCheck()"> </en-icon>
      </div>
    </div>
    <!-- 企业基本信息 -->
    <div class="base">
      <div class="base-law">
        <en-table :data="tableData" row-key="id" border default-expand-all>
          <en-table-column prop="date" label="序号" width="60"> </en-table-column>
          <en-table-column prop="checkDate" label="日期" width="160"> </en-table-column>
          <en-table-column prop="checkType" label="类型"> </en-table-column>
          <en-table-column prop="checkResult" label="结果"> </en-table-column>
          <en-table-column prop="checkOrg" label="检查实施机关"> </en-table-column>
        </en-table>
        <en-pagination :page-model="pageModel"></en-pagination>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Spottest",
  props: {
    tycData: {
      type: Object,
      default() {}
    }
  },
  data() {
    return {
      tableData: [],
      pageModel: {
        pageNo: this.tycData.pageNo || 1,
        pageSize: this.tycData.pageSize || 10,
        total: this.tycData.tycResult.total || "",
        totalPages: this.tycData.totalPages || Math.ceil(this.tycData.tycResult.total / (this.tycData.pageSize || 10))
      }
    };
  },
  computed: {},
  methods: {
    /**
     * @description: 页码
     */
    handlePageChanged({ pageSize, pageNo }) {
      this.$parent.blockEvent("434", 1, true, pageNo, pageSize);
    },
    /**
     * @description: 再次查验
     */
    reCheck() {
      this.$parent.blockEvent("434", 0, true);
    }
  }
};
</script>

<style lang="scss" scoped>
.tyc-settings {
  .title {
    i {
      background: #e5f2ff;
      padding: 3px;
      color: #5fb5ff;
      font-style: normal;
      margin-left: 10px;
      border-radius: 3px;
    }
  }
  .wrap {
    padding: 85px;
  }
}
.base {
  display: flex;
  flex-direction: row;
  margin-top: -1px;
  &-law {
    width: 100%;
  }
}
</style>
