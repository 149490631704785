<!--
 * @Author: zhulin
 * @Date: 2020-07-22 10:34:01
 * @LastEditors: zhulin
 * @LastEditTime: 2020-08-06 16:27:31
 * @Description: 产品信息
 * @FilePath: \user\src\views\corporateCredit\manageStatus\components\infolistAlert.vue
-->
<template>
  <en-dialog title="产品信息" :visible.sync="show" width="850px" @close="closeDialog">
    <div class="punish-content">
      <div class="punish-info">
        <table>
          <tr>
            <td>产品图标</td>
            <td>
              <img :src="data.icon" alt="data.filterName" />
            </td>
          </tr>
          <tr>
            <td>产品名称</td>
            <td>{{ data.name || "" }}</td>
          </tr>
          <tr>
            <td>产品简称</td>
            <td>{{ data.filterName || "" }}</td>
          </tr>
          <tr>
            <td>产品分类</td>
            <td>{{ data.type || "" }}</td>
          </tr>
          <tr>
            <td>领域</td>
            <td>{{ data.classes || "" }}</td>
          </tr>
          <tr>
            <td>描述</td>
            <td>{{ data.brief || "" }}</td>
          </tr>
        </table>
      </div>
    </div>
  </en-dialog>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false
    },
    infoData: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      data: {}
    };
  },
  watch: {
    /**
     * @description: 监听弹窗
     */
    show(val) {
      if (val) {
        this.data = this.infoData || {};
      }
    }
  },
  methods: {
    /**
     * @description: 关闭弹窗回调
     */
    closeDialog() {
      this.$emit("toShow", false);
    }
  }
};
</script>

<style lang="scss" scoped>
.punish-content {
  .punish-info {
    margin: 20px 0 50px 0;
    table {
      border-collapse: collapse;
      border-spacing: 0;
      width: 100%;
      tr {
        td {
          padding: 20px 18px;
          border: 1px solid #e8ecf2;
          font-size: 12px;
          text-align: left;
          img {
            width: 80px;
            height: 80px;
            border-radius: 50%;
          }
        }
        td:nth-child(1) {
          color: #333;
          background-color: #f6fafd;
          width: 200px;
        }
        td:nth-child(2) {
          color: #636c78;
        }
      }
    }
  }
}
</style>
