<!--
 * @Author: Do not edit
 * @Date: 2020-07-22 10:34:01
 * @LastEditors: zhulin
 * @LastEditTime: 2020-08-07 16:12:29
 * @Description: 工商信息365
 * @FilePath: \user\src\views\corporateCredit\information\enterprise-info.vue
-->
<template>
  <div id="enterpriseInfo" ref="enterpriseInfo" class="tyc-settings">
    <!-- 菜单导航 -->
    <div class="title">
      <span>工商信息</span>
    </div>
    <div class="enterprise">
      <table class="table">
        <tbody>
          <tr>
            <th rowspan="2" width="200">
              法定代表人信息
            </th>
            <td rowspan="2">
              <div class="legalPerson">
                <img :src="legalPersonNameMap.logo" alt="" />
                <div>
                  <strong>{{ legalPersonNameMap.name || tycData.tycResult.legalPersonName || "-" }}</strong>
                  <span>职位:{{ typeJoin }}</span>
                  <span>他有{{ legalPersonNameMap.toco || "-" }}家公司</span>
                </div>
              </div>
            </td>
            <th>注册资本</th>
            <td>{{ tycData.tycResult.regCapital }}</td>
          </tr>
          <tr>
            <th>实缴资本</th>
            <td>{{ tycData.tycResult.actualCapital }}</td>
          </tr>
          <tr>
            <th>成立日期</th>
            <td>{{ dayjs(tycData.tycResult.fromTime).format("YYYY-MM-DD") }}</td>
            <th>经营状态</th>
            <td>
              <span class="tips">{{ tycData.tycResult.regStatus }}</span>
            </td>
          </tr>
          <tr>
            <th>统一社会信用代码</th>
            <td>{{ tycData.tycResult.creditCode }}</td>
            <th>工商注册号</th>
            <td>{{ tycData.tycResult.regNumber }}</td>
          </tr>
          <tr>
            <th>纳税人识别号</th>
            <td>{{ tycData.tycResult.taxNumber }}</td>
            <th>组织机构代码</th>
            <td>{{ tycData.tycResult.orgNumber }}</td>
          </tr>
          <tr>
            <th>公司类型</th>
            <td>{{ tycData.tycResult.companyOrgType }}</td>
            <th>行业</th>
            <td>{{ tycData.tycResult.industry }}</td>
          </tr>
          <tr>
            <th>核准日期</th>
            <td>{{ dayjs(tycData.tycResult.approvedTime).format("YYYY-MM-DD") }}</td>
            <th>登记机关</th>
            <td>{{ tycData.tycResult.regInstitute }}</td>
          </tr>
          <tr>
            <th>营业期限</th>
            <td>{{ time365 }}</td>
            <th>纳税人资质</th>
            <td>{{ tycData.tycResult.industry }}</td>
          </tr>
          <tr>
            <th>人员规模</th>
            <td>{{ tycData.tycResult.staffNumRange || "-" }}</td>
            <th>参保人数</th>
            <td>{{ tycData.tycResult.socialStaffNum || "-" }}</td>
          </tr>
          <tr>
            <th>曾用名</th>
            <td>{{ tycData.tycResult.historyNames || "-" }}</td>
            <th>英文名称</th>
            <td>{{ tycData.tycResult.property3 || "-" }}</td>
          </tr>
          <tr>
            <th>注册地址</th>
            <td colspan="3">
              {{ tycData.tycResult.regLocation }}
            </td>
          </tr>
          <tr>
            <th>经营范围</th>
            <td colspan="3">
              {{ tycData.tycResult.businessScope }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";

export default {
  props: {
    tycData: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      result: {},
      dayjs,
      time365: "",
      legalPersonNameMap: {},
      typeJoin: ""
    };
  },
  mounted() {
    this.getField();
  },
  methods: {
    /**
     * @description: 时间格式化
     */
    changeTime(item) {
      const newtime = dayjs(item).format("YYYY-MM-DD");
      return newtime;
    },
    getField() {
      const data = this.tycData.tycResult;
      this.legalPersonNameMap = data.staffList?.result.find((item) => item.name === data.legalPersonName) || { typeJoin: [] };
      this.typeJoin = this.legalPersonNameMap.typeJoin[0];

      // 营业期限
      if (data.toTime) {
        this.time365 = `${dayjs(data.estiblishTime).format("YYYY-MM-DD")}至${dayjs(data.toTime).format("YYYY-MM-DD")}`;
      } else {
        this.time365 = `${dayjs(data.estiblishTime).format("YYYY-MM-DD")}至-`;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.tyc-settings {
  margin-top: 10px;
}
.enterprise {
  .table {
    border-collapse: collapse;
    border-spacing: 0;
    margin-top: -1px;
    margin-bottom: 20px;
    width: 100%;
    th,
    td {
      padding: 15px 18px;
      border-top: 1px solid #e8ecf2;
      border-left: 1px solid #e8ecf2;
      border-bottom: 1px solid #e8ecf2;
      font-size: 12px;
      text-align: left;
      .legalPerson {
        display: flex;
        flex-direction: row;
        img {
          width: 60px;
          height: 60px;
          border-radius: 30px;
        }
        strong {
          margin-top: 10px;
          font-weight: 600;
          color: #333;
        }
        strong,
        span {
          display: block;
          margin-left: 10px;
          line-height: 20px;
        }
      }
    }
    th {
      width: 20%;
      background-color: #f6fafd;
      font-weight: normal;
      color: #333;
    }
    td {
      width: 30%;
      color: #8a919a;
      line-height: 16px;
      letter-spacing: 1px;
      .tips {
        display: inline-block;
        background: #e9f9f4;
        color: #26c393;
        padding: 0 20px;
        line-height: 26px;
        border-radius: 5px;
      }
    }
  }
}
</style>
