var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "recruitment",
      staticClass: "tyc-settings",
      attrs: { id: "recruitment" },
    },
    [
      _c("div", { staticClass: "title" }, [
        _c("div", { staticClass: "leftbar" }, [
          _c("span", [
            _vm._v("招聘(" + _vm._s(_vm.tycData.tycResult.total) + ")"),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "rightbar" },
          [
            _c("en-icon", {
              attrs: {
                name: "iconzaicichayan-qiyezhengxin",
                size: "22px",
                color: "#ffb540",
              },
              nativeOn: {
                click: function ($event) {
                  return _vm.reCheck()
                },
              },
            }),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "base" }, [
        _c(
          "div",
          { staticClass: "base-law" },
          [
            _c(
              "en-table",
              { attrs: { data: _vm.tycData.tycResult.items } },
              [
                _c("en-table-column", {
                  attrs: { type: "index", width: "60px" },
                }),
                _c("en-table-column", {
                  attrs: { prop: "createTime", label: "发布时间" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function ({ row }) {
                        return [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.dayjs(row.createTime).format("YYYY-MM-DD")
                              )
                            ),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
                _c("en-table-column", {
                  attrs: { prop: "title", label: "招聘职位" },
                }),
                _c("en-table-column", {
                  attrs: { prop: "oriSalary", label: "薪资" },
                }),
                _c("en-table-column", {
                  attrs: { prop: "experience", label: "工作经验" },
                }),
                _c("en-table-column", {
                  attrs: { prop: "employerNumber", label: "招聘人数" },
                }),
                _c("en-table-column", {
                  attrs: { prop: "city", label: "所在城市" },
                }),
              ],
              1
            ),
            _c("en-pagination", {
              attrs: { "page-model": _vm.pageModel },
              on: { change: _vm.handlePageChanged },
            }),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }