<!--
 * @Author: Do not edit
 * @Date: 2020-07-22 10:34:01
 * @LastEditors: zhulin
 * @LastEditTime: 2020-08-07 11:48:11
 * @Description: 专利信息440
 * @FilePath: \user\src\views\corporateCredit\property\patentInfo.vue
-->
<template>
  <div id="patentInfo" ref="patentInfo" class="tyc-settings">
    <div class="title clearfix">
      <div class="leftbar">
        <span>专利信息({{ tycData.tycResult.total }})</span>
      </div>
      <div class="rightbar">
        <en-icon name="iconzaicichayan-qiyezhengxin" size="22px" color="#ffb540" @click.native="reCheck()"> </en-icon>
      </div>
    </div>
    <!-- 企业基本信息 -->
    <div class="base">
      <div class="base-inner">
        <en-table :data="tycData.tycResult.items" @row-click="showInfo">
          <en-table-column type="index" label="序号" width="60"> </en-table-column>
          <en-table-column prop="applicationPublishTime" label="申请公布日" width="160"> </en-table-column>
          <en-table-column prop="patentName" label="专利名称"> </en-table-column>
          <en-table-column prop="appnumber" label="申请公布号" width="235"> </en-table-column>
          <en-table-column prop="pubnumber" label="申请公示号" width="240"> </en-table-column>
          <en-table-column prop="patentType" label="专利类型" width="260"> </en-table-column>
        </en-table>
        <en-pagination :page-model="pageModel" @change="handlePageChanged"></en-pagination>
      </div>
    </div>
    <!--专利信息弹窗-->
    <patentinfo-alert ref="patentinfoAlert" :show="showAlert" :info-data="infoData" @toShow="toShow"></patentinfo-alert>
  </div>
</template>

<script>
import patentinfoAlert from "./components/patentinfoAlert";

export default {
  name: "PatentInfo",
  components: { patentinfoAlert },
  props: {
    tycData: {
      type: Object,
      default() {}
    }
  },
  data() {
    return {
      tableData: [],
      showAlert: false,
      infoData: {},
      pageModel: {
        pageNo: this.tycData.pageNo || 1,
        pageSize: this.tycData.pageSize || 10,
        total: this.tycData.tycResult.total || "",
        totalPages: this.tycData.totalPages || Math.ceil(this.tycData.tycResult.total / (this.tycData.pageSize || 10))
      }
    };
  },
  computed: {},
  methods: {
    /**
     * @description: 页码
     */
    handlePageChanged({ pageSize, pageNo }) {
      this.$parent.blockEvent("440", 1, true, pageNo, pageSize);
    },
    /**
     * @description: 再次查验
     */
    reCheck() {
      this.$parent.blockEvent("440", 0, true);
    },
    /**
     * @description: 打开弹窗
     */
    showInfo(item) {
      this.showAlert = true;
      this.infoData = item.row;
    },
    /**
     * @description: 关闭弹窗
     */
    toShow() {
      this.showNotice = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.tyc-settings {
  margin-top: 10px;
  .wrap {
    padding: 85px;
  }
}
.base {
  display: flex;
  flex-direction: row;
  margin-top: -1px;
  &-inner {
    width: 100%;
  }
}
</style>
