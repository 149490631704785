var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "en-dialog",
    {
      attrs: { title: "开庭公告详情", visible: _vm.show, width: "850px" },
      on: {
        "update:visible": function ($event) {
          _vm.show = $event
        },
        close: _vm.closeDialog,
      },
    },
    [
      _c("div", { staticClass: "courtSession-content" }, [
        _c("div", { staticClass: "courtSession-content-title" }, [
          _vm._v(" 开庭公告详情 "),
        ]),
        _c("div", { staticClass: "mortgagor-info" }, [
          _c("table", [
            _c("tr", [
              _c("td", [_vm._v("案由")]),
              _c("td", [_vm._v(_vm._s(_vm.data.caseReason || "") + "}")]),
            ]),
            _c("tr", [
              _c("td", [_vm._v("案号")]),
              _c("td", [_vm._v(_vm._s(_vm.data.caseNo || "") + "}")]),
            ]),
            _c("tr", [
              _c("td", [_vm._v("原告/上诉人")]),
              _c("td", [_vm._v(_vm._s(_vm.data.plaintiff || "") + "}")]),
            ]),
            _c("tr", [
              _c("td", [_vm._v("被告/被上诉人")]),
              _c("td", [_vm._v(_vm._s(_vm.data.defendant || "") + "}")]),
            ]),
            _c("tr", [
              _c("td", [_vm._v("开庭日期")]),
              _c("td", [_vm._v(_vm._s(_vm.data.startDate || "") + "}")]),
            ]),
            _c("tr", [
              _c("td", [_vm._v("当事人")]),
              _c("td", [_vm._v(_vm._s(_vm.data.litigant || "") + "}")]),
            ]),
            _c("tr", [
              _c("td", [_vm._v("承办部门")]),
              _c("td", [_vm._v(_vm._s(_vm.data.contractors || "") + "}")]),
            ]),
            _c("tr", [
              _c("td", [_vm._v("审判长/主审人")]),
              _c("td", [_vm._v(_vm._s(_vm.data.judge || "") + "}")]),
            ]),
            _c("tr", [
              _c("td", [_vm._v("法院")]),
              _c("td", [_vm._v(_vm._s(_vm.data.court || "") + "}")]),
            ]),
            _c("tr", [
              _c("td", [_vm._v("法庭")]),
              _c("td", [_vm._v(_vm._s(_vm.data.courtroom || "") + "}")]),
            ]),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }