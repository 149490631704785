<template>
  <div class="main">
    <!--tab-->
    <tab-nav></tab-nav>
    <!--投招标-->
    <tender></tender>
    <!--债券信息-->
    <bond></bond>
    <!--购地信息-->
    <buyland></buyland>
    <!--招聘-->
    <recruitment></recruitment>
    <!--税务评级-->
    <taxrate></taxrate>
    <!--抽查检查-->
    <spottest></spottest>
    <!--产品信息-->
    <infoList></infoList>
    <!--证书-->
    <credential></credential>
    <!--进出口信用-->
    <trading></trading>
  </div>
</template>

<script>
import TabNav from "@/views/corporateCredit/common/tab-nav";
import Tender from "@/views/corporateCredit/manageStatus/tender";
import Bond from "@/views/corporateCredit/manageStatus/bond";
import Buyland from "@/views/corporateCredit/manageStatus/buyland";
import Recruitment from "@/views/corporateCredit/manageStatus/recruitment";
import Taxrate from "@/views/corporateCredit/manageStatus/taxrate";
import Spottest from "@/views/corporateCredit/manageStatus/spottest";
import InfoList from "@/views/corporateCredit/manageStatus/infoList";
import Credential from "@/views/corporateCredit/manageStatus/credential";
import Trading from "@/views/corporateCredit/manageStatus/trading";

export default {
  name: "ManageStatus",
  components: {
    TabNav,
    Tender,
    Bond,
    Buyland,
    Recruitment,
    Taxrate,
    Spottest,
    InfoList,
    Credential,
    Trading
  },
  data() {
    return {};
  },
  computed: {},
  watch: {
    $route: { // 监听路由变化,当query的location发送变动时,在下一帧跳转到指定dom(该dom需要有id属性)
      immediate: true,
      deep: true,
      handler(val) {
        const { location } = val.query;
        if (location) {
          this.$nextTick(() => {
            window.location.hash = location;
          });
        }
      }
    }
  },
  methods: {}
};
</script>

<style lang="scss" scoped>
</style>
